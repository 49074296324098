import React from "react";
//import UserHeader from "../components/AdminPage/AdminUserPage/UserInfoHeader";

import ProfilePageHeader from "../components/AdminPage/AdminProfilePage/AdminProfilePageHeader";

import "../components/AdminPage/AdminUserPage/UserInfo.css";

import { UserInfoContextProvider } from "../context/UserInfoContext";

function AdminUserInfo() {
  return (
    <UserInfoContextProvider>
      <>
        <ProfilePageHeader />
      </>
    </UserInfoContextProvider>
  );
}

export default AdminUserInfo;
