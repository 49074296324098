import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import ApparatusChecklist from "../../apis/ApparatusChecklist";
import Header from "../Header/Header";

import { useNavigate } from "react-router-dom";

//Have to change page to not be able to save or edit task
import { IoMdArrowRoundBack } from "react-icons/io";
import { useAuth } from "../../context/AuthContext";

//
const SubmitedChecklistHeader = () => {
  const history = useNavigate();

  const { id } = useParams();
  //Popup
  const { currentUserSiteId } = useAuth();

  const [task, setTask] = useState([]);
  const [apparatusName, setApparatusName] = useState([]);
  // // console.log(data2.apparatusid);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApparatusChecklist.get(`/submited${id}`, {
          params: {
            currentUserSiteId,
          },
        });

        setTask(response.data.data.taskInfo);
        setApparatusName(response.data.data.apparatusInfo[0].name);
        //// console.log(response.data.data.final);
      } catch (err) {
        // console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const data = task.map(function (data) {
    // console.log(data);
    return {
      col1: data.completed,
      col2: data.scheduleddate,
      col3: data.checklistname,
      col4: data.taskgroupname,

      col5: data.bagname
        ? `${data.bagname} - ${data.taskdescription}`
        : data.name
        ? `${data.name} - ${data.taskdescription}`
        : data.taskdescription,

      col6: data.taskstatus,
      col7: data.tasknote,
    };
  });
  // eslint-disable-next-line
  const columns = React.useMemo(
    () => [
      {
        Header: "COMPLETED",
        columns: [
          {
            accessor: "col1", // accessor is the "key" in the data
          },
        ],
      },

      {
        Header: "SCHEDULED",
        columns: [
          {
            accessor: "col2", // accessor is the "key" in the data
            Filter: SelectColumnFilter,
          },
        ],
      },

      {
        Header: "CHECKLIST",
        columns: [
          {
            accessor: "col3", // accessor is the "key" in the data
            Filter: SelectColumnFilter,
          },
        ],
      },

      {
        Header: "TASK GROUP",
        columns: [
          {
            accessor: "col4", // accessor is the "key" in the data
            Filter: SelectColumnFilter,
          },
        ],
      },

      {
        Header: "TASK",
        columns: [
          {
            accessor: "col5", // accessor is the "key" in the data
            Filter: SelectColumnFilter,
          },
        ],
      },

      {
        Header: "STATUS",
        columns: [
          {
            accessor: "col6", // accessor is the "key" in the data
            Filter: SelectColumnFilter,
          },
        ],
      },

      {
        Header: "NOTES",
        columns: [
          {
            accessor: "col7", // accessor is the "key" in the data
          },
        ],
      },

      {
        Header: "Members",
        columns: [
          {
            accessor: "col8", // accessor is the "key" in the data
            Filter: SelectColumnFilter,
          },
        ],
      },
    ],

    []
  );
  // eslint-disable-next-line
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    //  const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        placeholder={`Search`}
        className="alertTableSearchInput"
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        className="itemPageHistorySelect"
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>

        <Header content={<b>Submited Checklist</b>} />
      </div>

      <div className="pageHeaderSubmitedCheck">
        <b>{apparatusName}</b>
      </div>

      <div className="apparatusChecklistpageHeader">
        <b>Submited Checklist </b>
      </div>

      <div className="primary">
        <div className="primaryContainer"></div>
      </div>
      {!task && (
        <div className="text-center p-3">
          <span className="spinner-border spinner-border-lg align-center"></span>
        </div>
      )}
    </>
  );
};

export default SubmitedChecklistHeader;
