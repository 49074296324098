import React, { useState, createContext } from "react";

export const SettingsContext = createContext();

export const SettingsContextProvider = (props) => {
  const [userList, setUserList] = useState([]);

  return (
    <SettingsContext.Provider
      value={{
        setUserList,
        userList,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};
