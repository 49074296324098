import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

import { usePerviousTrainingData } from "./fetchPerviousTrainingData";

const PreviousTrainingList = () => {
  const { currentUserSiteId } = useAuth();

  const { data } = usePerviousTrainingData(currentUserSiteId);

  const history = useNavigate();

  const handleTraining = (id) => {
    history(`/trainingInfo/${id}`);
  };

  let loopingMonth = null;

  const [dropdownQuery, setDropdownQuery] = useState("");
  const [dropdownQueryYear, setDropdownQueryYear] = useState("");

  return (
    <div className="trainingDivHolder">
      <div className="searchDiv4">
        <div className="inventorySelectHolder">
          <div className="inventorySelect1">
            <label className="NewInputLabel" data-domain="Category">
              <select
                className="NewInput"
                onChange={(event) => setDropdownQuery(event.target.value)}
              >
                <option value="">All</option>

                {data?.data.data.categoryList.map(function (e, i) {
                  return (
                    <option key={i} value={e.name}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="inventorySelect22">
            <label className="NewInputLabel" data-domain="Year">
              <select
                className="NewInput"
                onChange={(event) => setDropdownQueryYear(event.target.value)}
              >
                <option value="">All</option>
                {data?.data.data.yearList.map(function (e, i) {
                  return (
                    <option key={i} value={e.date1}>
                      {e.date1}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
      </div>
      {data?.data.data.trainingDetails &&
        data?.data.data.trainingDetails
          // eslint-disable-next-line
          .filter((post) => {
            //need to remove this
            if (post.category === null) {
              return post;
            } else if (dropdownQuery === "" && dropdownQueryYear === "") {
              return post;
            } else if (
              post.category
                .toLowerCase()
                .includes(dropdownQuery.toLowerCase()) &&
              dropdownQueryYear === ""
            ) {
              return post;
            } else if (
              post.month
                .toLowerCase()
                .includes(dropdownQueryYear.toLowerCase()) &&
              dropdownQuery === ""
            ) {
              return post;
            } else if (
              post.month
                .toLowerCase()
                .includes(dropdownQueryYear.toLowerCase()) &&
              post.category.toLowerCase().includes(dropdownQuery.toLowerCase())
            ) {
              return post;
            }
          })

          .map((data, i) => {
            //  console.log(data);

            let currentMonth = data.month;

            if (loopingMonth === currentMonth) {
              return (
                <div key={i}>
                  <div className="trainingContainer">
                    <div
                      className="trainingListContainer"
                      onClick={() => handleTraining(data.id)}
                    >
                      <div className="trainingName">
                        <b>{data.training_name}</b>
                      </div>

                      <div className="trainingDay">{data.date2}</div>
                      <div className="trainingDesc">
                        <b>
                          {data.category}s
                          {data.eventtype !== "" ? ` - ${data.eventtype}` : ""}
                        </b>
                      </div>
                      <div className="trainingDesc">
                        Description &nbsp;<b> {data.description}</b>
                      </div>

                      <div className="trainingCompletedCountHolder">
                        {" "}
                        <div className="trainingCompletedCountName">
                          Members{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.attended}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Notes{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.notecount}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Items{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.itemcount}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Photos{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.photocount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              loopingMonth = currentMonth;

              return (
                <div key={i}>
                  <div className="trainingMonth">{currentMonth}</div>

                  <div className="trainingContainer">
                    <div
                      className="trainingListContainer"
                      onClick={() => handleTraining(data.id)}
                    >
                      <div className="trainingName">
                        <b>{data.training_name}</b>
                      </div>

                      <div className="trainingDay">{data.date2}</div>
                      <div className="trainingDesc">
                        <b>
                          {data.category}
                          {data.eventtype !== "" ? ` - ${data.eventtype}` : ""}
                        </b>
                      </div>
                      <div className="trainingDesc">
                        Description &nbsp;<b> {data.description}</b>
                      </div>
                      <div className="trainingCompletedCountHolder">
                        {" "}
                        <div className="trainingCompletedCountName">
                          Members{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.attended}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Notes{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.notecount}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Items{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.itemcount}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Photos{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.photocount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
    </div>
  );
};

export default PreviousTrainingList;
