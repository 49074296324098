import React from "react";
import LoginHeader from "../components/Login/LoginHeader";

const Login = () => {
  return (
    <>
      <LoginHeader />
    </>
  );
};

export default Login;
