import React from "react";
import ForgotPasswordHeader from "../components/Login/ForgotPasswordHeader";

const ForgotPassword = () => {
  return (
    <>
      <ForgotPasswordHeader />
    </>
  );
};

export default ForgotPassword;
