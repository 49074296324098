import React from "react";
import "./PopupNew.css";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../tailwind/dialog";


const PopupNew = ({
  isOpen,
  handleClose,
  content,
  handleSubmit,
  showSubmitButton,
  headerText,
  submitButtonText,
  loading,
  ...props
}) => {
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} >
        <DialogTitle>{headerText}</DialogTitle>
        {/*<DialogDescription>
          The refund will be reflected in the customer’s bank account 2 to 3 business days after processing.
        </DialogDescription>*/}
        <DialogBody className="mt-0" >{content}</DialogBody>
    
        {showSubmitButton && (
              <DialogActions>
          <button
            className="rounded-md bg-custom-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-custom-blue-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="rounded-md bg-custom-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-custom-blue-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
             {submitButtonText}
          </button>
        </DialogActions>
         )}
      </Dialog>
    </>
  );
};

export default PopupNew;
