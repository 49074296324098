import React from "react";
import "../components/Training/Training.css";

import UserInfoTrainer from "../components/AdminPage/AdminUserPage/UserInfoTrainer";

const UserEventsTrained = () => {
  return (
    <>
      <UserInfoTrainer />
    </>
  );
};

export default UserEventsTrained;
