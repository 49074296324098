import React, { useState, useEffect, useContext } from "react";

import ApparatusChecklist from "../../apis/ApparatusChecklist";
import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupWeb";
import PublicFolder from "../../apis/PublicFolder";

import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

import { useAuth } from "../../context/AuthContext";

import ApparatusChecklistTask from "./ApparatusChecklistTask";

import { ApparatusChecklistContext } from "../../context/ApparatusChecklist";

const ApparatusChecklistHeader = () => {
  const { setTaskList } = useContext(ApparatusChecklistContext);
  const { setTaskGroupInfo, taskGroup, setTaskGroup } = useContext(
    ApparatusChecklistContext
  );
  const { checklistInProgressId, setChecklistInProgressId } = useContext(
    ApparatusChecklistContext
  );

  const { compartmentId, setCompartmentId } = useContext(
    ApparatusChecklistContext
  );
  const { clid } = useContext(ApparatusChecklistContext);
  const { tgid, setTgid, apparatusId } = useContext(
    ApparatusChecklistContext
  );

  const history = useNavigate();

  const { id } = useParams();
  //// console.log(data2.apparatusid);
  //Popup

  const { currentUserSiteId, currentUserId, currentUserRole, currentFirstName, currentLastName } = useAuth();

  const [checklistName] = useState("");

  const [itemId, setItemId] = useState("");
  const [bagId, setBagId] = useState("");

  const [checklistStatus, setChecklistStatus] = useState("");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  // // console.log(data2.apparatusid);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApparatusChecklist.get(`/inprogress${id}`, {
          params: {
            checklistinprogress: id,
            currentUserSiteId,
          },
        });
        setTaskGroup(response.data.data.taskInfo);
        setChecklistInProgressId(id);
        /// console.log(response.data.data.taskInfo);
        //   setScheduledDate(response.data.data.checklistInfo.scheduleddate);
        //  setChecklistName(response.data.data.checklistInfo.checklistname);
        //    setApparatusId(response.data.data.checklistInfo.apparatusid);
        setChecklistStatus(response.data.data.checklistInfo.repeat);
        //     setClid(response.data.data.checklistInfo.clid);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [currentUserSiteId]);

  const fetchData2 = async (bagId, save) => {
    // console.log(bagId);
    // console.log("knjsdnfgkjsdlnjh");
    setBottomSwipeOpen(true);
    setTask("");
    setTaskCompleted("");
    setItemInfo("");
    setItemId("");
    try {
      const response = await ApparatusChecklist.get("/inprogressBagTask", {
        params: {
          bagId: bagId,
          checklistInProgressId: checklistInProgressId,
          clid: clid,
          tgid,
          currentUserSiteId,
        },
      });
      //// console.log(response.data.data.tasks);
      // // console.log(response.data.data.taskscompleted);

      setOverAllCheck(
        response.data.data.tasks.length + response.data.data.itemInfo.length
      );
      setItemInfo(response.data.data.itemInfo);
      setTask(response.data.data.tasks);
      setTaskCompleted(response.data.data.taskscompleted);
      setBagId(bagId);
      toggleTaskGroup();
      // // console.log(response.data.data.bagInfo[0].bagname);
      setBottomSwipeName(response.data.data.bagInfo[0].bagname);

      if (
        response.data.data.tasks.length ===
        response.data.data.taskscompleted.length &&
        save === true
      ) {
        fetchTaskGroupInfo(compartmentId, tgid);
        setBottomSwipeOpen(false);
      } else {
        setBottomSwipeOpen(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };
  // eslint-disable-next-line
  const [task, setTask] = useState("");
  // eslint-disable-next-line
  const [taskCompleted, setTaskCompleted] = useState("");
  // eslint-disable-next-line
  const [itemInfo, setItemInfo] = useState("");
  const [overAllCheck, setOverAllCheck] = useState("");

  const fetchTask = async (itemId, save) => {
    //// console.log(itemId);
    setBottomSwipeOpen(true);
    setTask("");
    setItemInfo("");
    setTaskCompleted("");
    setBagId("");
    try {
      const response = await ApparatusChecklist.get("/inprogressTask", {
        params: {
          itemId: itemId,
          checklistInProgressId: checklistInProgressId,
          clid: clid,
          tgid,
          currentUserSiteId,
        },
      });
      //    // console.log(response.data.data.tasks);
      setOverAllCheck(
        response.data.data.tasks.length + response.data.data.itemInfo.length
      );
      setItemInfo(response.data.data.itemInfo);
      setBottomSwipeName(response.data.data.itemInfo[0].name);

      setTask(response.data.data.tasks);
      setTaskCompleted(response.data.data.taskscompleted);
      setItemId(itemId);
      // // console.log("dlkfmkjsdnlmfkjsdnfji");
      toggleTaskGroup();
      if (
        response.data.data.tasks.length ===
        response.data.data.taskscompleted.length &&
        save === true
      ) {
        fetchTaskGroupInfo(compartmentId, tgid);
        setBottomSwipeOpen(false);
      } else {
        setBottomSwipeOpen(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const fetchTaskCompartment = async (save) => {
    try {
      const response = await ApparatusChecklist.get(
        "/inprogressTaskCompartment",
        {
          params: {
            checklistInProgressId: checklistInProgressId,
            clid: clid,
            tgid,
            currentUserSiteId,
          },
        }
      );
      setItemInfo("");
      setBagId("");
      setItemId("");

      setTask(response.data.data.tasks);
      setOverAllCheck(response.data.data.tasks.length);
      setTaskCompleted(response.data.data.taskscompleted);
      setBottomSwipeName(response.data.data.tasksgroup[0].taskgroupname);
      setTaskGroupInfo(response.data.data.tasksgroup);
      setTaskGroup(response.data.data.tasksgroup);
      toggleTaskGroup();
      if (
        response.data.data.tasks.length ===
        response.data.data.taskscompleted.length &&
        save === true
      ) {
        fetchTaskGroupInfo(compartmentId, tgid);
        setBottomSwipeOpen(false);
      } else {
        setBottomSwipeOpen(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  // eslint-disable-next-line
  const [error, setError] = useState("");

  const headers = {
    "content-type": "multipart/form-data",
  };

  //Popup Bag
  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };

  //Popup checklist

  const { bottomSwipeOpen, setBottomSwipeOpen } = useContext(
    ApparatusChecklistContext
  );

  const togglePopup3 = async (cid, tgid) => {
    try {
      const response = await ApparatusChecklist.get(
        "/inprogressTaskCompartment",
        {
          params: {
            checklistInProgressId: checklistInProgressId,
            clid: clid,
            tgid,
            currentUserSiteId,
          },
        }
      );
      //  // console.log(response.data.data.tasks);
      //  // console.log(response.data.data.itemTask);
      setItemInfo("");
      setBagId("");
      setItemId("");
      setCompartmentId(cid);
      setTgid(tgid);
      setTaskList(response.data.data.tasks);
      //   setItemTaskList(response.data.data.itemTask);
      //   setOverAllCheck(response.data.data.tasks.length);
      // setTaskCompleted(response.data.data.taskscompleted);
      //   setBottomSwipeName(response.data.data.tasksgroup[0].taskgroupname);
      setTaskGroupInfo(response.data.data.tasksgroup);

      // setIsOpen3(!isOpen3);
      setBottomSwipeOpen(!bottomSwipeOpen);
    } catch (err) {
      // console.log(err);
    }
  };

  const [taskGroupOpen, setTaskGroupOpen] = useState(false);

  const toggleTaskGroup = () => {
    setTaskGroupOpen(!taskGroupOpen);
  };
  // eslint-disable-next-line
  const [compartmentItems, setCompartmentItems] = useState("");
  // eslint-disable-next-line

  // eslint-disable-next-line
  const [bottomSwipeName, setBottomSwipeName] = useState(false);

  const fetchTaskGroupInfo = async (cid, tgid, save) => {
    try {
      const response = await ApparatusChecklist.get(
        "/inprogressTaskGroupInfo",
        {
          params: {
            cid: cid,
            checklistInProgressId: checklistInProgressId,
            clid: clid,
            tgid: tgid,
            currentUserSiteId,
          },
        }
      );
      //  // console.log(response.data.data.compartmentItems);
      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }
      toggleTaskGroup();
      setTgid(tgid);
      setCompartmentId(cid);
      setCompartmentItems(response.data.data.compartmentItems);
    } catch (err) {
      // console.log(err);
    }
  };

  //Save Chechecklist
  const handleSaveBtnSubmit = async (e) => {
    e.preventDefault();
    setError("");
    //stop the page from reloading
    // console.log("working");

    let myForm = e.target;
    let formData = new FormData(myForm);
    let checkamount = 0;
    let currentKey = null;
    let done = false;

    for (let key of formData.keys()) {
      //  // console.log(key, formData.get(key));

      /* if (formData.get(key) === "Fail") {
         //// console.log(key, formData.getAll(key)[1]);
         if (
           formData.getAll(key)[1] === null ||
           formData.getAll(key)[1] === ""
         ) {
           alert(`Enter note for all failed task`);
 
           //document.body.scrollTop = document.documentElement.scrollTop = 0;
           return false;
         }
       } else {
       }
 */
      if (key !== currentKey && key !== "file" && formData.get(key) !== "") {
        //  // console.log(formData.get(key));
        //  // console.log(key);
        currentKey = key;
        checkamount += 1;
      }
    }
    // console.log("amount");
    // console.log(checkamount);
    // console.log(overAllCheck);
    if (checkamount === overAllCheck) {
      done = true;
    }
    try {
      console.log("sdlmnfjdkslnfldkjhsn");

      setLoading(true);
      formData.set("apparatusid", apparatusId);
      formData.set("checklistInProgressId", checklistInProgressId);
      formData.set("done", done);
      formData.set("itemId", itemId);
      formData.set("bagId", bagId);
      formData.set("compartmentId", compartmentId);
      formData.set("currentUserId", currentUserId);
      formData.set("currentLastName", currentLastName);
      formData.set("currentFirstName", currentFirstName);

      formData.set("tgid", tgid);
      formData.set("currentUserSiteId", currentUserSiteId);

      const response2 = await ApparatusChecklist.post(
        `/checklist${id}`,
        formData,
        headers
      );

      setLoading(false);
      setChecklistInProgressId(response2.data.data.checklistInProgressId);
      fetchTaskGroupInfo(compartmentId, tgid);
      setTaskGroup(response2.data.data.taskInfo);
      //// console.log(response2.data.data.taskInfo);
      let save = true;

      if (bagId !== "") {
        fetchData2(bagId, save);
      } else if (itemId !== "") {
        fetchTask(itemId, save);
      } else {
        fetchTaskCompartment(save);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  //Submit Checklist
  const handleSaveBtnSubmit2 = async (e) => {
    e.preventDefault();
    setError("");

    var formData = new FormData(document.querySelector("form"));

    for (let i = 0; i < taskGroup.length; i++) {
      if (taskGroup[i].totalcount !== taskGroup[i].totalcountinprogress) {
        alert(`Complete all task before completing the checklist`);
        return false;
      }
    }

    try {
      setLoading(true);
      // console.log(addMember);
      formData.set("apparatusid", apparatusId);
      formData.set("checklistInProgressId", checklistInProgressId);
      formData.set("currentUserId", currentUserId);
      formData.set("checklistName", checklistName);
      formData.set("checklistStatus", checklistStatus);
      formData.set("currentUserSiteId", currentUserSiteId);

      const response2 = await ApparatusChecklist.post(
        `/submitchecklist${id}`,
        formData,
        headers
      );

      setLoading(false);
      //   console.log("fjkdnkfdj");
      setChecklistInProgressId(response2.data.data.checklistInProgressId);

      history({
        pathname: `/apparatus/${apparatusId}/checklist`,
      });
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response = await ApparatusChecklist.post(
        `/deletechecklistInProgress`,
        {
          checklistInProgressId,
          currentUserSiteId,
        }
      );

      history({
        pathname: `/apparatus/${apparatusId}/checklist`,
      });
    } catch (err) { }
  };

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
              ? "topbar headerActive collapse"
              : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>

        <Header content={<b>{checklistName}</b>} />
      </div>

      <div className="pageHeader">
        <b>{checklistName}</b>
      </div>

      <div className="taskContainerHolder">
        {/*error && <div className="alert2">{error}</div>*/}
        <form id="form" onSubmit={handleSaveBtnSubmit}>
          <div className="taskGroupContainer">
            {taskGroup &&
              taskGroup.map((data, i) => {
                //  console.log(data);
                //  // console.log("lsdkmflfdm");
                return (
                  <div key={i}>
                    {data.done === true ? (
                      data.cid === null ? (
                        <>
                          {data.imagepath != null ? (
                            <>
                              <div className="itemPicture3">
                                <img
                                  src={PublicFolder.baseURL + data.imagepath}
                                  alt="Did Not Load"
                                  className="compartmentImg23"
                                />
                              </div>

                              <div
                                className="taskGroupNameDone"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              className="taskGroupNameDone"
                              onClick={() => togglePopup3(data.cid, data.ltgid)}
                            >
                              <div className="taskGroupName3">
                                <b>{data.taskgroupname}</b>
                              </div>
                              <div className="taskGroupAmount">
                                <b>
                                  {data.totalcountinprogress}/{data.totalcount}
                                </b>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {data.imagepath != null ? (
                            <div className="itemPicture3">
                              <img
                                src={PublicFolder.baseURL + data.imagepath}
                                alt="Did Not Load"
                                className="compartmentImg23"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )
                    ) : data.cid === null ? (
                      <>
                        {data.imagepath != null ? (
                          <>
                            <div className="itemPicture3">
                              <img
                                src={PublicFolder.baseURL + data.imagepath}
                                alt="Did Not Load"
                                className="compartmentImg23"
                              />
                            </div>
                            {data.totalcountinprogress === data.totalcount ? (
                              <div
                                className="taskGroupNameDoneImg"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="taskGroupNameImg"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {data.totalcountinprogress === data.totalcount ? (
                              <div
                                className="taskGroupNameDone"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="taskGroupName"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {data.totalcountinprogress === data.totalcount ? (
                          <>
                            {/* <div
                            className="taskGroupNameDone"
                            onClick={() => togglePopup3(data.cid, data.ltgid)}
                          >
                            <b>{data.taskgroupname}33333</b>
                            <div className="taskGroupAmount">
                              <b>
                                {data.totalcountinprogress}/{data.totalcount}
                              </b>
                            </div>
                        </div>*/}
                          </>
                        ) : (
                          <>
                            {/*<div
                            className="taskGroupName"
                            onClick={() => togglePopup3(data.cid, data.ltgid)}
                          >
                            <b>{data.taskgroupname}4444</b>
                            <div className="taskGroupAmount">
                              <b>
                                {data.totalcountinprogress}/{data.totalcount}
                              </b>
                            </div>
                          </div>*/}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {data.imagepath != null ? (
                          <>
                            <div className="itemPicture3">
                              <img
                                src={PublicFolder.baseURL + data.imagepath}
                                alt="Did Not Load"
                                className="compartmentImg23"
                              />
                            </div>
                            {data.totalcountinprogress === data.totalcount ? (
                              <div
                                className="taskGroupNameDoneImg"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="taskGroupNameImg"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {data.totalcountinprogress === data.totalcount ? (
                              <div
                                className="taskGroupNameDone"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="taskGroupName"
                                onClick={() =>
                                  togglePopup3(data.cid, data.ltgid)
                                }
                              >
                                <div className="taskGroupName3">
                                  <b>{data.taskgroupname}</b>
                                </div>
                                <div className="taskGroupAmount">
                                  <b>
                                    {data.totalcountinprogress}/
                                    {data.totalcount}
                                  </b>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </form>
        {!taskGroup && (
          <div className="text-center p-3">
            <span className="spinner-border spinner-border-lg align-center"></span>
          </div>
        )}
      </div>

      <div className="editApparatusBtnContainer">
        <div
          className="confirmCancelApparatusBtn"
          onClick={handleSaveBtnSubmit2}
        >
          <b>Submit</b>
        </div>

        {currentUserRole === "Admin" && (
          <div
            className="confirmDeleteApparatusBtn"
            onClick={togglePopup2}
          //onClick={togglePopup2}
          >
            <b>Delete</b>
          </div>
        )}
      </div>

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete </b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>This checklist will no longer be available</b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>THIS WILL DELETE THE CURRENT CHECKLIST AND NOT SAVE IT</b>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup2}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDelete}
                //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup2}
        />
      )}

      <div className="bottomSwipeContainer">
        {bottomSwipeOpen && <ApparatusChecklistTask />}
      </div>
    </>
  );
};

export default ApparatusChecklistHeader;
