import React, { useState, useEffect } from "react";
import ReportsFinder from "../../apis/ReportsFinder";
import { useAuth } from "../../context/AuthContext";

import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";


import imageToBase64 from "image-to-base64/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PublicFolder from "../../apis/PublicFolder";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReportsToDoList = () => {
  const { currentUserSiteId } = useAuth();

  const [results, setResults] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [from, setFrom] = useState("");
  const [thru, setThru] = useState("");

  const [pdfMemberList, setPdfMemberList] = useState("");
  const [pdfFrom, setPdfFrom] = useState("");
  const [pdfThru, setPdfThru] = useState("");



  const test = () => {
    document.getElementById("myDropdown").classList.toggle("show");

    let arrowCollapseRight = document.getElementById("bx-arrow-from-right2");
    let arrowCollapseLeft = document.getElementById("bx-arrow-from-left2");
    arrowCollapseRight.classList.toggle("collapse");

    if (arrowCollapseRight.classList.contains("collapse")) {
      arrowCollapseRight.classList.remove("disabled");
      arrowCollapseLeft.classList.add("disabled");
    } else {
      arrowCollapseRight.classList.add("disabled");
      arrowCollapseLeft.classList.remove("disabled");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ReportsFinder.get(`/getToDoOnLoad`, {
          params: {
            currentUserSiteId,
          },
        });

        setMemberList(response.data.data.memberNames);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]);

  const getReset = () => {
    setResults([]);
    setFrom("");
    setThru("");

    var options12 = document.getElementById("select-category");
    var options = document.getElementById("select-member");

    for (var i = 0; i < options12.length; i++) {
      options12[i].selected = false;
    }
    for (var ii = 0; ii < options.length; ii++) {
      options[ii].selected = false;
    }
  };

  const getResults = async (e) => {
    e.preventDefault();

    fetchResults();
    convertToBase64();
  };
  // eslint-disable-next-line 
  const getPDF = () => {
    generatePdf();
  };

  const fetchResults = async () => {
    try {

      var options = document.getElementById("select-member").selectedOptions;
      var members = Array.from(options).map(({ value }) => value);
      var membersNames = Array.from(options).map(({ innerHTML }) => innerHTML);

      const response = await ReportsFinder.get("/getToDoList", {
        params: {
          currentUserSiteId,
          from,
          thru,
          members: members.length === 0 ? "" : members,
        },
      });

      console.log(response.data.data.results)

      const fromholder = new Date(from).toLocaleDateString();
      const thruholder = new Date(thru).toLocaleDateString();
      setPdfFrom(fromholder);
      setPdfThru(thruholder);


      setPdfMemberList(
        membersNames.length === 0 ? "Not specified" : membersNames.toString()
      );

      setResults(response.data.data.results);
    } catch (err) {
      // console.log(err);
    }
  };

  const [base64IMG, setBase64IMG] = useState("");

  const convertToBase64 = () => {
    imageToBase64(PublicFolder.baseURL + "LogonIcon.png") // Path to the image
      .then((response) => {
        setBase64IMG(response);
      });
  };

  /* prettier-ignore */
  //let heading = [["Date","Individual Event Name", "Event Name", "Description", "Members"]];

  let heading = [
    [
      { text: "Date", bold: true },
      { text: "Title", bold: true },
      { text: "Note", bold: true },
      { text: "Status", bold: true },
      { text: "Member", bold: true },
      { text: "Comments", bold: true },

    ]
  ];

  const options2 = results.map((elt, i) => [
    elt.submiteddate,
    elt.title,
    elt.note,
    elt.todostatus,
    elt.username,
    elt.alertcomments.map((data2, i) => [data2.commentDate + " : " + data2.commentNote + " - " + data2.commentUser]),
  ]);

  let bodyData = heading.concat(options2);
  const generatePdf = async () => {
    var docDefinition = {
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [20, 60, 20, 20],
      // pageOrientation: "landscape",
      info: {
        title: "Individual Events Report",
      },
      footer: function (currentPage, pageCount) {
        var columns = [
          {
            text: currentPage.toString() + " of " + pageCount,
            width: "auto",
            alignment: "center",
          },
        ];
        return columns;
      },

      header: {
        columns: [
          {
            text: "Individual Event Report",
            bold: true,
            fontSize: 26,

            margin: [20, 20, 0, 0],
          },
          {
            image: `data:image/png;base64,${base64IMG}`,
            width: 50,
            height: 50,
            alignment: "right",
            margin: [0, 10, 15, 0],
          },
        ],
      },

      content: [
        {
          text: `Date range:    ${pdfFrom} - ${pdfThru}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        {
          text: `Members:       ${pdfMemberList}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        { width: "*", text: "", margin: [0, 0, 0, 30] },
        {
          text: `Number of records: ${results.length}`,
          fontSize: 10,
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableSyle",

          // layout: "lightHorizontalLines", // optional
          table: {
            // headers are automatically repeated if the tasble spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [60, 110, 120, 120, 100],

            body: bodyData,
          },
        },
      ],

      styles: {
        tableSyle: {
          fontSize: 10,
        },
      },
    };

    //pdfMake.createPdf(docDefinition).download();
    pdfMake.createPdf(docDefinition).open();
  };

  return (
    <>
  

      <form onSubmit={getResults} className="formClass">
        <div className="ReportResultsCommandsHolder">
          {results.length > 0 ? (
            <div className="ReportResultsCommandsPDF">
              <div className="ReportResultsCommandsBtnPDF" >
                <b>PDF</b>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="ReportResultsCommands">
            <button className="ReportResultsCommandsGenerate" type="submit">
              <b>Generate</b>
            </button>
            <div className="ReportResultsCommandsReset" onClick={getReset}>
              <b>Reset</b>
            </div>
          </div>
        </div>
        <div className="inventorySelectHolder">
          <div className="inventorySelect1">
            <label className="NewInputLabel" data-domain="From">
              <input
                className="NewInput"
                type="date"
                placeholder=""
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                required
              />
            </label>
          </div>

          <div className="inventorySelect2">
            <label className="NewInputLabel" data-domain="Thru">
              <input
                className="NewInput"
                type="date"
                placeholder=""
                value={thru}
                onChange={(e) => setThru(e.target.value)}
                required
              />
            </label>
          </div>
        </div>
      </form>
      <div className="filterdropdown">
        <button className="filterdropbtn" onClick={() => test()}>
          <div className="filterHolder">
            <div className="filterDiv">
              <b>Filter and sort options</b>
            </div>
          </div>
          <div className="filterArrow disabled" id="bx-arrow-from-right2">
            <i className="bx" id="logo-name__icon">
              <AiOutlineArrowUp />
            </i>
          </div>
          <div className="filterArrow" id="bx-arrow-from-left2">
            <i className="bx" id="logo-name__icon">
              <AiOutlineArrowDown />
            </i>
          </div>
        </button>

        <div id="myDropdown" className="dropdown-contentReports">

          <label className="NewInputLabel" data-domain="Select Members">
            <select multiple className="NewInput" id="select-member">
              {memberList.map(function (e, i) {
                return (
                  <option key={i} value={e.user_id}>
                    {e.firstname + " " + e.lastname}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
      {results.length > 0 ? (
        <div className="ReportRecords">Number of records: {results.length}</div>
      ) : (
        ""
      )}


      <div className="ReportResultsContainer">
        <div className="reportsPopupContainer">
          {results.length > 0 ? (
            <>
              <table id="reportsTable">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Title</th>
                    <th>Note</th>
                    <th>Status</th>
                    <th>Member</th>
                    <th>Comments</th>
                  </tr>
                  {results.map((data, i) => {
                    // console.log(data);
                    return (
                      <tr key={i}>
                        <td>{data.submiteddate}</td>
                        <td>{data.title}</td>

                        <td>{data.note}</td>

                        <td>{data.todostatus}</td>
                        <td>{data.username}</td>

                        <td>
                          {data.alertcomments.map((data2, i) => [
                            data2.commentDate + " : " + data2.commentNote + " - " + data2.commentUser + ", ",
                          ])}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="emptySpaceReport"> </div>
    </>
  );
};

export default ReportsToDoList;
