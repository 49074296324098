import { useQuery } from "@tanstack/react-query";
import InventoryFinder from "../../apis/InventoryFinder";





const fetchMainInventory = ({ queryKey }) => {
  var  categorySearch = localStorage.getItem('InventoryFilters');
  var  InventorySearchQuery = localStorage.getItem('InventorySearchQuery');
  var  InventoryInspectionFilter = localStorage.getItem('InventoryInspectionFilter');
  var  InventoryMemberFilter= localStorage.getItem('InventoryMemberFilter');
  var  apparatusFilter= localStorage.getItem('InventoryLocationFilter');
  var  bagFilter= localStorage.getItem('InventoryBagFilter');

  const currentUserSiteId = queryKey[1];
  return InventoryFinder.get(`/getAllInventory`, {
    params: {
      currentUserSiteId,
      categorySearch,
      InventorySearchQuery,
      InventoryInspectionFilter,
      InventoryMemberFilter,
      apparatusFilter,
      bagFilter
    },
  });
};

export const useMainInventoryData = (currentUserSiteId) => {
  return useQuery(["mainInventory", currentUserSiteId], fetchMainInventory);
};
