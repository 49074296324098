import React from "react";

import Header from "../Header/Header";

import { AiOutlineMenu } from "react-icons/ai";

const PreviousTrainingHeader = () => {



  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  return (
    <>
      <div className="addItem"></div>
  

      <div id="topbar" className="topbar headerActive">
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>
 
        <Header content={<b>Completed Events</b>} />
      </div>



      <div className="topbarEmptySpace"></div>
    </>
  );
};

export default PreviousTrainingHeader;
