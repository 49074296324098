import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api/user"
    : `http://192.168.1.249:3001/api/user`;

export default axios.create({
  baseURL,
});
