import React, { useRef, useEffect, useState, useContext } from "react";

import { ApparatusChecklistContext } from "../../context/ApparatusChecklist";

import PublicFolder from "../../apis/PublicFolder";
import PopupWeb from "../Popup/PopupWeb";

import ApparatusChecklist from "../../apis/ApparatusChecklist";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";

import { MdFlipCameraAndroid } from "react-icons/md";
import { QrReader } from "react-qr-reader";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";

const ApparatusChecklistTask = () => {
  const { taskList, setTaskList, apparatusId } = useContext(
    ApparatusChecklistContext
  );
  const { setItemTaskList, setTaskGroup } = useContext(
    ApparatusChecklistContext
  );
  const { taskGroupInfo } = useContext(ApparatusChecklistContext);
  const { checklistInProgressId } = useContext(ApparatusChecklistContext);

  const { clid } = useContext(ApparatusChecklistContext);
  const { tgid } = useContext(ApparatusChecklistContext);

  const {
    currentUserRole,
    currentUserId,
    currentUserSiteId,
    currentFirstName,
    currentLastName,
  } = useAuth();
  const { id } = useParams();

  const { compartmentId } = useContext(ApparatusChecklistContext);

  const { bottomSwipeOpen, setBottomSwipeOpen } = useContext(
    ApparatusChecklistContext
  );

  const bottomSwipeFunc = () => {
    setBottomSwipeOpen(false);
  };

  const [bagItemsList, setBagItemsList] = useState(false);
  const [inspectionNotes, setInspectionNotes] = useState("");
  const [isOpenItemScanner, setIsOpenItemScanner] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleItemScanner = (currentBagId, currentBagName) => {
    fetchData3(currentBagId);
    setCurrentBagName(currentBagName);
    // setIsOpenItemScanner(!isOpenItemScanner);
    // setCurrentBagId(currentBagId);
  };

  const [itemScannerCompartment, setItemScannerCompartment] = useState(false);

  const toggleCompartmentItemScanner = () => {
    setIsOpen(!isOpen1);
    setItemScannerCompartment(!itemScannerCompartment);
  };

  const [isOpenRemoveItem, setIsOpenRemoveItem] = useState(false);
  const [deleteBagCount, setDeleteBagCount] = useState(false);
  const [deletedBagName, setDeletedBagName] = useState(false);

  const toggleRemvoveItem = (itemid, name, bagcount, bagname) => {
    // console.log(bagcount);
    setCurrentBagName(name);
    setDeleteItemName(name);
    setDeleteItemId(itemid);
    setDeleteBagCount(bagcount);
    setDeletedBagName(bagname);
    setItemInfoOpen(!itemInfoOpen);
    setIsOpenRemoveItem(!isOpenRemoveItem);

    // setIsOpenItemScanner(!isOpenItemScanner);
    // setCurrentBagId(currentBagId);
  };

  const fetchData3 = async (currentBagId) => {
    try {
      const response = await ApparatusChecklist.get(`/inprogresssBagItem`, {
        params: {
          currentBagId: currentBagId,
          currentUserSiteId,
        },
      });

      setBagItemsList(response.data.data.bagItems);
      setIsOpenItemScanner(!isOpenItemScanner);
      setCurrentBagId(currentBagId);
    } catch (err) {
      // console.log(err);
    }
  };

  const togglePopupBagAddItem2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpenItemScanner(!isOpenItemScanner);
  };

  const [currentBagId, setCurrentBagId] = useState(false);
  const [currentBagName, setCurrentBagName] = useState(false);

  const [inventoryCategory, setInventoryCategory] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [inventoryItemsEvents, setInventoryItemsEvents] = useState([]);
  const [itemArea, setItemArea] = useState("");

  // // console.log(data2.apparatusid);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApparatusChecklist.get(`/inprogressInfo`, {
          params: {
            currentUserSiteId,
          },
        });

        // // console.log(response.data.data.packNames);
        setInventoryCategory(response.data.data.inventoryCategory);
      } catch (err) {
        // console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);


  const [inspectionPhotos, setInspectionPhotos] = useState([]);

  const handleFileInputInspectionPhotos = (e) => {
    const files = Array.from(e.target.files);
    setInspectionPhotos((prevImages) => [...prevImages, ...files]);
  };

  const removeInspectionPhotos = (index) => {
    setInspectionPhotos((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  //custom image
  const fileRef = useRef();


  //Item move submit
  const handleSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response2 = await ApparatusChecklist.post(
        `/inprogressPackInfoAdd`,
        {
          currentBagId,
          checklistInProgressId,
          compartmentId,
          currentUserSiteId,
        }
      );
    } catch (err) { }
  };

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState("False");

  const headers = {
    "content-type": "multipart/form-data",
  };

  const handleSaveBtnSubmit = async (e) => {
    e.preventDefault();
    setError("");
    let myForm = e.target;
    let formData = new FormData(myForm);
    let currentKey = null;
    setLoading(true);
    setLoading2("True");

    for (let key of formData.keys()) {
      if (key !== currentKey && key !== "file" && formData.get(key) !== "") {
        currentKey = key;
      }
    }

    try {
      formData.set("apparatusid", apparatusId);
      formData.set("checklistInProgressId", checklistInProgressId);
      formData.set("compartmentId", compartmentId);
      formData.set("currentUserId", currentUserId);
      formData.set("clid", clid);
      formData.set("tgid", tgid);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("currentLastName", currentLastName);
      formData.set("currentFirstName", currentFirstName);

      // Append inspection photos
      inspectionPhotos.forEach((photo, index) => {
        formData.append(`inspectionPhotos`, photo);
      });

      // Append inspection notes
      formData.set("inspectionNotes", inspectionNotes);

      const response2 = await ApparatusChecklist.post(
        `/checklist${id}`,
        formData,
        headers
      );

      setTaskGroup(response2.data.data.taskInfo);
      setLoading(false);
      setLoading2("False");
    } catch (err) {
      setLoading(false);
    }
  };

  const addCompletedTask = (value, tid) => {
    document.getElementById(`UserChanged${tid}`).value = true;
  };

  const handleFileInput = (e, tid) => {
    // console.log(e.target.files[0].name);
    // console.log(tid);
    document.getElementById(tid + "!").value = e.target.files[0].name;
    document.getElementById(`UserChanged${tid}`).value = true;
  };

  function toggleClass(id) {
    document.getElementById(id).classList.toggle("show22");
  }

  function toggleClass2(id) {
    document.getElementById(id).classList.toggle("show22");
  }
  const [isOpen1, setIsOpen] = useState(false);

  const togglePopupAddItem = () => {
    setIsOpen(!isOpen1);
    setItemLocation("");
    setAddedInvId("");
    setAddedItemId("");
    setItemArea("");
    setError("");
  };

  const [itemLocation, setItemLocation] = useState("");

  const fetchData2 = async (id) => {
    try {
      if (currentUserRole === "Admin") {
        const response = await ApparatusChecklist.get(`/inprogressitemsadmin`, {
          params: {
            itemId: id,
            currentUserSiteId,
          },
        });

        setAddedItemId("");
        setAddedEventId("");
        setItemArea("");
        setInventoryItems(response.data.data.inventoryItems);
        setInventoryItemsEvents(response.data.data.inventoryItemEvents);
        setAddedInvId(id);
      } else {
        const response = await ApparatusChecklist.get(`/inprogressitems`, {
          params: {
            itemId: id,
            currentUserSiteId,
          },
        });

        setAddedItemId("");
        setAddedEventId("");
        setItemArea("");
        setInventoryItems(response.data.data.inventoryItems);
        setInventoryItemsEvents(response.data.data.inventoryItemEvents);
        setAddedInvId(id);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const [addedInvId, setAddedInvId] = useState();
  const [addedItemId, setAddedItemId] = useState();
  const [addedEventId, setAddedEventId] = useState();

  const toggleSetAddedItemSearch = (itemid) => {
    fetchData55(itemid);
    setAddedItemId(itemid);
    setAddedEventId("");
  };

  const toggleSetAddedItemSearch2 = (itemid) => {
    fetchData555(itemid);
    setAddedItemId(itemid);
    setAddedEventId("");
  };

  const fetchData55 = async (id) => {
    try {
      const response = await ApparatusChecklist.get(
        `/inprogressitemsLocation`,
        {
          params: {
            itemId: id,
            currentUserSiteId,
          },
        }
      );
      setItemArea(response.data.data.itemArea);
    } catch (err) {
      // console.log(err);
    }
  };

  const fetchData555 = async (id) => {
    try {
      const response = await ApparatusChecklist.get(
        `/inprogressitemsLocation`,
        {
          params: {
            itemId: id,
            currentUserSiteId,
          },
        }
      );
      fetchData2(response.data.data.inventoryItems[0].invid);
      setItemArea(response.data.data.itemArea);
    } catch (err) {
      // console.log(err);
    }
  };

  //remove event
  const handleAddedItem = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      // console.log("ofdjmfdsmifjsandfipjsanisnf");
      console.log(compartmentId);
      if (compartmentId === "" || compartmentId === null) {
        setError("No compartment linked to this task group");
      } else if (bagAddId !== "") {
        // console.log("ffff");

        // eslint-disable-next-line
        const response = await ApparatusChecklist.post("/inprogressAddedBag", {
          checklistInProgressId: checklistInProgressId,
          addedItemId,
          apparatusid: apparatusId,
          compartmentId,
          addedInvId,
          currentUserSiteId,
          bagAddId,
          addedEventId,
          clid: clid,
          tgid: tgid,
        });

        // setBagInfoOpen(false);
        setBagInfoAdd(false);
        setBagAddName("");
        setBagAddId("");
      } /*else if (moveBagCheck === true && bagAddId !== "") {
        // eslint-disable-next-line
        const response = await ApparatusChecklist.post(
          "/inprogressAddedItemAndBag",
          {
            checklistInProgressId: checklistInProgressId,
            addedItemId,
            apparatusid: apparatusId,
            compartmentId,
            addedInvId,
            currentUserSiteId,
            addedEventId,

            clid: clid,
            tgid: tgid,
          }
        );
        togglePopupAddItem();
      }*/ else {
        console.log("111111");

        // eslint-disable-next-line
        const response = await ApparatusChecklist.post("/inprogressAddedItem", {
          checklistInProgressId: checklistInProgressId,
          addedItemId,
          apparatusid: apparatusId,
          compartmentId,
          addedInvId,
          currentUserSiteId,
          addedEventId,
          moveBagCheck,
          clid: clid,
          tgid: tgid,
        });
        togglePopupAddItem();
      }

      const response3 = await ApparatusChecklist.get(
        "/inprogressTaskCompartment",
        {
          params: {
            checklistInProgressId: checklistInProgressId,
            clid: clid,
            tgid: tgid,
            currentUserSiteId,
          },
        }
      );

      setAddedItemId("");
      setAddedEventId("");
      setItemArea("");
      setTaskGroup(response3.data.data.taskGroup);

      setTaskList(response3.data.data.tasks);
      setItemTaskList(response3.data.data.itemTask);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  //remove event
  const handlebagUnassign = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await ApparatusChecklist.post("/inprogressBagUnassign", {
        checklistInProgressId: checklistInProgressId,
        addedItemId,
        apparatusid: apparatusId,
        compartmentId,
        addedInvId,
        currentUserSiteId,
        bagAddId,
        addedEventId,
        clid: clid,
        tgid: tgid,
      });

      // setBagInfoOpen(false);
      setBagAddName("");
      setBagAddId("");
      setBagUnssignOpen(false);

      const response3 = await ApparatusChecklist.get(
        "/inprogressTaskCompartment",
        {
          params: {
            checklistInProgressId: checklistInProgressId,
            clid: clid,
            tgid: tgid,
            currentUserSiteId,
          },
        }
      );

      setAddedItemId("");
      setAddedEventId("");
      setItemArea("");
      setTaskGroup(response3.data.data.taskGroup);

      setTaskList(response3.data.data.tasks);
      setItemTaskList(response3.data.data.itemTask);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  //remove event
  const handleBagAddedItem = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await ApparatusChecklist.post(
        "/inprogressBagAddedItem",
        {
          currentBagId,
          addedItemId,
          addedInvId,
          currentUserSiteId,
        }
      );

      const response3 = await ApparatusChecklist.get(
        "/inprogressTaskCompartment",
        {
          params: {
            checklistInProgressId: checklistInProgressId,
            clid: clid,
            tgid: tgid,
            currentUserSiteId,
          },
        }
      );
      setBagItemsList(response.data.data.bagItems);

      setTaskList(response3.data.data.tasks);
      setItemTaskList(response3.data.data.itemTask);
      setTaskGroup(response3.data.data.taskGroup);

      togglePopupBagAddItem2();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [isOpen3, setIsOpen3] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemName, setDeleteItemName] = useState("");

  const toggleDeleteBagItem = (itemid, itemName) => {
    setIsOpen3(!isOpen3);
    setIsOpenItemScanner(!isOpenItemScanner);
    setDeleteItemId(itemid);

    setDeleteItemName(itemName);
  };

  //remove event
  const handleBagRemovedItem = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await ApparatusChecklist.post(
        "/inprogressBagDeleteItem",
        {
          currentBagId,
          deleteItemId,
          currentUserSiteId,
        }
      );

      const response3 = await ApparatusChecklist.get(
        "/inprogressTaskCompartment",
        {
          params: {
            checklistInProgressId: checklistInProgressId,
            clid: clid,
            tgid: tgid,
            currentUserSiteId,
          },
        }
      );
      setBagItemsList(response.data.data.bagItems);

      setTaskList(response3.data.data.tasks);
      setItemTaskList(response3.data.data.itemTask);
      setTaskGroup(response3.data.data.taskGroup);

      setIsOpen3(!isOpen3);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  //remove event
  const handleBagRemovedItem2 = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await ApparatusChecklist.post(
        "/inprogressTaskDeleteItem",
        {
          deleteItemId,
          currentUserSiteId,
        }
      );

      const response3 = await ApparatusChecklist.get(
        "/inprogressTaskCompartment",
        {
          params: {
            checklistInProgressId: checklistInProgressId,
            clid: clid,
            tgid: tgid,
            currentUserSiteId,
          },
        }
      );
      setIsOpenRemoveItem(!isOpenRemoveItem);

      setBagItemsList(response.data.data.bagItems);

      setTaskList(response3.data.data.tasks);
      setItemTaskList(response3.data.data.itemTask);
      setTaskGroup(response3.data.data.taskGroup);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const sideBar = document.getElementById("sidebar");

  const handleError = (err) => {
    // console.error(err);
  };

  const handleScan = (data) => {
    if (data) {
      // console.log(data);

      let data2 = data.split("?")[1];
      let search = data2.split(":")[0];
      let number = data2.split(":")[1];
      // console.log(search);
      // console.log(number);
      if (search === "itemId") {
        setItemScannerCompartment(!itemScannerCompartment);
        setIsOpen(!isOpen1);
        toggleSetAddedItemSearch2(number);
      } else if (search === "bagId") {
        setItemScannerCompartment(!itemScannerCompartment);
        setIsOpen(!isOpen1);
        toggleSetAddedItemSearch2(number);
      }
    }
  };

  const handleScan2 = (data) => {
    if (data) {
      // console.log(data);

      let data2 = data.split("?")[1];
      let search = data2.split(":")[0];
      let number = data2.split(":")[1];
      // console.log(search);
      // console.log(number);
      if (search === "itemId") {
        fetchItemData(number);
      } else if (search === "bagId") {
        // setItemScannerCompartment(!itemScannerCompartment);
        //setIsOpen(!isOpen1);
        // toggleSetAddedItemSearch(number);
      }
    }
  };

  //needs to be fixed
  const fetchItemData = async (itemid) => {
    try {
      // console.log("fkdjsnkjsdnfkjdsnj");
      const response = await ApparatusChecklist.get("/getScannedItemInfo", {
        params: {
          itemId: itemid,
          currentUserSiteId,
        },
      });
      //  console.log(response.data.data);
      setInventoryItems(response.data.data.inventoryItems);
      setInventoryItemsEvents(response.data.data.inventoryItemEvents);
      setAddedInvId(response.data.data.itemInfo[0].invid);
      setAddedItemId(response.data.data.itemInfo[0].itemid);
    } catch (err) {
      console.log(err);
    }
  }; //?itemId:14

  const [selfie, setSelfie] = useState(false);

  const [bagInfoOpen, setBagInfoOpen] = useState(false);
  const [bagInfo, setBagInfo] = useState([]);

  const toggleBagInfo = async (e, bagId) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response3 = await ApparatusChecklist.get("/bagInfo", {
        params: {
          bagId: bagId,
          currentUserSiteId,
        },
      });
      //   console.log(response3.data.data.bagInfo);

      setBagInfo(response3.data.data.bagInfo);
      setLoading(false);
      setBagInfoOpen(!bagInfoOpen);
    } catch (err) {
      setLoading(false);
    }
  };

  const toggleBagInfoClose = () => {
    setBagInfoOpen(!bagInfoOpen);
  };

  const [bagInfoAdd, setBagInfoAdd] = useState(false);
  const [bagAddName, setBagAddName] = useState("");
  const [bagAddId, setBagAddId] = useState("");

  const toggleBagAddOpen = (bagid, bagname) => {
    setBagInfoAdd(!bagInfoAdd);
    setBagInfoOpen(!bagInfoOpen);
    setBagAddName(bagname);
    setBagAddId(bagid);
    setAddedInvId("");
    setAddedItemId("");
    setItemArea("");
  };

  const toggleBagAddClose = () => {
    setBagInfoOpen(!bagInfoOpen);
    setBagInfoAdd(!bagInfoAdd);
    setBagAddName("");
    setBagAddId("");
    setAddedInvId("");
    setAddedItemId("");
    setItemArea("");
  };

  const [bagUnssignOpen, setBagUnssignOpen] = useState(false);

  const toggleBagUnassignOpen = (bagid, bagname) => {
    setBagInfoOpen(!bagInfoOpen);
    setBagUnssignOpen(!bagUnssignOpen);
    setBagAddName(bagname);
    setBagAddId(bagid);
    setAddedInvId("");
    setAddedItemId("");
  };

  const toggleBagUnassignClose = (bagid, bagname) => {
    setBagInfoOpen(!bagInfoOpen);
    setBagUnssignOpen(!bagUnssignOpen);
    setBagAddName("");
    setBagAddId("");
    setAddedInvId("");
    setAddedItemId("");
  };

  const [itemInfoOpen, setItemInfoOpen] = useState(false);
  const [itemInfo, setItemInfo] = useState([]);

  const toggleItemInfo = async (e, itemId) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response3 = await ApparatusChecklist.get("/itemInfo", {
        params: {
          itemId: itemId,
          currentUserSiteId,
        },
      });
      //  console.log(response3.data.data.itemInfo);

      setItemInfo(response3.data.data.itemInfo);
      setItemInfoOpen(!itemInfoOpen);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const toggleItemClose = () => {
    setItemInfoOpen(!itemInfoOpen);
  };

  const [moveBagCheck, setMoveBagCheck] = useState(true);

  const handleCheckBoxChange2 = () => {
    setMoveBagCheck(!moveBagCheck);
  };

  var num = 1;
  let loopingItemId = null;

  return (
    <>
      {bottomSwipeOpen && (
        <PopupWeb
          content={
            <>
              <form id="form" onSubmit={handleSaveBtnSubmit}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Save
                  </button>
                  <div className="addItemTask" onClick={togglePopupAddItem}>
                    +
                  </div>
                  <div className="popupTitle3">
                    <b>{taskGroupInfo[0].taskgroupname}</b>
                  </div>
                </div>
                <div className="emptySpace"></div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                  {error && <div className="alert2">{error}</div>}
                </div>

                <div className="popupContainer">
                  {loading2 === "True" && (
                    <div className="popupLoading1">
                      <div className="popupLoading2">
                        <ClipLoader className="override" size={35} />
                      </div>
                    </div>
                  )}
                  {taskList &&
                    taskList.map((data, i, arr) => {
                      console.log(data);

                      return (
                        <div key={i}>
                          <div className="taskName2"> {data.name}</div>
                          <div className="taskName2"> {data.itemchecklist}</div>

                          {data.tasklist.map((data2, j) => (
                            <div key={j}>
                              <div className="taskNameHolder">
                                <div className="taskName2">{data2.taskname}</div>
                                <div className="taskDesc2">{data2.taskdescription}</div>
                              </div>
                              <div
                                className="PassFailTaskContainer"
                                onChange={(e) =>
                                  addCompletedTask(e.target.value, data2.taskinprogressid)
                                }
                              >
                                <input
                                  value="Pass"
                                  type="radio"
                                  className="passBtn"
                                  id={"Pass" + data2.taskinprogressid}
                                  name={data2.taskinprogressid}
                                  defaultChecked={data2.taskstatus === "Pass"}
                                />
                                <label
                                  className="passBtn2"
                                  htmlFor={"Pass" + data2.taskinprogressid}
                                >
                                  Pass
                                </label>
                                <div className="space"></div>
                                <input
                                  value="Fail"
                                  type="radio"
                                  className="passBtn"
                                  id={"Fail" + data2.taskinprogressid}
                                  name={data2.taskinprogressid}
                                  defaultChecked={data2.taskstatus === "Fail"}
                                />
                                <label
                                  className="failBtn2"
                                  htmlFor={"Fail" + data2.taskinprogressid}
                                >
                                  Fail
                                </label>
                                <div className="space"></div>
                                <input
                                  value="Monitor"
                                  type="radio"
                                  className="passBtn"
                                  id={"Monitor" + data2.taskinprogressid}
                                  name={data2.taskinprogressid}
                                  defaultChecked={data2.taskstatus === "Monitor"}
                                />
                                <label
                                  className="MonitorBtn2"
                                  htmlFor={"Monitor" + data2.taskinprogressid}
                                >
                                  Monitor
                                </label>
                              </div>

                              <div className="inputDiv" id={`input${data2.taskinprogressid}`}>
                                <div className="textInputLeft">
                                  <textarea
                                    name={data2.taskinprogressid}
                                    defaultValue={data2.tasknote}
                                    className="addItemInput"
                                    type="text"
                                    placeholder="Note..."
                                  />
                                </div>

                                <div className="textInputRight">
                                  {data2.image !== "" && data2.image != null ? (
                                    <div className="imageChecklistContainer">
                                      <img
                                        src={PublicFolder.baseURL + data2.image}
                                        alt="Did Not Load"
                                        className="checklistImg"
                                      />
                                      <input
                                        multiple={false}
                                        type="file"
                                        name={"file"}
                                        onChange={(e) => handleFileInput(e, data2.taskinprogressid)}
                                        className="checklistImgInput"
                                      />
                                    </div>
                                  ) : (
                                    <div className="imageChecklistContainer">
                                      <input
                                        multiple={false}
                                        type="file"
                                        name={"file"}
                                        onChange={(e) => handleFileInput(e, data2.taskinprogressid)}
                                        className="checklistImgInput"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div>
                                <input
                                  name={data2.taskinprogressid}
                                  id={`UserChanged${data2.taskinprogressid}`}
                                  type="hidden"
                                />
                                <input
                                  name={data2.taskinprogressid}
                                  id={data.itemid}
                                  type="hidden"
                                  value={data.itemid}
                                />
                                <input
                                  name={data2.taskinprogressid}
                                  type="hidden"
                                  value={data2.taskinprogressid}
                                />
                                <input
                                  name={data2.taskinprogressid}
                                  id={data2.taskinprogressid + "!"}
                                  type="hidden"
                                  value={""}
                                />
                              </div>
                            </div>
                          ))}

                          <div className="NewInputLabel" data-domain="Add Image">
                            <input
                              ref={fileRef}
                              onChange={handleFileInputInspectionPhotos}
                              multiple
                              type="file"
                              accept="image/*"
                              hidden
                            />
                            <div onClick={() => fileRef.current.click()} className="NewInputImage">
                              <RiImageAddFill size={30} />
                            </div>
                            <div className="imagePreviewContainer">
                              {inspectionPhotos.map((image, index) => (
                                <div key={index} className="imageHolder">
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt="imageDidNotLoad"
                                    className="addImage"
                                  />
                                  <button
                                    type="button"
                                    className="removeImageButton"
                                    onClick={() => removeInspectionPhotos(index)}
                                  >
                                    <RiCloseFill size={20} />
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="inspectionImg">Notes</div>
                          <div className="textInputLeft2">
                            <textarea
                              onChange={(e) => setInspectionNotes(e.target.value)}
                              className="addItemInput"
                              type="text"
                              placeholder="Note..."
                            />
                          </div>
                        </div>
                      );
                    })}

                  <div className="BottomFixPosition">
                    <div className="PopupBottomHolder">
                      <button className="button-39" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={bottomSwipeFunc}
        />
      )}

      {isOpenItemScanner && (
        <PopupWeb
          content={
            <div className="popupScannerOver">
              <form onSubmit={handleSubmit}>
                <div id="holderTest" className="holderTest">
                  <div className="popupTitle">
                    <b>Edit {currentBagName}</b>
                  </div>
                </div>
                <div className="emptySpace"></div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                {bagItemsList.map((data, i) => (
                  <div className="bagListItemHolder" key={i}>
                    <div
                      className="bagListDelete"
                      onClick={() =>
                        toggleDeleteBagItem(
                          data.itemid,
                          data.identificationname
                            ? data.identificationname
                            : data.name
                        )
                      }
                    >
                      Remove
                    </div>
                    <div className="bagListItem">
                      {data.identificationname
                        ? data.identificationname +
                        (data.serialnumber ? " : " + data.serialnumber : "")
                        : data.name +
                        (data.serialnumber ? " : " + data.serialnumber : "")}
                    </div>
                  </div>
                ))}

                <div className="bagListAdd" onClick={togglePopupBagAddItem2}>
                  <b>Add Item</b>
                </div>
              </form>
            </div>
          }
          handleClose={toggleItemScanner}
        />
      )}

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Add To Compartment</b>
              </div>

              <div>
                <form onSubmit={handleAddedItem}>
                  <button
                    type="submit"
                    className="submitItemBtn222"
                    disabled={loading}
                  >
                    Add
                  </button>

                  <div className="popupContainer">
                    {error && <div className="alert2">{error}</div>}

                    <label className="NewInputLabel" data-domain="Item Holder">
                      <select
                        className="NewInput"
                        onChange={(e) => {
                          fetchData2(e.target.value);
                        }}
                        required
                        defaultValue=""
                        value={addedInvId}
                      >
                        <option value="" disabled hidden>
                          Please Choose...
                        </option>

                        {inventoryCategory.map((data, i) => (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label
                      className="NewInputLabel"
                      data-domain="Select Current Item"
                    >
                      <select
                        className="NewInput"
                        onChange={(e) => {
                          toggleSetAddedItemSearch(e.target.value);
                        }}
                        value={addedItemId}
                        required
                        defaultValue=""
                      >
                        <option value="" disabled hidden>
                          Select Inventory Item
                        </option>

                        {inventoryItems.map((data, i) => (
                          <option key={i} value={data.itemid}>
                            {data.identificationname
                              ? data.identificationname +
                              (data.serialnumber
                                ? " : " + data.serialnumber
                                : "")
                              : data.name +
                              (data.serialnumber
                                ? " : " + data.serialnumber
                                : "")}
                          </option>
                        ))}
                      </select>
                    </label>
                    <div>
                      {itemArea.length > 0 ? (
                        itemArea.map((data2, i) => {
                          //  console.log(data2);
                          if (data2.name === null) {
                            return (
                              <div key={i}>Current Location - Unassigned</div>
                            );
                          } else if (data2.bagname !== null) {
                            return (
                              <div key={i}>
                                Current Location -
                                {data2.apparatusname
                                  ? ` ${data2.apparatusname}`
                                  : ""}
                                {data2.compartmentname
                                  ? ` - ${data2.compartmentname}`
                                  : ""}
                                {data2.bagname ? ` - ${data2.bagname}` : ""}
                                <div className="taskBagCurrentLocation">
                                  <label className="checkboxLabel">
                                    <input
                                      type="checkbox"
                                      className="NewInputCheckBox"
                                      onChange={(e) =>
                                        handleCheckBoxChange2(e.target.value)
                                      }
                                      name="checkbox-checked"
                                      checked={
                                        moveBagCheck === true ||
                                          moveBagCheck === "true"
                                          ? true
                                          : false
                                      }
                                    />
                                    <div className="checkboxLabel">
                                      Move {data2.bagname} and all items to
                                      compartment
                                    </div>
                                  </label>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div key={i}>
                                Current Location -
                                {data2.apparatusname
                                  ? ` ${data2.apparatusname}`
                                  : ""}
                                {data2.compartmentname
                                  ? ` - ${data2.compartmentname}`
                                  : ""}
                                {data2.bagname ? ` - ${data2.bagname}` : ""}
                              </div>
                            );
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="selectItemLocation">
                    {itemLocation &&
                      itemLocation.map((data, i) => {
                        //loops 3 times idk why

                        return (
                          <div key={i}>
                            <div className="itemListHeaderContainer">
                              <div className="itemDetailsContainer">
                                <div className="itemManufacturer">
                                  Item currently Located in
                                  <div className="itemManufacturer2">
                                    <b>
                                      {data.apparatus} / {data.compartmentname}
                                    </b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </form>

                <div className="qrscannerContainerTask">
                  <QrReader
                    scanDelay={20}
                    onResult={(result, error) => {
                      if (!!result) {
                        handleScan2(result?.text);
                      }

                      if (!!error) {
                        handleError(error);
                      }
                    }}
                    style={{ width: "100%" }}
                    constraints={{
                      facingMode: selfie ? "user" : "environment",
                    }}
                    showViewFinder={false}
                  />

                  {/*data*/}
                </div>
              </div>
            </>
          }
          handleClose={togglePopupAddItem}
        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Add Item To {currentBagName}</b>
              </div>

              <div>
                <form onSubmit={handleBagAddedItem}>
                  <button
                    type="submit"
                    className="submitItemBtn222"
                    disabled={loading}
                  >
                    Add
                  </button>
                  <div className="selectItemDiv">
                    <select
                      className="form-controlSelect2"
                      onChange={(e) => {
                        fetchData2(e.target.value);
                      }}
                      style={{ height: "38px" }}
                    >
                      <option value="" disabled selected hidden>
                        Please Choose...
                      </option>

                      {inventoryCategory.map((data, i) => (
                        <option key={i} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </select>

                    <select
                      className="form-controlSelect2"
                      onChange={(e) => {
                        setAddedItemId(e.target.value);
                      }}
                      style={{ height: "38px" }}
                      required
                    >
                      <option value="" disabled selected hidden>
                        Pick Inventory Type First
                      </option>

                      {inventoryItems.map((data, i) => (
                        <option key={i} value={data.itemid}>
                          {data.identificationname
                            ? data.identificationname +
                            (data.serialnumber
                              ? " : " + data.serialnumber
                              : "")
                            : data.name +
                            (data.serialnumber
                              ? " : " + data.serialnumber
                              : "")}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-controlSelect2"
                      onChange={(e) => {
                        setAddedEventId(e.target.value);
                      }}
                      style={{ height: "38px" }}
                      required
                    >
                      <option value="" disabled selected hidden>
                        Pick An Item First
                      </option>

                      {inventoryItemsEvents.map((data, i) => (
                        <option key={i} value={data.itemeventid}>
                          {data.eventname}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
            </>
          }
          handleClose={togglePopupBagAddItem2}
        />
      )}

      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Remove - {deleteItemName}</b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>
                  This will unassigned the current item and remove it from the
                  checklist
                </b>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleBagRemovedItem}
                >
                  <b>Remove</b>
                </div>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={toggleDeleteBagItem}
                >
                  <b>Cancel</b>
                </div>
              </div>
            </>
          }
          handleClose={toggleDeleteBagItem}
        />
      )}

      {isOpenRemoveItem && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest">
                <div className="popupTitle3">
                  <b>Remove - {deleteItemName}</b>
                </div>
              </div>
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              {deleteBagCount === "1" ? (
                <>
                  <div className="deleteInfoApparatusContainer">
                    <b>Atleast one items must remain in {deletedBagName}</b>
                  </div>
                  <div className="editApparatusBtnContainer"></div>
                </>
              ) : (
                <>
                  <div className="deleteInfoApparatusContainer">
                    <b>
                      This will unassigned the current item and remove it from
                      the checklist
                    </b>
                  </div>
                  <div className="editApparatusBtnContainer">
                    <div
                      className="confirmDeleteApparatusBtn"
                      onClick={handleBagRemovedItem2}
                    >
                      <b>Remove</b>
                    </div>
                  </div>
                </>
              )}

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={toggleRemvoveItem}
                >
                  <b>Cancel</b>
                </div>
              </div>
            </>
          }
          handleClose={toggleRemvoveItem}
        />
      )}

      {bagUnssignOpen && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest">
                <div className="popupTitle3">
                  <b>Unassign - {bagAddName}</b>
                </div>
              </div>
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <>
                <div className="deleteInfoApparatusContainer">
                  <b>This will unassigned the all item and {bagAddName}</b>
                </div>
                <div className="editApparatusBtnContainer">
                  <div
                    className="confirmDeleteApparatusBtn"
                    onClick={handlebagUnassign}
                  >
                    <b>Unassign</b>
                  </div>
                </div>
              </>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={toggleBagUnassignClose}
                >
                  <b>Cancel</b>
                </div>
              </div>
            </>
          }
          handleClose={toggleBagUnassignClose}
        />
      )}

      {itemScannerCompartment && (
        <PopupWeb
          content={
            <div className="popupScanner">
              <div onClick={() => setSelfie(!selfie)} className="cameraFlip">
                <MdFlipCameraAndroid />
              </div>
              <div className="popupTitle">
                <b>Scan a QR code </b>
              </div>
              <div className="scannerInputContainer"></div>
              {
                <div className="scannerInputContainer">
                  <form onSubmit={handleScan}>
                    <div className="scannerInputContainer2">
                      <button type="submit" className="submitScannerBtn">
                        Search
                      </button>
                      <input
                        onChange={(e) => handleScan(e.target.value)}
                        className="addItemInput2"
                        type="text"
                        placeholder="Search By Serial Number"
                        required
                      />
                    </div>
                  </form>
                </div>
              }
              {error && (
                <div className="errorDiv">
                  <div className="alert2">{error}</div>
                </div>
              )}

              <div className="coverUp"> &nbsp;</div>
              <div className="qrscannerContainer">
                <QrReader
                  delay={250}
                  onError={handleError}
                  onScan={handleScan}
                  resolution={1000}
                  facingMode={selfie ? "user" : "environment"}
                  showViewFinder={false}
                />

                {/*data*/}
              </div>
            </div>
          }
          handleClose={toggleCompartmentItemScanner}
        />
      )}

      {bagInfoOpen && (
        <PopupWeb
          content={
            <div className="popupScanner">
              {bagInfo.length > 0 ? (
                bagInfo.map((data, i) => {
                  // // console.log(data);
                  if (i === 0) {
                    return (
                      <div key={i}>
                        <div id="holderTest" className="holderTest">
                          <button
                            type="submit"
                            className="submitItemBtn2"
                            disabled={loading}
                            onClick={() =>
                              toggleBagUnassignOpen(data.bagid, data.bagname)
                            }
                          >
                            Unassign
                          </button>
                          <div className="popupTitle3">
                            <b>{data.bagname}</b>
                          </div>
                        </div>
                        <div className="holderTestEmpty"></div>

                        <div
                          className="apparatusBagLocation"
                          id={"apparatusLocation"}
                        ></div>
                        <div className="appratusBagPopup">
                          {data.imagepath ? (
                            <img
                              src={PublicFolder.baseURL + data.imagepath}
                              alt="fix later"
                              className="apparatusBagImg"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="emptyHolderPageDiv">
                          <button
                            className="restartPageBtn"
                            onClick={() =>
                              toggleBagAddOpen(data.bagid, data.bagname)
                            }
                          >
                            <b>Add Item</b>
                          </button>
                        </div>
                        <div className="apparatusBagPopupitemTextHolder2">
                          <div className="itemText1">Item</div>
                          <div className="amountText1">Amount</div>
                        </div>
                        <div className="itemContainer2">
                          <div className="inventoryImgDiv">
                            {data.image ? (
                              <img
                                src={PublicFolder.baseURL + data.image}
                                alt={data.name.charAt(0).toUpperCase()}
                                className="inventoryImg"
                              />
                            ) : (
                              <div className="noPicDiv">
                                {data.name.charAt(0).toUpperCase()}
                              </div>
                            )}
                          </div>
                          <div className="itemDiv">
                            {data.identificationname ? (
                              <div className="itemName">
                                {data.identificationname}
                              </div>
                            ) : (
                              <div className="itemName">{data.name}</div>
                            )}
                          </div>
                          <div className="itemAmount">{data.amount}</div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={i}>
                        <div className="itemContainer2">
                          <div className="inventoryImgDiv">
                            {data.image ? (
                              <img
                                src={PublicFolder.baseURL + data.image}
                                alt={data.name.charAt(0).toUpperCase()}
                                className="inventoryImg"
                              />
                            ) : (
                              <div className="noPicDiv">
                                {data.name.charAt(0).toUpperCase()}
                              </div>
                            )}
                          </div>
                          <div className="itemDiv">
                            {data.identificationname ? (
                              <div className="itemName">
                                {data.identificationname}
                              </div>
                            ) : (
                              <div className="itemName">{data.name}</div>
                            )}
                          </div>
                          <div className="itemAmount">{data.amount}</div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
          }
          handleClose={toggleBagInfoClose}
        />
      )}

      {bagInfoAdd && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Add To {bagAddName}</b>
              </div>

              <div>
                <form onSubmit={handleAddedItem}>
                  <button
                    type="submit"
                    className="submitItemBtn222"
                    disabled={loading}
                  >
                    Add
                  </button>

                  <div className="popupContainer">
                    <label className="NewInputLabel" data-domain="Item Holder">
                      <select
                        className="NewInput"
                        onChange={(e) => {
                          fetchData2(e.target.value);
                        }}
                        value={addedInvId}
                        defaultValue=""
                        required
                      >
                        <option value="" disabled selected hidden>
                          Please Choose...
                        </option>

                        {inventoryCategory.map((data, i) => (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label
                      className="NewInputLabel"
                      data-domain="Select Current Item"
                    >
                      <select
                        className="NewInput"
                        onChange={(e) => {
                          toggleSetAddedItemSearch(e.target.value);
                        }}
                        value={addedItemId}
                        defaultValue=""
                        required
                      >
                        <option value="" disabled selected hidden>
                          Select Inventory Item
                        </option>

                        {inventoryItems.map((data, i) => (
                          <option key={i} value={data.itemid}>
                            {data.identificationname
                              ? data.identificationname +
                              (data.serialnumber
                                ? " : " + data.serialnumber
                                : "")
                              : data.name +
                              (data.serialnumber
                                ? " : " + data.serialnumber
                                : "")}
                          </option>
                        ))}
                      </select>
                    </label>
                    <div>
                      {itemArea.length > 0 ? (
                        itemArea.map((data2, i) => {
                          // console.log(data2);
                          if (data2.name === null) {
                            return (
                              <div key={i}>Current Location - Unassigned</div>
                            );
                          } else {
                            return (
                              <div key={i}>
                                Current Location -
                                {data2.apparatusname
                                  ? ` ${data2.apparatusname}`
                                  : ""}
                                {data2.compartmentname
                                  ? ` - ${data2.compartmentname}`
                                  : ""}
                                {data2.bagname ? ` - ${data2.bagname}` : ""}
                              </div>
                            );
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="selectItemLocation">
                    {itemLocation &&
                      itemLocation.map((data, i) => {
                        //loops 3 times idk why

                        return (
                          <div key={i}>
                            <div className="itemListHeaderContainer">
                              <div className="itemDetailsContainer">
                                <div className="itemManufacturer">
                                  Item currently Located in
                                  <div className="itemManufacturer2">
                                    <b>
                                      {data.apparatus} / {data.compartmentname}
                                    </b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="qrscannerContainerTask">
                    <QrReader
                      scanDelay={20}
                      onResult={(result, error) => {
                        if (!!result) {
                          handleScan2(result?.text);
                        }

                        if (!!error) {
                          handleError(error);
                        }
                      }}
                      style={{ width: "100%" }}
                      constraints={{
                        facingMode: selfie ? "user" : "environment",
                      }}
                      showViewFinder={false}
                    />

                    {/*data*/}
                  </div>
                </form>
              </div>
            </>
          }
          handleClose={toggleBagAddClose}
        />
      )}

      {itemInfoOpen && (
        <PopupWeb
          content={
            <div className="popupScanner">
              {itemInfo.length > 0 ? (
                itemInfo.map((data, i) => {
                  //  console.log(data);
                  if (i === 0) {
                    return (
                      <div key={i}>
                        <div id="holderTest" className="holderTest">
                          <button
                            type="submit"
                            className="submitItemBtn2"
                            disabled={loading}
                            onClick={() =>
                              toggleRemvoveItem(
                                data.itemid,
                                data.name,
                                data.bagitemscount,
                                data.bagname
                              )
                            }
                          >
                            Remove
                          </button>
                          <div className="popupTitle3">
                            <b>{data.name}</b>
                          </div>
                        </div>
                        <div className="holderTestEmpty"></div>
                        <div
                          className="apparatusBagLocation"
                          id={"apparatusLocation"}
                        ></div>
                        <div className="appratusBagPopup">
                          {data.image ? (
                            <img
                              src={PublicFolder.baseURL + data.image}
                              alt="fix later"
                              className="apparatusBagImg"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="apparatusBagPopupitemTextHolder2">
                          <div className="itemText1">Item</div>
                          <div className="amountText1">Amount</div>
                        </div>
                        <div className="itemContainer2">
                          <div className="itemDiv">
                            {data.identificationname ? (
                              <div className="itemName">
                                {data.identificationname}
                              </div>
                            ) : (
                              <div className="itemName">{data.name}</div>
                            )}
                          </div>
                          <div className="itemAmount">{data.amount}</div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={i}>
                        <div className="itemContainer2">
                          <div className="inventoryImgDiv">
                            {data.image ? (
                              <img
                                src={PublicFolder.baseURL + data.image}
                                alt={data.name.charAt(0).toUpperCase()}
                                className="inventoryImg"
                              />
                            ) : (
                              <div className="noPicDiv">
                                {data.name.charAt(0).toUpperCase()}
                              </div>
                            )}
                          </div>
                          <div className="itemDiv">
                            {data.identificationname ? (
                              <div className="itemName">
                                {data.identificationname}
                              </div>
                            ) : (
                              <div className="itemName">{data.name}</div>
                            )}
                          </div>
                          <div className="itemAmount">{data.amount}</div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
          }
          handleClose={toggleItemClose}
        />
      )}
    </>
  );
};

export default ApparatusChecklistTask;
