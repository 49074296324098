import React, { useState, useEffect } from "react";
import ReportsFinder from "../../apis/ReportsFinder";
import { useAuth } from "../../context/AuthContext";

import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";


import imageToBase64 from "image-to-base64/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PublicFolder from "../../apis/PublicFolder";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReportsInventoryInformation = () => {
  const { currentUserSiteId } = useAuth();

  const [results, setResults] = useState([]);
  const [categoryList, setCategoryList] = useState([]);




  const test = () => {
    document.getElementById("myDropdown").classList.toggle("show");

    let arrowCollapseRight = document.getElementById("bx-arrow-from-right2");
    let arrowCollapseLeft = document.getElementById("bx-arrow-from-left2");
    arrowCollapseRight.classList.toggle("collapse");

    if (arrowCollapseRight.classList.contains("collapse")) {
      arrowCollapseRight.classList.remove("disabled");
      arrowCollapseLeft.classList.add("disabled");
    } else {
      arrowCollapseRight.classList.add("disabled");
      arrowCollapseLeft.classList.remove("disabled");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ReportsFinder.get(`/getInventoryInformationOnLoad`, {
          params: {
            currentUserSiteId,
          },
        });

        setCategoryList(response.data.data.categoryList);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]);

  const getReset = () => {
    setResults([]);


    var options12 = document.getElementById("select-category");

    for (var i = 0; i < options12.length; i++) {
      options12[i].selected = false;
    }

  };

  const getResults = async (e) => {
    e.preventDefault();

    fetchResults();
    convertToBase64();
  };

  const getPDF = () => {
    generatePdf();
  };

  const fetchResults = async () => {
    try {

      var options12 =
        document.getElementById("select-category").selectedOptions;
      var category = Array.from(options12).map(({ value }) => value);

      const response = await ReportsFinder.get("/getInventoryInformation", {
        params: {
          currentUserSiteId,
          category: category.length === 0 ? "" : category,

        },
      });





      setResults(response.data.data.results);

    } catch (err) {
      //console.log(err);
    }
  };

  const [base64IMG, setBase64IMG] = useState("");

  const convertToBase64 = () => {
    imageToBase64(PublicFolder.baseURL + "LogonIcon.png") // Path to the image
      .then((response) => {
        setBase64IMG(response);
      });
  };

  /* prettier-ignore */
  //let heading = [["Date","Individual Event Name", "Event Name", "Description", "Members"]];

  let heading = [
    [
      { text: "ID", bold: true },
      { text: "Equipment", bold: true },
      { text: "Serial Number", bold: true },
      { text: "Location", bold: true },
      { text: "Manufacture Date", bold: true },
      { text: "Mandatory Inspection Date", bold: true },
      { text: "Expiration Date", bold: true },

    ]
  ];

  const options2 = results.map((elt, i) => [
    elt.itemid,
    elt.identificationname ? elt.identificationname : elt.itemname,
    elt.serialnumber,
    `${elt.apparatus} - ${elt.compartmentname} ${elt.bagname ? `- ${elt.bagname}` : ""
    }`,
    elt.manufacturedate,
    elt.mandatoryinspectiondate,
    elt.expirationdate,
  ]);

  let bodyData = heading.concat(options2);
  const generatePdf = async () => {
    var docDefinition = {
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [20, 80, 20, 20],
      // pageOrientation: "landscape",
      info: {
        title: "Inventory Information Report",
      },
      footer: function (currentPage, pageCount) {
        var columns = [
          {
            text: currentPage.toString() + " of " + pageCount,
            width: "auto",
            alignment: "center",
          },
        ];
        return columns;
      },

      header: {
        columns: [
          {
            text: "Inventory Information Report",
            bold: true,
            fontSize: 26,

            margin: [20, 20, 0, 0],
          },
          {
            image: `data:image/png;base64,${base64IMG}`,
            width: 50,
            height: 50,
            alignment: "right",
            margin: [0, 10, 15, 0],
          },
        ],
      },

      content: [



        { width: "*", text: "", margin: [0, 0, 0, 10] },
        {
          text: `Number of records: ${results.length}`,
          fontSize: 10,
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableSyle",

          // layout: "lightHorizontalLines", // optional
          table: {
            // headers are automatically repeated if the tasble spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [20, 80, 80, 120, 60, 60, 60],

            body: bodyData,
          },
        },
      ],

      styles: {
        tableSyle: {
          fontSize: 10,
        },
      },
    };

    //pdfMake.createPdf(docDefinition).download('InventoryUsage.pdf');
    pdfMake.createPdf(docDefinition).open();
  };

  return (
    <>


      <form onSubmit={getResults} className="formClass">
        <div className="ReportResultsCommandsHolder">
          {results.length > 0 ? (
            <div className="ReportResultsCommandsPDF">
              <div className="ReportResultsCommandsBtnPDF" onClick={getPDF}>
                <b>PDF</b>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="ReportResultsCommands">
            <button className="ReportResultsCommandsGenerate" type="submit">
              <b>Generate</b>
            </button>
            <div className="ReportResultsCommandsReset" onClick={getReset}>
              <b>Reset</b>
            </div>
          </div>
        </div>

      </form>
      <div className="filterdropdown">
        <button className="filterdropbtn" onClick={() => test()}>
          <div className="filterHolder">
            <div className="filterDiv">
              <b>Filter and sort options</b>
            </div>
          </div>
          <div className="filterArrow disabled" id="bx-arrow-from-right2">
            <i className="bx" id="logo-name__icon">
              <AiOutlineArrowUp />
            </i>
          </div>
          <div className="filterArrow" id="bx-arrow-from-left2">
            <i className="bx" id="logo-name__icon">
              <AiOutlineArrowDown />
            </i>
          </div>
        </button>

        <div id="myDropdown" className="dropdown-contentReports">
          <label className="NewInputLabel" data-domain="Category">
            <select className="NewInput" id="select-category">
              {categoryList.map(function (e, i) {
                return (
                  <option key={i} value={e.id}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </label>

        </div>
      </div>
      {results.length > 0 ? (
        <div className="ReportRecords">Number of records: {results.length}</div>
      ) : (
        ""
      )}
      <div className="ReportResultsContainer">
        <div className="reportsPopupContainer">
          {results.length > 0 ? (
            <>
              <table id="reportsTable">
                <tbody>
                  <tr>
                    <th>Id</th>
                    <th>Equipment</th>
                    <th>Serial Number</th>
                    <th>Location</th>
                    <th>Manufacture Date</th>
                    <th>Mandatory Inspection Date</th>
                    <th>Expiration Date</th>

                  </tr>
                  {results.map((data, i) => {
                    // console.log(data);
                    return (
                      <tr key={i}>
                        <td>{data.itemid}</td>
                        <td>
                          {data.identificationname
                            ? data.identificationname
                            : data.itemname}
                        </td>
                        <td>{data.serialnumber}</td>

                        <td>
                          {data.apparatus} - {data.compartmentname}
                          {data.bagname ? `- ${data.bagname}` : ""}
                        </td>

                        <td>{data.manufacturedate}</td>

                        <td>{data.mandatoryinspectiondate}</td>
                        <td>{data.expirationdate}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="emptySpaceReport"> </div>
    </>
  );
};

export default ReportsInventoryInformation;
