import React, { useState, createContext } from "react";

export const TrainingContext = createContext();

export const TrainingContextProvider = (props) => {
  const [trainingId, setTrainingId] = useState([]);

  const [trainingMembers, setTrainingMembers] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberPopupOpen, setMemberPopupOpen] = useState(false);
  const [memberAttendingCount, setMemberAttendingCount] = useState("0");
  const [apparatusList, setApparatusList] = useState("");

  const [trainingItems, setTrainingItems] = useState([]);

  const [itemPopupOpen, setItemPopupOpen] = useState(false);
  const [trainingItemsCount, setTrainingItemsCount] = useState("0");

  const [addPhoto, setAddPhoto] = useState([]);

  const [photoPopupOpen, setPhotoPopupOpen] = useState(false);
  const [photoCount, setPhotoCount] = useState("0");

  const [noteList, setNoteList] = useState([]);

  return (
    <TrainingContext.Provider
      value={{
        memberList,
        setMemberList,
        trainingMembers,
        setTrainingMembers,

        trainingId,
        setTrainingId,
        setAddPhoto,
        addPhoto,

        trainingItems,
        setTrainingItems,
        memberPopupOpen,
        setMemberPopupOpen,
        memberAttendingCount,
        setMemberAttendingCount,
        itemPopupOpen,
        setItemPopupOpen,
        trainingItemsCount,
        setTrainingItemsCount,
        photoPopupOpen,
        setPhotoPopupOpen,
        photoCount,
        setPhotoCount,
        apparatusList,
        setApparatusList,
        noteList,
        setNoteList,
      }}
    >
      {props.children}
    </TrainingContext.Provider>
  );
};
