import React, { useState, useEffect } from "react";
import SiteFinder from "../../apis/SiteFinder";
import UserFinder from "../../apis/UserFinder";

import Header from "../Header/Header";

import { useAuth } from "../../context/AuthContext";
import { AiOutlineMenu } from "react-icons/ai";

import PopupWeb from "../Popup/PopupWeb";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";

const SiteHeader = () => {
  let history = useNavigate();

  const { currentUserId, currentUserSiteId, updateUserSiteData } = useAuth();

  const [siteList, setSiteList] = useState("");
  //const [selectedSiteId, setSelectSiteId] = useState("");

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [newSiteName, setNewSiteName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SiteFinder.get(`/getSiteList`, {
          params: {
            currentUserId,
          },
        });
        console.log(response.data.data.siteList);

        setSiteList(response.data.data.siteList);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCurrentSite = async (id, name) => {
    //stop the page from reloading
    //   e.preventDefault();
    try {
      setLoading(true);
      console.log(id);
      // eslint-disable-next-line
      const response = await SiteFinder.post("/changeSite", {
        site_id: id,
        currentUserId,
      });

      updateUserSiteData(currentUserId);
     // window.location.reload();
      history(`/`);
     // alert(`You changed your site to ${name}`);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
  };

  const handleNewSite = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const response = await UserFinder.post("/createNewSite", {
        currentUserId: currentUserId,
        newSiteName,
      });
      setLoading(false);
      if (response.data.data.alert) {
        setError(response.data.data.alert);
        return;
      }
      //  refetch();
      updateUserSiteData(currentUserId);
      setSiteList(response.data.data.siteList);

      togglePopup3();
    } catch (err) {
      setLoading(false);
      setError("Failed to update account");
    }
    setLoading(false);
  };

  return (
    <>



      <div className="siteListHolder">
        {siteList &&
          siteList.map((data, i) => {
            // console.log(data);
            return (
              <div key={i}>
                <div className="trainingContainer">
                  <div
                    className="siteListContainer"
                    onClick={() => handleCurrentSite(data.site_id, data.name)}
                  >
                    <div className="trainingName">
                      <b>{data.name}</b>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {isOpen3 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={handleNewSite} className="userForm">
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Save
                  </button>
                  <div className="popupTitle3">
                    <b> Create New Site</b>
                  </div>
                </div>
                <div className="emptySpace"></div>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                {error && <div className="alert">{error}</div>}

                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Site Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setNewSiteName(e.target.value)}
                      value={newSiteName}
                      required
                    />
                  </label>
                </div>
              </form>
            </div>
          }
          handleClose={togglePopup3}
        />
      )}
    </>
  );
};

export default SiteHeader;
