import React, { useState, createContext } from "react";

export const TrainingInfoContext = createContext();

export const TrainingInfoContextProvider = (props) => {
  const [trainingMembers, setTrainingMembers] = useState([]);
  const [trainingPhotos, setTrainingPhotos] = useState([]);
  const [trainingItems, setTrainingItems] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [trainingId, setTrainingId] = useState([]);
  const [trainingNotes, setTrainingNotes] = useState([]);
  const [apparatusList, setApparatusList] = useState("");

  const [photoPopupOpen, setPhotoPopupOpen] = useState(false);
  const [photoCount, setPhotoCount] = useState("0");

  const [itemPopupOpen, setItemPopupOpen] = useState(false);
  const [trainingItemsCount, setTrainingItemsCount] = useState("0");

  const [memberPopupOpen, setMemberPopupOpen] = useState(false);
  const [memberAttendingCount, setMemberAttendingCount] = useState("0");

  const [noteList, setNoteList] = useState([]);

  return (
    <TrainingInfoContext.Provider
      value={{
        trainingMembers,
        setTrainingMembers,
        trainingPhotos,
        setTrainingPhotos,
        trainingItems,
        setTrainingItems,
        setMemberList,
        memberList,
        setTrainingId,
        trainingId,
        trainingNotes,
        setTrainingNotes,
        memberAttendingCount,
        setMemberAttendingCount,
        trainingItemsCount,
        setTrainingItemsCount,
        photoCount,
        setPhotoCount,
        photoPopupOpen,
        setPhotoPopupOpen,
        itemPopupOpen,
        setItemPopupOpen,
        memberPopupOpen,
        setMemberPopupOpen,
        apparatusList,
        setApparatusList,
        noteList,
        setNoteList,
      }}
    >
      {props.children}
    </TrainingInfoContext.Provider>
  );
};
