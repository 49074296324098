import React, { useContext, useState, useRef } from "react";
import { TrainingInfoContext } from "../../context/TrainingInfoContext";
import TrainingFinder from "../../apis/TrainingFinder";

import PublicFolder from "../../apis/PublicFolder";
import PopupWeb from "../Popup/PopupNew";

//import Lightbox from "react-image-lightbox";
//react-photoswipe-gallery
//import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

import { useAuth } from "../../context/AuthContext";

import ClipLoader from "react-spinners/ClipLoader";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";
import { notify } from "../../ToastNotifier";

const TrainingInfoPictures = () => {
  const { currentUserRole, currentUserSiteId } = useAuth();

  const { trainingPhotos } = useContext(TrainingInfoContext);

  const { setTrainingPhotos, setPhotoCount } = useContext(TrainingInfoContext);
  const { trainingId } = useContext(TrainingInfoContext);


  const [loading, setLoading] = useState(false);


  const [isOpen, setIsOpen] = useState(false);
  const addMemberPopupClose = () => {
    setIsOpen(!isOpen);
    setPhotoListPopup(!photoListPopup);
  };

  // eslint-disable-next-line

  const [photoListPopup, setPhotoListPopup] = useState(true);

  //Popup delete Member
  const { photoPopupOpen, setPhotoPopupOpen } = useContext(TrainingInfoContext);
  const MainPopup = () => {
    setPhotoPopupOpen(!photoPopupOpen);
  };

  const headers = {
    "content-type": "multipart/form-data",
  };
  //custom image
  const fileRef = useRef();
  const [inspectionPhotos, setInspectionPhotos] = useState([]);

  const handleFileInputInspectionPhotos = (e) => {
    const files = Array.from(e.target.files);
    setInspectionPhotos((prevImages) => [...prevImages, ...files]);
  };

  const removeInspectionPhotos = (index) => {
    setInspectionPhotos((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };



  const handlePhotoSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();

      formData.set("currentUserSiteId", currentUserSiteId);

      formData.set("trainingId", trainingId);
      inspectionPhotos.forEach((image, index) => {
        formData.append(`file`, image);
      });


      const response = await TrainingFinder.post(
        "/addCompeltedPicture",
        formData,
        headers
      );
      // // console.log(response.data.data.trainingPhotos);
      // console.log(response.data.data.trainingPhotos.length);
      setTrainingPhotos(response.data.data.trainingPhotos);
      setPhotoCount(response.data.data.trainingPhotos.length);
      addPicturePopupClose();
      //  history.push("/");
      // history.push(location.pathname);
      setLoading(false);
      notify("Added photo successfully!", "success"); // Show success notification

    } catch (err) {
      setLoading(false);
      notify("Failed to add photo. Please try again.", "error"); // Show error notification

    }
  };
  //Popup Add Training
  const [isOpen4, setIsOpen4] = useState(false);
  const addPicturePopupClose = () => {
    setIsOpen4(!isOpen4);
    setLoading(false);
    setPhotoListPopup(!photoListPopup);
  };

  const addPicturePopup = () => {
    setIsOpen4(!isOpen4);
    setPhotoListPopup(!photoListPopup);



  };

  //Delete Member
  const [deletePhotoHolder, setDeletePhotoHolder] = useState("");

  //Popup delete Member
  const [isOpen1, setIsOpen1] = useState(false);
  const deletePhotoPopup = () => {
    setIsOpen1(!isOpen1);
    setPhotoListPopup(!photoListPopup);
  };

  const removePhoto = (username, trainingipm_id) => {
    setDeletePhotoHolder(username);
    setIsOpen1(!isOpen1);
    setPhotoListPopup(!photoListPopup);
  };

  const handleDeletePhotoSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      const response = await TrainingFinder.post("/removeCompletedPicture", {
        deletePhotoHolder,
        trainingId,
        currentUserSiteId,
      });
      setTrainingPhotos(response.data.data.trainingPhotos);
      setPhotoCount(response.data.data.trainingPhotos.length);
      deletePhotoPopup();
      notify("Removed photo successfully!", "success"); // Show success notification
      setLoading(false);
    } catch (err) {
      notify("Failed to remove photo. Please try again.", "error"); // Show error notification
      setLoading(false);
    }
  };

  //// console.log(trainingPhotos);
  return (
    <>
      {photoListPopup && (
        <PopupWeb
          content={
            <>
       
              <div className="trainingMembersList">
                {trainingPhotos.length ? (
                  <button type="button" onClick={addMemberPopupClose}>
                    Open Photo Gallery
                  </button>
                ) : (
                  ""
                )}
              </div>

              {currentUserRole === "Admin" || currentUserRole === "Advanced" ? (
                //admin

                <div className="trainingInProgressPhotos">
                  {trainingPhotos.length > 0 ? (
                    trainingPhotos.map((data, i) => {
                      return (
                        <div key={i} className="trainingInProgressPhotosHolder">
                          <img
                            src={PublicFolder.baseURL + data.image}
                            alt="fix later"
                            className="trainingInProgressImg"
                            onClick={() => removePhoto(data.image)}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                //Not admin
                <div className="trainingInProgressPhotos">
                  {trainingPhotos.length > 0 ? (
                    trainingPhotos.map((data, i) => {
                      return (
                        <div key={i}>
                          <img
                            src={PublicFolder.baseURL + data.image}
                            alt="fix later"
                            className="trainingInProgressImg"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </>
          }
          handleClose={MainPopup}
          isOpen={photoListPopup}
          showSubmitButton={true}
          handleSubmit={addPicturePopup}
          headerText={                <b>Photos</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 
        />
      )}
      <div>
        {isOpen && (
          <></>
          /* <Lightbox
            mainSrc={images[photoIndex].value}
            nextSrc={images[(photoIndex + 1) % images.length].value}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].value
            }
            onCloseRequest={addMemberPopupClose}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />  */
        )}
      </div>

      {isOpen4 && (
        <PopupWeb
          content={
            <>
            

              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <div className="popupContainer">
              <div className="NewInputLabel" data-domain="Add Image">
                  <input
                    ref={fileRef}
                    onChange={handleFileInputInspectionPhotos}
                    multiple
                    type="file"
                    accept="image/*"
                    hidden
                  />
                  <div
                    onClick={() => fileRef.current.click()}
                    className="NewInputImage"
                  >
                    <RiImageAddFill size={30} />
                  </div>
                  <div className="imagePreviewContainer">
                    {inspectionPhotos.map((image, index) => (
                      <div key={index} className="imageHolder">
                        <img
                          src={URL.createObjectURL(image)}
                          alt="imageDidNotLoad"
                          className="addImage"
                        />
                        <button
                          type="button"
                          className="removeImageButton"
                          onClick={() => removeInspectionPhotos(index)}
                        >
                          <RiCloseFill size={20} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={addPicturePopupClose}
          isOpen={isOpen4}
          handleSubmit={handlePhotoSubmit}
          showSubmitButton={true}
          headerText={ <b>Add a Photo</b>}
          submitButtonText={loading ? "Loading..." : "Add"}         />
      )}

      {isOpen1 && (
        <PopupWeb
          content={
            <>
             <div className="removePhotoContainer">
                <img
                  src={PublicFolder.baseURL + deletePhotoHolder}
                  alt="fix later"
                  className="removeInProgressImg"
                />
              </div>
            </>
          }
 
          handleClose={deletePhotoPopup}
          isOpen={isOpen1}
          handleSubmit={handleDeletePhotoSubmit}
          showSubmitButton={true}
          headerText={  <b>Remove Photo</b>}
          submitButtonText={loading ? "Loading..." : "Delete"} 
        />
      )}
    </>
  );
};

export default TrainingInfoPictures;
