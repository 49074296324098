import React from "react";

import ApparatusChecklistInProgressHeader from "../components/ApparatusChecklist/ApparatusChecklistInProgressHeader";
import "../components/ApparatusChecklist/ApparatusChecklist.css";

import { ApparatusChecklistContextProvider } from "../context/ApparatusChecklist";

function ApparatusChecklist() {
  return (
    <ApparatusChecklistContextProvider>
      <>
        <ApparatusChecklistInProgressHeader />
      </>
    </ApparatusChecklistContextProvider>
  );
}

export default ApparatusChecklist;
