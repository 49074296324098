import React from "react";
import "../components/Reports/Reports.css";
import ReportsHeader from "../components/Reports/ReportsHeader";
import { ReportsContextProvider } from "../context/ReportsContext";

const Reports = () => {
  return (
    <ReportsContextProvider>
      <>
        <ReportsHeader />
      </>
    </ReportsContextProvider>
  );
};

export default Reports;
