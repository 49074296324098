import { useQuery } from "@tanstack/react-query";
import TrainingFinder from "../../apis/TrainingFinder";

const fetchTrainingPage = ({ queryKey }) => {
  const currentUserSiteId = queryKey[1];
  return TrainingFinder.get(`/main`, {
    params: {
      currentUserSiteId,
    },
  });
};

export const useTrainingData = (currentUserSiteId) => {
  return useQuery(["mainEvents", currentUserSiteId], fetchTrainingPage);
};
