import React, { useState, useRef } from "react";

import ApparatusFinder from "../../apis/ApparatusFinder";

import { useAuth } from "../../context/AuthContext";
//import Scanner from "../Scanner/Scanner";


import { useMainLocationsData } from "./fetchApparatusData";

import  { notify } from "../Popup/ToastNotifier";

const ApparatusHeader = () => {
  const { currentUserSiteId } = useAuth();
  const { refetch } = useMainLocationsData(currentUserSiteId);

  const [loading, setLoading] = useState(false);

  //Input useStae
  const [name, setName] = useState("");
  //const [status, setStatus] = useState("");

  const [status, setStatus] = useState("");
  const [apparatusType, setApparatusType] = useState("");
  const [appartusOrder, setApparatusOrder] = useState("");

  const [setFile] = useState(null);

  const headers = {
    "content-type": "multipart/form-data",
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Please enter a name";
    if (!apparatusType) newErrors.apparatusType = "Please select a type";
    if (!status) newErrors.status = "Please select a status";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const clearForm = () => {
    setSelectedImage(null);
    setName("");
    setApparatusType("");
    setStatus("");
    setApparatusOrder(null);
    setFile(null);
    setErrors({});
  };

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setLoading(false);
    clearForm();
  };

  const handleSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      if (!validateForm()) {
        return;
      }

      setLoading(true);
      const formData = new FormData();
      // // console.log("handleFileInput working!");

      formData.set("name", name);
      formData.set("status", status);
      formData.set("apparatusType", apparatusType);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("appartusOrder", appartusOrder);

      // Ensure selectedImage is an array
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        // If it's a single image, append it directly
        formData.append("file", selectedImage);
      }
      // eslint-disable-next-line
      const response = await ApparatusFinder.post(
        "/createapparatus",
        formData,
        headers
      );
      refetch();
      clearForm();
      togglePopup();
      notify("Location created successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to create location. Please try again.", "error"); // Show error notification
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
  const removehandleFileInput = (index) => {
    setSelectedImage(null);
  };

  const options = [
    { value: "IN-SERVICE", label: "IN-SERVICE" },
    { value: "OUT-OF-SERVICE", label: "OUT-OF-SERVICE" },
    { value: "N/A", label: "N/A" },
  ];

  const options2 = [
    { value: "Vehicle", label: "Vehicle" },
    { value: "Storage", label: "Storage" },
  ];

  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState("");



  return (
    <>
    
     
    </>
  );
};

export default ApparatusHeader;
