import React from "react";
import HomeHeader from "../components/Home/Home";
import "../components/Home/Home.css";
import { HomeContextProvider } from "../context/HomeContext";

const Home = () => {
  return (
    <HomeContextProvider>
    <>
      <HomeHeader />
    </>
    </HomeContextProvider>

  );
};

export default Home;
