import React from "react";

import MemberHeader from "../components/MemberList/MemberHeader";

import "../components/MemberList/MemberList.css";

function MemberList() {
  return (
    <div>
      {/*  {process.env.REACT_APP_App_Id === "Main" ? (
        <MemberHeaderPublic />
      ) : (
        <MemberHeader />
      )}*/}

      <MemberHeader />
    </div>
  );
}

export default MemberList;
