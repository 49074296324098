import { useQuery } from "@tanstack/react-query";
import SiteFinder from "../../apis/SiteFinder";

const fetchSiteSettings = ({ queryKey }) => {
  const currentUserSiteId = queryKey[1];
  return SiteFinder.get(`/getCategoryList`, {
    params: {
      currentUserSiteId,
    },
  });
};

export const useMainSiteCategoryData = (currentUserSiteId) => {
  return useQuery(["siteSettingsCategory", currentUserSiteId], fetchSiteSettings);
};
