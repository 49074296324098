import React, { useContext, useState } from "react";
import { TrainingInfoContext } from "../../context/TrainingInfoContext";
import TrainingFinder from "../../apis/TrainingFinder";

import PopupWeb from "../Popup/PopupNew";

import ClipLoader from "react-spinners/ClipLoader";

import { MdFlipCameraAndroid } from "react-icons/md";

import QrReader from "modern-react-qr-reader";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import PublicFolder from "../../apis/PublicFolder";
import { FaArrowRight } from "react-icons/fa";

import Select from "react-select";
import { useAuth } from "../../context/AuthContext";
import { notify } from "../../ToastNotifier";

const TrainingInfoItems = () => {
  function toggleClass(id) {
    document.getElementById(id).classList.toggle("show");
  }
  const { currentUserSiteId } = useAuth();

  const [selfie, setSelfie] = useState(false);

  const [loading, setLoading] = useState(false);

  const {
    trainingItems,
    setTrainingItems,
    setTrainingItemsCount,
    apparatusList,
  } = useContext(TrainingInfoContext);
  const { trainingId } = useContext(TrainingInfoContext);

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    fetchData3();
    setIsOpen(!isOpen1);
    setItemListPopup(!itemListPopup);
  };
  const togglePopupClose = () => {
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen5(false);
    setItemListPopup(!itemListPopup);
  };

  //Popup delete Member
  const { itemPopupOpen, setItemPopupOpen } = useContext(TrainingInfoContext);
  const MainPopup = () => {
    setItemPopupOpen(!itemPopupOpen);
  };

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [tags, setTags] = useState([]);
  const [inventoryLookUpItem, setInventoryLookUpItem] = useState("");
  const [locationFilter, setLocationFilter] = useState([]);

  const [trainingItemsSelect, setTrainingItemsSelect] = useState("");
  const fetchData3 = async () => {

    var  EventItemFilterLocation = localStorage.getItem('EventItemFilterLocation');
    var  eventItemSearch = localStorage.getItem('EventItemSearch');

    try {
      const response = await TrainingFinder.get("/items", {
        params: {
          currentUserSiteId,
          training_id: trainingId,
          EventItemFilterLocation,
          eventItemSearch
        },
      });

      setTrainingItemsSelect(response.data.data.inventoryItems);
      setTrainingItems(response.data.data.trainingItems);
      setTrainingItemsCount(response.data.data.trainingItems.length);
    } catch (err) {
      // console.log(err);
    }
  };

  const addItem = async (itemid) => {
    togglePopupClose();
    try {
      const response = await TrainingFinder.post("/addCompletedItem", {
        itemid: itemid,
        trainingId: trainingId,
        currentUserSiteId,
      });
      notify("Item added successfully", "success"); // Show success notification

      setTrainingItems(response.data.data.trainingItems);
      setTrainingItemsCount(response.data.data.trainingItems.length);
    } catch (err) {
      notify("Failed to Item added. Please try again.", "error"); // Show error notification

    }
  };

  const addItemBag = async (itemid, bagid) => {
    try {
      const response = await TrainingFinder.post("/addCompletedItemBag", {
        itemid: itemid,
        trainingId: trainingId,
        bagid: bagid,
        currentUserSiteId,
      });
      setTrainingItems(response.data.data.trainingItems);
      setTrainingItemsCount(response.data.data.trainingItems.length);
      setTrainingBagItems(response.data.data.trainingBagItems);
      setBagItems(response.data.data.bagItems);
      notify("Item added successfully", "success"); // Show success notification

    } catch (err) {
      notify("Failed to Item added. Please try again.", "error"); // Show error notification
    }
  };

  //Scan popup
  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
    setItemListPopup(!itemListPopup);
  };

  const handleScan = (decodedText) => {
    if (decodedText) {
      let data2 = decodedText.split("?")[1];
      let search = data2.split(":")[0];
      let number = data2.split(":")[1];
      // console.log(search);
      // console.log(number);
      if (search === "itemId") {
        addItem(number);
      } else if (search === "bagId") {
        editBagItemScanner(number);
      } else if (search === "compartmentId") {
        addCompartmentItems(number);
      }
    }
  };

  const handleError = (err) => {
    // console.error(err);
  };

  const editBagItemScanner = (bagid) => {
    fetchData2(bagid);
    setIsOpen2(false);
    setIsOpen3(!isOpen3);
  };

  const editBagItemScanner2 = (bagid) => {
    fetchData2(bagid);
    setIsOpen5(false);
    setIsOpen3(!isOpen3);
  };

  let currentBag = null;

  //Delete bag item
  const [trainingBagItems, setTrainingBagItems] = useState("");
  const [bagItems, setBagItems] = useState("");

  //Popup delete Member
  const [isOpen3, setIsOpen3] = useState(false);
  const deleteItemPopup = () => {
    setIsOpen3(!isOpen3);
    setItemListPopup(!itemListPopup);
  };

  const editBagItem = (bagid) => {
    fetchData2(bagid);
    setIsOpen3(!isOpen3);
    setIsOpen(!isOpen1);
  };
  const editBagItem2 = (bagid) => {
    fetchData2(bagid);
    setIsOpen3(!isOpen3);
    setItemListPopup(!itemListPopup);
  };

  const fetchData2 = async (bagid) => {
    try {
      const response = await TrainingFinder.get(
        "/getCompletedBagInfoTraining",
        {
          params: {
            trainingId: trainingId,
            bagid: bagid,
            currentUserSiteId,
          },
        }
      );
      // // console.log(response.data.data.trainingBagItems);
      // // console.log(response.data.data.bagItems);
      setTrainingBagItems(response.data.data.trainingBagItems);

      setBagItems(response.data.data.bagItems);
    } catch (err) {
      // console.log(err);
    }
  };

  const removeBagItem = (bagid, itemid) => {
    fetchData4(bagid, itemid);
  };

  const fetchData4 = async (bagid, itemid) => {
    try {
      const response = await TrainingFinder.post(
        "/getCompletedBagInfoTraining",
        {
          trainingId: trainingId,
          itemid: itemid,
          currentUserSiteId,
          bagid: bagid,
        }
      );

      setTrainingItems(response.data.data.trainingItems);
      setTrainingItemsCount(response.data.data.trainingItems.length);

      setTrainingBagItems(response.data.data.trainingBagItems);
      setBagItems(response.data.data.bagItems);
    } catch (err) {
      // console.log(err);
    }
  };

  //Compartment qr code
  const [compartmentItems, setCompartmentItems] = useState("");

  const addCompartmentItems = (compartmentId) => {
    fetchData6(compartmentId);
    setIsOpen5(!isOpen5);
    setIsOpen2(!isOpen2);
  };

  const fetchData6 = async (compartmentId) => {
    try {
      const response = await TrainingFinder.get("/getCompartmentInfoTraining", {
        params: {
          compartmentId: compartmentId,
          currentUserSiteId,
        },
      });
      setCompartmentItems(response.data.data.compartmentItems);
    } catch (err) {
      // console.log(err);
    }
  };

  const [isOpen5, setIsOpen5] = useState(false);
  const compartmentPopup = () => {
    setIsOpen5(!isOpen5);
  };

  //Popup delete Member
  const [itemListPopup, setItemListPopup] = useState(true);

  //Looping through current items
  let currentGroup = null;

  const [removeItemName, setRemoveItemName] = useState("");
  const [removeIdentificationname, setRemoveIdentificationname] = useState("");
  const [removeItemid, setRemoveItemid] = useState("");
  const [removeApparatus, setRemoveApparatus] = useState("");
  const [removeCompartmentName, setRemoveCompartmentName] = useState("");
  const [removeAmount, setRemoveAmount] = useState("");

  const removeItem = (
    itemName,
    identificationname,
    itemid,
    apparatus,
    compartmentname,
    amount
  ) => {
    // // console.log(compartmentname);
    setRemoveItemName(itemName);
    setRemoveIdentificationname(identificationname);
    setRemoveItemid(itemid);
    setRemoveApparatus(apparatus);
    setRemoveCompartmentName(compartmentname);
    setRemoveAmount(amount);
    deleteItemPopup2();
  };

  //Popup delete Member
  const [isOpen4, setIsOpen4] = useState(false);
  const deleteItemPopup2 = () => {
    setIsOpen4(!isOpen4);
    setItemListPopup(!itemListPopup);
  };

  const handleRemoveItemSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    setLoading(true);
    try {
      const response = await TrainingFinder.post("/removeCompletedItem", {
        trainingId,
        removeItemid,
        currentUserSiteId,
      });
      setLoading(false);
      setTrainingItems(response.data.data.trainingItems);
      setTrainingItemsCount(response.data.data.trainingItems.length);
      deleteItemPopup2();
      notify("Item remove successfully", "success"); // Show success notification

    } catch (err) {
      notify("Failed to remove Item. Please try again.", "error"); // Show error notification
    }
  };

  //Looping through current compartment holders
  let currentApparatus = null;
  let currentCompartment = null;

//Looping through current items

const [query, setQuery] = useState("");
const [dropdownQuery, setDropdownQuery] = useState("");

console.log(apparatusList)
let options = apparatusList.map(function (e) {
  return {
    value: e.id,
    label: e.name,
  };
});

      // Get default values for the Select component from the state
      const defaultValues = options.filter((option) =>
      locationFilter.includes(option.value)
    );
    
    
    

const toggleCollapse = () => setIsCollapsed(!isCollapsed);
const handleTagChange = (selectedOptions) => setTags(selectedOptions);
const handleClearFilters = () => {
  setLocationFilter([]);
  localStorage.removeItem("EventItemFilterLocation");
  localStorage.removeItem("EventItemSearch");
  setQuery("");
  fetchData3();
};

const filterItems = () => {
  return trainingItemsSelect.filter((post) => {
    let matchesQuery =
      !query ||
      post.itemname?.toLowerCase().includes(query.toLowerCase()) ||
      post.bags.some((data2) =>
        data2.bagitem?.toLowerCase().includes(query.toLowerCase())
      );

    let matchesDropdown =
      !dropdownQuery ||
      post.apparatusname.toLowerCase().includes(dropdownQuery.toLowerCase());

    let matchesTags =
      !tags.length || tags.some((tag) => post.tags?.includes(tag.value));

    return matchesQuery && matchesDropdown && matchesTags;
  });
};

const renderItems = (filteredItems) => {
  let currentApparatus = "";
  let currentCompartment = "";

  return filteredItems.map((data, i) => {
    const { apparatusname, compartmentname, itemname, amount, bags, bagid } =
      data;

    if (currentApparatus !== apparatusname) {
      currentApparatus = apparatusname;
      currentCompartment = compartmentname;
      return (
        <div key={i}>
          <div className="trainingItemApparatusName">
            <b>{apparatusname}</b>
          </div>
          <div className="trainingItemCompartmentName">
            <b>{compartmentname}</b>
          </div>
          {renderItemOrBag(data)}
        </div>
      );
    }

    if (currentCompartment !== compartmentname) {
      currentCompartment = compartmentname;
      return (
        <div key={i}>
          <div className="trainingItemCompartmentName">
            <b>{compartmentname}</b>
          </div>
          {renderItemOrBag(data)}
        </div>
      );
    }

    return renderItemOrBag(data, i);
  });
};

const renderItemOrBag = (data, i) => {
  if (data.itemname) {
    return (
      <div
        key={i}
        className="trainingItemContainer"
        onClick={() => addItem(data.itemid)}
      >
        <div className="trainingItemName">{data.itemname}</div>
        <div className="trainingItemAmount">{data.amount}</div>
      </div>
    );
  }


  return (
    <div key={i} className="trainingItemBagRow2">
      <div className="trainingBagNameHolder2">
        <div
          className="trainingItemName2"
          onClick={() => editBagItem(data.bagid)}
        >
          {data.bagname}
        </div>
        <div
          className="trainingBagDropdownArrow2"
          onClick={() => toggleClass(`${data.bagname}AddItem`)}
        >
          <i className="arrowdown"></i>
        </div>
      </div>
      <div
        className="trainingItemBagItemsContainer"
        id={`${data.bagname}AddItem`}
      >
        {data.bags.map((data2, j) => (
          <div
            key={j}
            className="trainingItemBagContainer"
            onClick={() => addItemBag(data2.bagitemid, data.bagid)}
          >
            <div className="trainingItemBagItemName">{data2.bagitem}</div>
            <div className="trainingItemBagItemAmount">{data2.bagAmount}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const handleChange = (e) => {
  const categorySearch = Array.isArray(e) ? e.map((x) => x.value) : [];
  localStorage.setItem("EventItemFilterLocation", categorySearch); // Store as JSON string
  setLocationFilter(categorySearch); // Update the state
  fetchData3(); // Refetch data
};

const handleSearchChange = (e) => {
  const searchQuery = inventoryLookUpItem;
  localStorage.setItem("EventItemSearch", searchQuery); // Store the search query
  setInventoryLookUpItem(searchQuery); // Update the state
  fetchData3(); // Refetch data
};


  return (
    <>
      {itemListPopup && (
        <PopupWeb
          content={
            <>

              <div className="trainingItemBottomPadding">
                {trainingItems.length > 0 ? (
                  trainingItems.map((data, i) => {
                    let loopingGroup = data.apparatus;
                    if (loopingGroup === currentGroup) {
                      return (
                        <div key={i}>
                          <div className="trainingItemContainer2">
                            <div className="trainingListItemHolder2">
                              {data.bagname ? (
                                <div className="trainingBagNameHolder">
                                  <div
                                    className="trainingitemsContainer"
                                    onClick={() => editBagItem2(data.bagid)}
                                  >
                                    <div className="trainingItemName2">
                                      {data.identificationname
                                        ? `${data.identificationname} - `
                                        : ""}
                                      {data.bagname}
                                    </div>
                                    <div className="itemPageLocation">
                                      {`${data.compartmentname}/ ${data.bagname}`}
                                    </div>
                                  </div>
                                  <div
                                    className="trainingBagDropdownArrow"
                                    onClick={() => {
                                      toggleClass(`${data.bagname}`);
                                    }}
                                  >
                                    <i className="arrowdown"></i>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="trainingitemsContainer"
                                  onClick={() =>
                                    removeItem(
                                      data.itemname,
                                      data.identificationname,
                                      data.itemid,
                                      data.apparatus,
                                      data.compartmentname,
                                      data.amount
                                    )
                                  }
                                >
                                  <div className="trainingItemName2">
                                    {data.identificationname
                                      ? `${data.identificationname} - `
                                      : ""}
                                    {data.itemname}
                                  </div>
                                  <div className="itemPageLocation">
                                    {data.compartmentname}
                                  </div>
                                </div>
                              )}

                              {data.bagname ? (
                                <div
                                  className="trainingItemBagItemsContainer"
                                  id={data.bagname}
                                >
                                  {data.bags.map((data2, i) => (
                                    <div
                                      key={i}
                                      className="trainingItemBagContainer"
                                    >
                                      <div className="trainingItemBagItemName">
                                        {data2.bagitem}
                                      </div>
                                      <div className="trainingItemBagItemAmount">
                                        {data2.bagAmount}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="trainingItemAmount2">
                              {data.amount}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      currentGroup = data.apparatus;
                      return (
                        <div key={i}>
                          <div className="itemListHeader">
                            <b>{data.apparatus}</b>
                          </div>

                          <div className="trainingItemContainer2">
                            <div className="trainingListItemHolder2">
                              {data.bagname ? (
                                <div className="trainingBagNameHolder">
                                  <div
                                    className="trainingitemsContainer"
                                    onClick={() => editBagItem2(data.bagid)}
                                  >
                                    <div className="trainingItemName2">
                                      {data.identificationname
                                        ? `${data.identificationname} - `
                                        : ""}
                                      {data.bagname}
                                    </div>
                                    <div className="itemPageLocation">
                                      {`${data.compartmentname}/ ${data.bagname}`}
                                    </div>
                                  </div>
                                  <div
                                    className="trainingBagDropdownArrow"
                                    onClick={() => {
                                      toggleClass(`${data.bagname}`);
                                    }}
                                  >
                                    <i className="arrowdown"></i>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="trainingitemsContainer"
                                  onClick={() =>
                                    removeItem(
                                      data.itemname,
                                      data.identificationname,
                                      data.itemid,
                                      data.apparatus,
                                      data.compartmentname,
                                      data.amount
                                    )
                                  }
                                >
                                  <div className="trainingItemName2">
                                    {data.identificationname
                                      ? `${data.identificationname} - `
                                      : ""}
                                    {data.itemname}
                                  </div>
                                  <div className="itemPageLocation">
                                    {data.compartmentname}
                                  </div>
                                </div>
                              )}

                              {data.bagname ? (
                                <div
                                  className="trainingItemBagItemsContainer"
                                  id={data.bagname}
                                >
                                  {data.bags.map((data2, i) => (
                                    <div
                                      key={i}
                                      className="trainingItemBagContainer"
                                    >
                                      <div className="trainingItemBagItemName">
                                        {data2.bagitem}
                                      </div>
                                      <div className="trainingItemBagItemAmount">
                                        {data2.bagAmount}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="trainingItemAmount2">
                              {data.amount}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>
                    <div className="trainingItemContainer">
                      <div className="trainingItemName">
                        <b>No items added</b>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          }
          handleClose={MainPopup}
          isOpen={itemListPopup}
          handleSubmit={togglePopup}
          showSubmitButton={true}
          headerText={       <b>Inventory Used</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 
        />
      )}

{isOpen1 && (
        <PopupWeb
          content={
            <>
              <div className="top">
                <div className="filter-search-toggle-container-popup">
                  <button className="toggle-button" onClick={toggleCollapse}>
                    {isCollapsed ? "Show Filters" : "Hide Filters"}
                  </button>
                  <div
                    className={`filter-search-container ${
                      isCollapsed ? "collapsed" : ""
                    }`}
                  >
                    <label className="NewInputLabel123" data-domain="Location">
                      <Select
                        isMulti
                        options={options}
                        onChange={handleChange}
                        value={defaultValues}
                        className="filter-dropdown"
                        classNamePrefix="Select"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </label>
                    <div className="search-input-container">
                      <input
                        className="inventorySearchButton"
                        placeholder="Search Item Name"
                        type="search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                      <button className="search-button">
                        <span className="arrow-icon">
                          <FaArrowRight />
                        </span>
                      </button>
                    </div>

                    <button
                      className="clear-filters"
                      onClick={handleClearFilters}
                    >
                      Clear
                    </button>
                    <div className="addScan22" onClick={togglePopup2}>
                      <MdOutlineQrCodeScanner />
                    </div>
                  </div>
                </div>
              </div>

              <div className="detailsPopupContainer">
                {trainingItemsSelect.length > 0 ? (
                  renderItems(filterItems())
                ) : (
                  <div>
                    <b>No items</b>
                  </div>
                )}
              </div>
            </>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          showSubmitButton={false}
          headerText={<b>Select Item To Add</b>}
        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <div className="popupScanner">
              <div onClick={() => setSelfie(!selfie)} className="cameraFlip">
                <MdFlipCameraAndroid />
              </div>

              <div className="coverUp"> &nbsp;</div>
              <div className="qrscannerContainer">
                <QrReader
                  delay={20}
                  onError={handleError}
                  // onScan={handleScan}
                  onScan={handleScan}
                  className="qrScanner"
                  resolution={800}
                  facingMode={selfie ? "user" : "environment"}
                  showViewFinder={false}
                />

                {/*data*/}
              </div>
            </div>
          }
          handleClose={togglePopup2}
          isOpen={isOpen2}
          showSubmitButton={false}
          headerText={  <b>Scan a QR code </b>}
        />
      )}
      {isOpen3 && (
        <PopupWeb
          content={
            <div>
              {trainingBagItems.length > 0 ? (
                trainingBagItems.map((data, i) => {
                  // // console.log(data);
                  if (i === 0) {
                    return (
                      <div key={i}>
                        <div id="holderTest" className="holderTest">
                          <div className="popupTitle">
                            <b>{data.bagname}</b>
                          </div>
                        </div>
                        <div className="holderTestEmpty"></div>

                        <div className="appratusBagPopup">
                          {data.imagepath ? (
                            <img
                              src={PublicFolder.baseURL + data.imagepath}
                              alt="Did Not Load"
                              className="apparatusBagImg"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="apparatusBagPopupitemTextHolder1">
                          <div className="itemText1">Item</div>
                          <div className="amountText1">Amount</div>
                        </div>
                        <div
                          className="itemContainer"
                          onClick={() => removeBagItem(data.bagid, data.itemid)}
                        >
                          {data.image ? (
                            <div className="inventoryImgDiv">
                              <img
                                src={PublicFolder.baseURL + data.image}
                                alt={data.name.charAt(0).toUpperCase()}
                                className="inventoryImg"
                              />
                            </div>
                          ) : (
                            <div className="noPicDiv">
                              {data.name.charAt(0).toUpperCase()}
                            </div>
                          )}

                          <div className="itemDiv">{data.name}</div>
                          <div className="itemAmount">{data.amount}</div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={i}>
                        <div
                          className="itemContainer"
                          onClick={() => removeBagItem(data.bagid, data.itemid)}
                        >
                          {data.image ? (
                            <div className="inventoryImgDiv">
                              <img
                                src={PublicFolder.baseURL + data.image}
                                alt="Did Not Load"
                                className="inventoryImg"
                              />
                            </div>
                          ) : (
                            <div className="noPicDiv">
                              {data.name.charAt(0).toUpperCase()}
                            </div>
                          )}
                          <div className="itemDiv">{data.name}</div>
                          <div className="itemAmount">{data.amount}</div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <>
                  <div id="holderTest" className="holderTest">
                    <div className="popupTitle">
                      <b>No Items Added</b>
                    </div>
                  </div>
                  <div className="holderTestEmpty"></div>

                  {bagItems.length > 0 ? (
                    bagItems.map((data, i) => {
                      if (i === 0) {
                        return (
                          <div className="appratusBagPopup" key={i}>
                            {data.imagepath ? (
                              <>
                                <img
                                  src={PublicFolder.baseURL + data.imagepath}
                                  alt="Did Not Load"
                                  className="apparatusBagImg"
                                />
                              </>
                            ) : (
                              <div className="appratusBagPopup"></div>
                            )}
                          </div>
                        );
                      } else {
                        return <div key={i}></div>;
                      }
                    })
                  ) : (
                    <div className="appratusBagPopup">dsklfsdkj</div>
                  )}
                </>
              )}

              <div className="appartusBagPopupContainer">
                {bagItems.length > 0 ? (
                  bagItems.map((data, i) => {
                    //// console.log(data);
                    if (i === 0) {
                      return (
                        <div key={i}>
                          <div className="appartusBagPopupItemsLeft">
                            <b> Items To Add</b>
                          </div>
                          <div className="apparatusBagPopupitemTextHolder1">
                            <div className="itemText1">Item</div>
                            <div className="amountText1">Amount</div>
                          </div>
                          <div
                            className="itemContainer"
                            onClick={() => addItemBag(data.itemid, data.bagid)}
                          >
                            {data.image ? (
                              <div className="inventoryImgDiv">
                                <img
                                  src={PublicFolder.baseURL + data.image}
                                  alt={data.name.charAt(0).toUpperCase()}
                                  className="inventoryImg"
                                />
                              </div>
                            ) : (
                              <div className="noPicDiv">
                                {data.name.charAt(0).toUpperCase()}
                              </div>
                            )}

                            <div className="itemDiv">{data.name}</div>
                            <div className="itemAmount">{data.amount}</div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={i}>
                          <div
                            className="itemContainer"
                            onClick={() => addItemBag(data.itemid, data.bagid)}
                          >
                            {data.image ? (
                              <div className="inventoryImgDiv">
                                <img
                                  src={PublicFolder.baseURL + data.image}
                                  alt={data.name.charAt(0).toUpperCase()}
                                  className="inventoryImg"
                                />
                              </div>
                            ) : (
                              <div className="noPicDiv">
                                {data.name.charAt(0).toUpperCase()}
                              </div>
                            )}
                            <div className="itemDiv">{data.name}</div>
                            <div className="itemAmount">{data.amount}</div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <>
                    <div className="appartusBagPopupItemsAdded">
                      <b>All Items Added</b>
                    </div>
                  </>
                )}
              </div>
              <div className="removeMemberBtnContainer">
                <div className="removeMemberBtn" onClick={deleteItemPopup}>
                  Done
                </div>
              </div>
            </div>
          }
          handleClose={deleteItemPopup}
          isOpen={isOpen3}
          handleSubmit={deleteItemPopup}
          showSubmitButton={true}
          headerText={<b> Bag</b>}
          submitButtonText={loading ? "Loading..." : "Done"} 
        />
      )}

      {isOpen4 && (
        <PopupWeb
          content={
            <>
           
              <div className="removeItemContainer">
                <div className="itemListHeader">
                  <b>{removeApparatus} </b>
                </div>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="trainingItemContainer2">
                  <div className="trainingListItemHolder2">
                    <div className="trainingitemsContainer">
                      <div className="trainingItemName2">
                        {removeIdentificationname
                          ? `${removeIdentificationname} - `
                          : ""}
                        {removeItemName}
                      </div>
                      <div className="itemPageLocation">
                        {removeCompartmentName}
                      </div>
                    </div>
                  </div>
                  <div className="trainingItemAmount2">{removeAmount}</div>
                </div>
              </div>

      
            </>
          }
          handleClose={deleteItemPopup2}
          isOpen={isOpen4}
          handleSubmit={handleRemoveItemSubmit}
          showSubmitButton={true}
          headerText={                <b>Remove Item</b>}
          submitButtonText={loading ? "Loading..." : "Remove"} 
        />
      )}

      {isOpen5 && (
        <PopupWeb
          content={
            <>
              <div className="appartusBagPopupContainer">
                {compartmentItems.length > 0 ? (
                  compartmentItems.map((data, i) => {
                    // // console.log(data);
                    if (i === 0 && data.itemname != null) {
                      return (
                        <div key={i}>
                          <div id="holderTest" className="holderTest">
                            <div className="topbar22 headerActive"></div>

                            <div className="popupTitle">
                              <b>{data.compartmentname}</b>
                            </div>
                          </div>
                          <div className="holderTestEmpty"></div>

                          <div className="appratusBagPopup">
                            {data.imagepath ? (
                              <img
                                src={PublicFolder.baseURL + data.imagepath}
                                alt="Did Not Load"
                                className="apparatusBagImg"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="apparatusBagPopupitemTextHolder">
                            <div className="itemText1">Item</div>
                            <div className="amountText1">Amount</div>
                          </div>
                          <div
                            className="itemRow"
                            onClick={() => addItem(data.itemid)}
                          >
                            <div className="itemName">{data.itemname}</div>
                            <div className="itemAmount">{data.amount}</div>
                          </div>
                        </div>
                      );
                    } else if (i === 0) {
                      currentBag = data.bagname;
                      return (
                        <div key={i}>
                          <div id="holderTest" className="holderTest">
                            <div className="popupTitle">
                              <b>{data.compartmentname}</b>
                            </div>
                          </div>
                          <div className="appratusBagPopup">
                            {data.imagepath ? (
                              <img
                                src={PublicFolder.baseURL + data.imagepath}
                                alt="Did Not Load"
                                className="apparatusBagImg"
                              />
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="apparatusBagPopupitemTextHolder">
                            <div className="itemText1">Item</div>
                            <div className="amountText1">Amount</div>
                          </div>

                          <div className="bagRow">
                            <div className="bagName2">
                              <div
                                className="bagName"
                                onClick={() => editBagItemScanner2(data.bagid)}
                              >
                                {data.bagname}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }

                    if (data.itemname != null) {
                      return (
                        <div key={i}>
                          <div
                            className="itemRow"
                            onClick={() => addItem(data.itemid)}
                          >
                            <div className="itemName">{data.itemname}</div>
                            <div className="itemAmount">{data.amount}</div>
                          </div>
                        </div>
                      );
                    } else {
                      if (data.bagname !== currentBag) {
                        currentBag = data.bagname;
                        return (
                          <div key={i}>
                            <div className="bagRow">
                              <div className="bagName2">
                                <div
                                  className="bagName"
                                  onClick={() =>
                                    editBagItemScanner2(data.bagid)
                                  }
                                >
                                  {data.bagname}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={i}>
                            <div className="itemRow">
                              <div className="itemName">{data.bagitem}</div>
                              <div className="itemAmount">{data.bagAmount}</div>
                            </div>
                          </div>
                        );
                      }
                    }
                  })
                ) : (
                  <></>
                )}
              </div>
            </>
          }
       
          handleClose={compartmentPopup}
          isOpen={isOpen5}
          showSubmitButton={false}
          headerText={                <b>Remove Item</b>}
          submitButtonText={loading ? "Loading..." : "Remove"} 

        />
      )}
    </>
  );
};

export default TrainingInfoItems;
