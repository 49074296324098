import React, { useState, useEffect } from "react";
import SiteFinder from "../../apis/SiteFinder";
import UserFinder from "../../apis/UserFinder";

import PopupWeb from "../Popup/PopupNew";

import { useAuth } from "../../context/AuthContext";

import "./Settings.css";
import ClipLoader from "react-spinners/ClipLoader";

const SettingsProfileEdit = () => {
  const { currentUserId, currentUserSiteId, updateUserData } = useAuth();

  const [setSiteList] = useState("");

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [newSiteName, setNewSiteName] = useState("");

  const [error, setError] = useState("");

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SiteFinder.get(`/getSiteList`, {
          params: {
            currentUserId,
          },
        });
        console.log(response.data.data.userInfo[0].email);
        setEmail(response.data.data.userInfo[0].email);
        setPhoneNumber(response.data.data.userInfo[0].phone);
        setFirstName(response.data.data.userInfo[0].firstname);
        setLastName(response.data.data.userInfo[0].lastname);
      } catch (err) {
        console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]); // eslint-disable-line react-hooks/exhaustive-deps
  // eslint-disable-next-line
  const handleCurrentSite = async (id) => {
    //stop the page from reloading
    // e.preventDefault();
    try {
      setLoading(true);
      console.log(id);
      // eslint-disable-next-line
      const response = await SiteFinder.post("/changeSite", {
        site_id: id,
        currentUserId,
      });

      updateUserData(currentUserId);
      // window.location.reload();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };



  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };
  const togglePopup22 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const response = await UserFinder.post("/updateUser", {
        userid: currentUserId,
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
      });
      setLoading(false);
      if (response.data.data.alert) {
        setError(response.data.data.alert);
        return;
      }
      //  refetch();
      updateUserData(currentUserId);

      togglePopup22();
    } catch (err) {
      setLoading(false);
      setError("Failed to update account");
    }
    setLoading(false);
  };

  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
  };

  const handleNewSite = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const response = await UserFinder.post("/createNewSite", {
        currentUserId: currentUserId,
        newSiteName,
      });
      setLoading(false);
      if (response.data.data.alert) {
        setError(response.data.data.alert);
        return;
      }
      //  refetch();
      updateUserData(currentUserId);
      setSiteList(response.data.data.siteList);

      togglePopup3();
    } catch (err) {
      setLoading(false);
      setError("Failed to update account");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="settingsHolder">
        {/*  <div className="settingsLeftContainer">
            <div className="nav-links-header">
            <div className="features-item-holder">
              <div className="features-item-text">GENERAL</div>
            </div>
          </div>
          <div
            className="nav-links"
            to="/apparatus"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <AiOutlineAppstoreAdd />
              </div>
              <div className="features-item-text">Profile</div>
            </div>
          </div>
         <div
            className="nav-links"
            to="/apparatus"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <AiOutlineAppstoreAdd />
              </div>
              <div className="features-item-text">Security</div>
            </div>
      </div>

          <div className="nav-links-header">
            <div className="features-item-holder">
              <div className="features-item-text">SITES</div>
            </div>
          </div>
          {siteList &&
            siteList.map((data, i) => {
              //console.log(data);
              return (
                <div key={i}>
                  <div
                    className="nav-links"
                    onClick={() => handleCurrentSite(data.site_id)}
                  >
                    <div className="features-item-holder">
                      <div className="features-item-Icon">
                        <AiOutlineAppstoreAdd />
                      </div>
                      <div className="features-item-text">{data.name}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="features-item-text">New Site</div>
          <div
            className="nav-links"
            to="/apparatus"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <AiOutlineAppstoreAdd />
              </div>
              <div className="features-item-text" onClick={togglePopup3}>
                Create New Site
              </div>
            </div>
         </div>
            </div>*/}

        <div className="settingsRightContainer">
          <div className="settingsRightHeader">Name</div>
          <div className="settingsRightInfo">{firstName + " " + lastName}</div>
          {/*}   <div className="settingsRightLink">
            Edit preferred first and last name
          </div>*/}
          <div className="settingsRightLink"></div>
          <div className="settingsRightHeader">Email</div>
          <div className="settingsRightInfo">{email}</div>
          <div className="settingsRightLink"></div>
          <div className="settingsRightHeader">Phone</div>
          <div className="settingsRightInfo">
            {phoneNumber ? phoneNumber : ""}
          </div>{" "}
          <div className="settingsRightLink"></div>
          <div className="settingsRightLink">
            {" "}
            <button type="submit" className="" onClick={togglePopup2}>
              Edit
            </button>
          </div>
        </div>
      </div>

      {isOpen2 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={handleEditUser} className="userForm">
                <div className="text-center ">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                {error && <div className="alert">{error}</div>}

                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="First Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Last Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Email">
                    <input
                      className="NewInput"
                      type="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Phone Number">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </label>
                </div>
              </form>
            </div>
          }
          handleClose={togglePopup2}
          isOpen={isOpen2}
          handleSubmit={handleEditUser}
          showSubmitButton={true}
          headerText={<b> Edit Profile</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      {isOpen3 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={handleNewSite} className="userForm">
                <div className="text-center ">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                {error && <div className="alert">{error}</div>}

                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Site Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setNewSiteName(e.target.value)}
                      value={newSiteName}
                      required
                    />
                  </label>
                </div>
              </form>
            </div>
          }
          handleClose={togglePopup3}
          isOpen={isOpen3}
          handleSubmit={handleNewSite}
          showSubmitButton={true}
          headerText={<b> Create New Site</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}
    </>
  );
};

export default SettingsProfileEdit;
