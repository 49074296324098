import React from "react";
import SettingsHeader from "../components/Settings/SettingsHeader";

import { SettingsContextProvider } from "../context/SettingsContext";

const Settings = () => {
  return (
    <SettingsContextProvider>
      <>
        <SettingsHeader />
      </>
    </SettingsContextProvider>
  );
};

export default Settings;
