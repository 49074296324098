import React from "react";
import "../components/PreviousTraining/PreviousTraining.css";
import PreviousTrainingHeader from "../components/PreviousTraining/PreviousTrainingHeader";
import PreviousTrainingList from "../components/PreviousTraining/PreviousTrainingList";

const PreviousTraining = () => {
  return (
    <>
      <PreviousTrainingHeader />

      <PreviousTrainingList />
    </>
  );
};

export default PreviousTraining;
