import React from "react";

//import Scanner from "../Scanner/Scanner";

import DefaultItem from "./DefaultItem";


import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const AdminHeader = () => {
  //Photos
  //https://www.cleanpng.com/free/id.html

  //var bottomBar = document.getElementById("popup-1");

  return (
    <>

      <div className="detailsPopupContainer">
        <Tabs>
          <TabList>
            <Tab>
              <b>Default Items</b>
            </Tab>

          </TabList>

          <TabPanel>
            <DefaultItem/>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default AdminHeader;
