import React, { useEffect, useState } from "react";
import UserFinder from "../../../apis/UserFinder";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";
import { useAuth } from "../../../context/AuthContext";

const UserInfoItems = () => {
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();

  const [eventInfo, setEventInfo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [eventType, setEventType] = useState([]);

  const [dropdownQuery, setDropdownQuery] = useState("");
  const [dropdownQueryYear, setDropdownQueryYear] = useState("");

  let history = useNavigate();

  let loopingGroup = null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UserFinder.get(`/getEventsAttended`, {
          params: {
            userid: id,
            currentUserSiteId,
          },
        });

        setEventInfo(response.data.data.trainingInfo);
        setFirstName(response.data.data.userInfo[0].firstname);
        setLastName(response.data.data.userInfo[0].lastname);
        setEventType(response.data.data.eventTypeResults);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    } // eslint-disable-next-line
  }, [currentUserSiteId]);

  const handleTraining = (id) => {
    history(`/trainingInfo/${id}`);
  };

  const sideBar = document.getElementById("sidebar");

  return (
    <div className="eventHolder">
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>

        <div className="smallOnScreen" id="header">
          <div className="smallHeader">
            <b>
              {firstName} {lastName}
            </b>
          </div>
        </div>
      </div>

      <div className="eventsHolder">
        <b>User Events</b>
      </div>

      <div className="inventorySelectHolder">
        <div className="inventorySelect1">
          <label className="NewInputLabel" data-domain="Category">
            <select
              className="NewInput"
              onChange={(event) => setDropdownQuery(event.target.value)}
            >
              <option value="">All</option>

              {eventType.map(function (e, i) {
                return (
                  <option key={i} value={e.eventtype}>
                    {e.eventtype}
                  </option>
                );
              })}
            </select>
          </label>
        </div>

        <div className="inventorySelect22">
          <label className="NewInputLabel" data-domain="Year">
            <select
              className="NewInput"
              onChange={(event) => setDropdownQueryYear(event.target.value)}
            >
              <option value="">All</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </label>
        </div>
      </div>

      {eventInfo.length > 0 ? (
        eventInfo

          // eslint-disable-next-line
          .filter((post) => {
            if (dropdownQuery === "" && dropdownQueryYear === "") {
              return post;
            } else if (
              post.category
                .toLowerCase()
                .includes(dropdownQuery.toLowerCase()) &&
              dropdownQueryYear === ""
            ) {
              return post;
            } else if (
              post.month
                .toLowerCase()
                .includes(dropdownQueryYear.toLowerCase()) &&
              dropdownQuery === ""
            ) {
              return post;
            } else if (
              post.month
                .toLowerCase()
                .includes(dropdownQueryYear.toLowerCase()) &&
              post.category.toLowerCase().includes(dropdownQuery.toLowerCase())
            ) {
              return post;
            }
          })
          .map((data, i) => {
            // console.log(data);
            if (loopingGroup === data.month) {
              return (
                <div key={i}>
                  <div
                    className="itemListContainerHolder"
                    onClick={() => handleTraining(data.id)}
                  >
                    <div className="userItemListPageHolder">
                      <div className="itemListPageName">
                        {data.training_name}
                      </div>
                      <div className="userInfoEventTypeName">
                        {data.category}
                      </div>
                    </div>
                    <div className="userItemListPageAmount">{data.date2}</div>
                  </div>
                </div>
              );
            } else {
              loopingGroup = data.month;
              return (
                <div key={i}>
                  <div className="itemListHeader">
                    <b>{data.month}</b>
                  </div>
                  <div
                    className="itemListContainerHolder"
                    onClick={() => handleTraining(data.id)}
                  >
                    <div className="userItemListPageHolder">
                      <div className="itemListPageName">
                        {data.training_name}
                      </div>
                      <div className="userInfoEventTypeName">
                        {data.category}
                      </div>
                    </div>
                    <div className="userItemListPageAmount">{data.date2}</div>
                  </div>
                </div>
              );
            }
          })
      ) : (
        <>No attended events</>
      )}
    </div>
  );
};

export default UserInfoItems;
