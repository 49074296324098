import React from "react";
import "../components/AlertPage/AlertPage.css";
//import ReportsAlertsComponent from "../../../../../New folder/Web app/Inventory-Web-App-Production/react-production/client/src/components/Reports/ReportsAlerts";

function AlertPage() {
  return (
      <>
      
      </>

  );
}

export default AlertPage;




