import React from "react";
import "../components/Training/Training.css";

import UserInfoEvents from "../components/AdminPage/AdminUserPage/UserInfoEvents";

const UserEventsAttended = () => {
  return (
    <>
      <UserInfoEvents />
    </>
  );
};

export default UserEventsAttended;
