import React from "react";

//import ChecklistMainPage from "../components/Checklist/ChecklistMainPageWeb";
import ChecklistHeader from "../components/Checklist/ChecklistHeader";

import "../components/Checklist/Checklist.css";

function Checklist() {
  return (
    <div>
      <ChecklistHeader />
    </div>
  );
}

export default Checklist;
