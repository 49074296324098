import React, { useState, useRef } from "react";
import ApparatusFinder from "../../apis/ApparatusFinder";
import { useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";

import { useAuth } from "../../context/AuthContext";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";

import ClipLoader from "react-spinners/ClipLoader";

import { useMainLocationsData } from "./fetchApparatusData";

import Select from "react-select";
import PopupWeb from "../Popup/PopupNew";
import { FaArrowRight } from "react-icons/fa";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";


const ApparatusList = () => {
  const { currentUserRole, currentUserSiteId } = useAuth();
  const { data, refetch } = useMainLocationsData(currentUserSiteId);

  let history = useNavigate();

  const [locationLookUpItem, setLocationLookUpItem] = useState("");


  const handleApparatus = (id) => {
    history(`/location/${id}`);
  };
  const handleApparatusChecklist = (id) => {
    history(`/location  /${id}/checklist`);
  };

  function toggleClass(id) {
    document.getElementById(id).classList.toggle("show");
  }


  const handleSearchChange = (e) => {
    const searchQuery = locationLookUpItem;
    localStorage.setItem("LocationSearchQuery", searchQuery); // Store the search query
    setLocationLookUpItem(searchQuery); // Update the state
    refetch(); // Refetch data
  };

   //Popup
   const [isOpenAdd, setIsOpenAdd] = useState(false);
   const togglePopupAdd = () => {
     setIsOpenAdd(!isOpenAdd);
     setLoading(false);
     clearForm();
   };


  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setLoading(false);
  };

  const togglePopupClose = () => {
    setIsOpen(!isOpen1);
    setLoading(false);
    setAparatusId("");
    setApparatusName("");
    setApparatusStatus("");
    setOldImgPath("");
    setApparatusType("");
    setSelectedImage("");
  };

  //Popup
  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(true);
    setIsOpen(false);
  };
  const togglePopup22 = () => {
    setIsOpen2(false);
    setIsOpen(false);
  };

  const [loading, setLoading] = useState(false);

  //Input useStae
  const [apparatusId, setAparatusId] = useState("");
  const [apparatusName, setApparatusName] = useState("");
  const [apparatusStatus, setApparatusStatus] = useState("");
  const [apparatusType, setApparatusType] = useState("");
  const [appartusOrder, setApparatusOrder] = useState("");

  const [oldImgPath, setOldImgPath] = useState("");
  const [setFile] = useState(null);

  const headers = {
    "content-type": "multipart/form-data",
  };


  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!apparatusName) newErrors.name = "Please enter a name";
    if (!apparatusType) newErrors.apparatusType = "Please select a type";
    if (!apparatusStatus) newErrors.status = "Please select a status";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateFormAdd = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Please enter a name";
    if (!apparatusType) newErrors.apparatusType = "Please select a type";
    if (!status) newErrors.status = "Please select a status";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const clearForm = () => {
    setSelectedImage(null);
    setApparatusName("");
    setApparatusType("");
    setApparatusStatus("");
    setName("");
    setStatus("");
    setApparatusOrder(null);
    setErrors({});
  };


  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
  const removehandleFileInput = (index) => {
    setSelectedImage(null);
  };


  const editApparatus = async (
    apparatusId,
    apparatusName,
    apparatusStatus,
    apparatusImage,
    apparatusType,
    displayorder,
    evt
  ) => {
    evt.stopPropagation();
    setAparatusId(apparatusId);
    setApparatusName(apparatusName);
    setApparatusStatus(apparatusStatus);
    setOldImgPath(apparatusImage);
    setApparatusType(apparatusType);
    setApparatusOrder(displayorder);
    togglePopup();
  };

  //edit apparatus
  const handleSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {

      if (!validateForm()) {
        return;
      }


      setLoading(true);
      const formData = new FormData();
      // // console.log("handleFileInput working!");

      formData.set("apparatusId", apparatusId);
      formData.set("name", apparatusName);
      formData.set("status", apparatusStatus);
      formData.set("apparatusType", apparatusType);
      formData.set("oldImgPath", oldImgPath);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("appartusOrder", appartusOrder === null ? "" : appartusOrder);

      // Ensure selectedImage is an array
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        // If it's a single image, append it directly
        formData.append("file", selectedImage);
      }
      // eslint-disable-next-line 
      const response = await ApparatusFinder.post(
        "/editApparatus",
        formData,
        headers
      );

      setLoading(false);
      refetch();
      clearForm();
      togglePopup();
      notify("Location updated successfully!", "success"); // Show success notification

    } catch (err) {
      console.log(err);
      setLoading(false);
      notify("Failed to update location. Please try again.", "error"); // Show error notification

    }
  };

  //Delete apparatus

  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response = await ApparatusFinder.post(`/deleteApparatus`, {
        apparatusId,
        oldImgPath,
        currentUserSiteId,
      });

      refetch();
      togglePopup22();
      notify("Location deleted successfully!", "success"); // Show success notification

    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to delete location. Please try again.", "error"); // Show error notification

    }
  };

  const options = [
    { value: "IN-SERVICE", label: "IN-SERVICE" },
    { value: "OUT-OF-SERVICE", label: "OUT-OF-SERVICE" },
    { value: "N/A", label: "N/A" },
  ];

  const options2 = [
    { value: "Vehicle", label: "Vehicle" },
    { value: "Storage", label: "Storage" },
  ];
  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState("");





  //Input useStae
  const [name, setName] = useState("");
  //const [status, setStatus] = useState("");

  const [status, setStatus] = useState("");





    const handleSubmitAdd = async (e) => {
      //stop the page from reloading
      e.preventDefault();
      try {
        if (!validateFormAdd()) {
          return;
        }
  
        setLoading(true);
        const formData = new FormData();
        // // console.log("handleFileInput working!");
  
        formData.set("name", name);
        formData.set("status", status);
        formData.set("apparatusType", apparatusType);
        formData.set("currentUserSiteId", currentUserSiteId);
        formData.set("appartusOrder", appartusOrder === null ? "" : appartusOrder);
  
        // Ensure selectedImage is an array
        if (Array.isArray(selectedImage)) {
          selectedImage.forEach((image, index) => {
            formData.append(`file${index}`, image);
          });
        } else if (selectedImage) {
          // If it's a single image, append it directly
          formData.append("file", selectedImage);
        }
        // eslint-disable-next-line
        const response = await ApparatusFinder.post(
          "/createapparatus",
          formData,
          headers
        );
        refetch();
        clearForm();
        togglePopupAdd();
        notify("Location created successfully!", "success"); // Show success notification
      } catch (err) {
        // console.log(err);
        setLoading(false);
        notify("Failed to create location. Please try again.", "error"); // Show error notification
      }
    };
  


    
  return (
    <>


<div className="filter-search-toggle-container">
        <div className={`filter-search-container`}>
          <div className="search-input-container">
            <input
              className="inventorySearchButton"
              placeholder="Search Locations"
              type="search"
               value={locationLookUpItem}
              onChange={(e) => setLocationLookUpItem(e.target.value)}
            />
            <button className="search-button"  onClick={handleSearchChange}>
              <span className="arrow-icon">
                <FaArrowRight />
              </span>
            </button>
          </div>

          <button
            className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={togglePopupAdd}
          >
            Add Location
          </button>
          <div className="selected-options-display">
            <div className="selected-options-row"></div>
          </div>
        </div>

        <div className="mb-4"></div>
      </div>


      {data?.data.data.apparatus.length > 0 ? (
        data?.data.data.apparatus.map((apparatus) => {
          // // console.log(apparatus);
          if (apparatus.image) {
            return (
              <div key={apparatus.id} className="apparatusHolder">
                <div className="apparatusContainer">
                  <div
                    className="apparatusNameContainer"
                    onClick={() => {
                      toggleClass(`${apparatus.id}`);
                    }}
                  >
                    <img
                      src={PublicFolder.baseURL + "preview_" + apparatus.image}
                      alt="Did Not Load"
                      className="apparatusImg"
                    />
                    <div className="apparatusName">{apparatus.name}</div>

                    {apparatus.status === "IN-SERVICE" ? (
                      <div className="apparatusINStatus">
                        <b>{apparatus.status}</b>
                      </div>
                    ) : apparatus.status === "OUT-OF-SERVICE" ? (
                      <div className="apparatusOUTStatus">
                        <b>{apparatus.status}</b>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="apparatusDropdown" id={apparatus.id}>
                      <div className="apparatusDropdown2">
                        <div
                          className="apparatusInventoryLink"
                          onClick={() => handleApparatus(apparatus.id)}
                        >
                          <b>Inventory</b>
                        </div>
                        <div
                          className="apparatusChecklistLink"
                          onClick={() => handleApparatusChecklist(apparatus.id)}
                        >
                          <b>Check List</b>
                        </div>

                        {(currentUserRole === "Advanced" ||
                          currentUserRole === "Admin") && (
                            <div
                              className="apparatusChecklistLinkEdit"
                              onClick={(evt) =>
                                editApparatus(
                                  apparatus.id,
                                  apparatus.name,
                                  apparatus.status,
                                  apparatus.image,
                                  apparatus.type,
                                  apparatus.displayorder,
                                  evt
                                )
                              }
                            >
                              <b>Edit</b>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={apparatus.id} className="apparatusHolder">
                <div className="apparatusContainer">
                  <div
                    className="apparatusNameContainer"
                    onClick={() => {
                      toggleClass(`${apparatus.id}`);
                    }}
                  >
                    <div className="apparatusNameNoImage">{apparatus.name}</div>

                    {apparatus.status === "IN-SERVICE" ? (
                      <div className="apparatusINStatus">
                        <b>{apparatus.status}</b>
                      </div>
                    ) : apparatus.status === "OUT-OF-SERVICE" ? (
                      <div className="apparatusOUTStatus">
                        <b>{apparatus.status}</b>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="apparatusDropdownNoImage" id={apparatus.id}>
                      <div className="apparatusDropdown2">
                        <div
                          className="apparatusInventoryLink"
                          onClick={() => handleApparatus(apparatus.id)}
                        >
                          <b>Inventory</b>
                        </div>
                        <div
                          className="apparatusChecklistLink"
                          onClick={() => handleApparatusChecklist(apparatus.id)}
                        >
                          <b>Check List</b>
                        </div>
                        {(currentUserRole === "Advanced" ||
                          currentUserRole === "Admin") && (
                            <div
                              className="apparatusChecklistLinkEdit"
                              onClick={(evt) =>
                                editApparatus(
                                  apparatus.id,
                                  apparatus.name,
                                  apparatus.status,
                                  apparatus.image,
                                  apparatus.type,
                                  apparatus.displayorder,
                                  evt
                                )
                              }
                            >
                              <b>Edit</b>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })
      ) : (
        <>
          <div className="apparatusEmptyHolder">
            <div className="apparatusEmptyName">No Current Locations</div>
            <div className="apparatusEmptyButton">Tap + to start</div>
          </div>
        </>
      )}

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleSubmit}>
                <div className="text-center">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={apparatusName}
                      onChange={(e) => setApparatusName(e.target.value)}
                      required
                    />
                    {errors.name && (
                      <div className="errorInputLabel">{errors.name}</div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Type">


                    <Select
                      options={options2}
                      onChange={(e) => setApparatusType(e.value)}
                      value={options2.find(option => option.value === apparatusType)}

                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />

                    {errors.apparatusType && (
                      <div className="errorInputLabel">
                        {errors.apparatusType}
                      </div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Status">


                    <Select
                      options={options}
                      onChange={(e) => setApparatusStatus(e.value)}
                      value={options.find(option => option.value === apparatusStatus)}

                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />

                    {errors.status && (
                      <div className="error">{errors.status}</div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={appartusOrder === null ? "" : appartusOrder}
                      onChange={(e) => setApparatusOrder(e.target.value)}
                    />
                  </label>

                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                    </div>
                    <div className="imagePreviewContainer">
                      {selectedImage && (
                        <div className="imageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                          <button
                            type="button"
                            className="removeImageButton"
                            onClick={removehandleFileInput}
                          >
                            <RiCloseFill size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </form>
           {apparatusId ?  <div className="editApparatusBtnContainer">
                <div className="editApparatusBtn" onClick={togglePopup2}>
                  <b>Delete</b>
                </div>
              </div>
              : ""} 
            </>
          }

          handleClose={togglePopupClose}
          isOpen={isOpen1}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={<b>Edit Location</b>}
          submitButtonText={loading ? "Loading..." : "Save"} // Optionally change the text to indicate loading

        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>


              <div className="deleteInfoApparatusContainer">
                <b>This location will no longer be available</b>
              </div>

            </>
          }


          handleClose={togglePopup22}
          isOpen={isOpen2}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={<b>Delete - {apparatusName} </b>}
          submitButtonText={loading ? "Loading..." : "Delete"} // Optionally change the text to indicate loading

        />
      )}


{isOpenAdd && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleSubmitAdd}>
                <div className="text-center">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    {errors.name && (
                      <div className="errorInputLabel">{errors.name}</div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Type">
                    <Select
                      options={options2}
                      onChange={(e) => setApparatusType(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.apparatusType && (
                      <div className="errorInputLabel">
                        {errors.apparatusType}
                      </div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Status">
                    <Select
                      options={options}
                      onChange={(e) => setStatus(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.status && (
                      <div className="errorInputLabel">{errors.status}</div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={appartusOrder === null ? "" : appartusOrder}
                      onChange={(e) => setApparatusOrder(e.target.value)}
                    />
                  </label>

                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                    </div>
                    <div className="imagePreviewContainer">
                      {selectedImage && (
                        <div className="imageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                          <button
                            type="button"
                            className="removeImageButton"
                            onClick={removehandleFileInput}
                          >
                            <RiCloseFill size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopupAdd}
          isOpen={isOpenAdd}
          handleSubmit={handleSubmitAdd}
          showSubmitButton={true}
          headerText={<b> New Location</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      <ToastNotifier /> 
    </>
  );
};

export default ApparatusList;
