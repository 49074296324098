import React from "react";
import "../components/Reports/Reports.css";
import ReportsInformationComponent from "../components/Reports/ReportsInventoryInformation";

const ReportsInventoryInformation = () => {
  return (
    <>
      <ReportsInformationComponent />
    </>
  );
};

export default ReportsInventoryInformation;
