import React from "react";
import "../components/Training/Training.css";
import TrainingUpcoming from "../components/Training/TrainingUpcoming";
import { TrainingContextProvider } from "../context/TrainingContext";

const Training = () => {
  return (
    <TrainingContextProvider>
      <>
        <TrainingUpcoming />
      </>
    </TrainingContextProvider>
  );
};

export default Training;
