import React from "react";
import SiteHeader from "../components/Sites/SiteHeader";

import "../components/Sites/SiteList.css";

const Site = () => {
  return (
    <>
      <SiteHeader />
    </>
  );
};

export default Site;
