import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import ItemCheckFinder from "../../apis/ItemCheckFinder";

import Header from "../Header/Header";
import { FaCheckSquare } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";

const ItemChecklistHeader = () => {
  //reload page
  const history = useNavigate();

  const { id } = useParams();

  const [apparatusInfo, setApparatusInfo] = useState("");

  const [scheduledEvents, setScheduledEvents] = useState("");

  const [selectedItems, setSelectedItems] = useState(
    ""
    // { itemscheduledeventid: "0", itemid: "0"}
  );

  const [selectedItemsNotPresent, setSelectedItemsNotPresent] = useState(
    ""
    // { itemscheduledeventid: "0", itemid: "0"}
  );

  //call to add apparatus
  const addCompletedItemTask2 = (value, item, itemid) => {
    if (value === "true") {
      let newArray = [...selectedItems, item];

      setSelectedItems(newArray);

      let newArray2 = [...selectedItemsNotPresent];
      if (selectedItemsNotPresent.includes(item)) {
        newArray2 = newArray2.filter((day) => day !== item);
      }
      setSelectedItemsNotPresent(newArray2);
    } else {
      let newArray = [...selectedItems];
      if (selectedItems.includes(item)) {
        newArray = newArray.filter((day) => day !== item);
      }
      setSelectedItems(newArray);

      let newArray2 = [...selectedItemsNotPresent, item];

      setSelectedItemsNotPresent(newArray2);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ItemCheckFinder.get(`/${id}`, {});
        setScheduledEvents(response.data.data.results);
        setApparatusInfo(response.data.data.apparatusInfo);
        //  setSelectedItems(response.data.data.results2);
        // // console.log(response.data.data.results);
      } catch (err) {
        // console.log(err);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleItemChecklistTask = () => {
    history({
      pathname: `/itemchecklist/${id}/task`,
      state: { selectedItems, selectedItemsNotPresent },
    });
  };

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>

        <Header content={<b>Asset Check</b>} />
      </div>

      {apparatusInfo.length > 0 ? (
        apparatusInfo.map((data, i) => {
          return (
            <div key={i}>
              <div className="assestPageHeader">
                <b>Asset Check - {data.name}</b>
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
      <div className="itemChecklistContainer33"></div>
      <div className="inProgressContainerHead">
        {scheduledEvents.length > 0 ? (
          scheduledEvents.map((data, i) => {
            return (
              <div key={i}>
                <div className="itemChecklistContainer">
                  <div className="itemChecklistEventName2">
                    <b> {data.eventname}</b>
                  </div>
                </div>
                <div className="primary2">
                  <div className="primaryContainer2">
                    <table className="checklistHistoryTable">
                      <thead>
                        <tr>
                          <th>Present</th>
                          <th>Not Present</th>
                          <th>Name</th>
                          <th>Serial</th>
                          <th>Identification Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.eventlist.map((data2, i) => (
                          <tr
                            key={i}
                            onChange={(e) =>
                              addCompletedItemTask2(
                                e.target.value,
                                data2.itemscheduledeventid,
                                data2.itemid
                              )
                            }
                          >
                            <td>
                              <input
                                type="radio"
                                value="true"
                                className="passBtn"
                                name={"radio" + data2.itemscheduledeventid}
                                defaultChecked={data2.taskstatus === "Pass"}
                                id={"Pass" + data2.itemscheduledeventid}
                              />

                              <label
                                className="passBtn22"
                                htmlFor={"Pass" + data2.itemscheduledeventid}
                              >
                                <FaCheckSquare size="25px" />
                              </label>
                            </td>
                            <td>
                              <input
                                type="radio"
                                value="false"
                                className="failBtn"
                                name={"radio" + data2.itemscheduledeventid}
                                defaultChecked={data2.taskstatus === "Fail"}
                                id={"Fail" + data2.itemscheduledeventid}
                              />

                              <label
                                className="failBtn22"
                                htmlFor={"Fail" + data2.itemscheduledeventid}
                              >
                                <FaCheckSquare size="25px" />
                              </label>
                            </td>
                            <td> {data2.itemname}</td>
                            <td> {data2.serialnumber}</td>
                            <td> {data2.identificationname}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="itemChecklistGetItems"
                  onClick={() => handleItemChecklistTask()}
                >
                  <div className="itemChecklistGetItemName">
                    <b>Get Selected Items</b>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div className="trainingEmptyContainer">
              <div className="trainingEmptyContainer2">
                <b>No scheduled assest checks at this time</b>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ItemChecklistHeader;
