import React from "react";
import "../components/Training/Training.css";

import TrainingInfoHeader from "../components/TrainingInfo/TrainingInfoHeader";

import { TrainingInfoContextProvider } from "../context/TrainingInfoContext";

const TrainingInfo = () => {
  return (
    <TrainingInfoContextProvider>
      <>
        <TrainingInfoHeader />
      </>
    </TrainingInfoContextProvider>
  );
};

export default TrainingInfo;
