import React, { useState, createContext } from "react";

export const ApparatusChecklistContext = createContext();

export const ApparatusChecklistContextProvider = (props) => {
  const [taskList, setTaskList] = useState([]);
  const [itemTaskList, setItemTaskList] = useState([]);
  const [taskGroupInfo, setTaskGroupInfo] = useState([]);
  const [checklistInProgressId, setChecklistInProgressId] = useState([]);
  const [compartmentId, setCompartmentId] = useState([]);
  const [taskGroup, setTaskGroup] = useState([]);
  const [apparatusId, setApparatusId] = useState([]);

  const [clid, setClid] = useState([]);

  const [tgid, setTgid] = useState([]);

  const [bottomSwipeOpen, setBottomSwipeOpen] = useState(false);

  return (
    <ApparatusChecklistContext.Provider
      value={{
        taskList,
        setTaskList,
        itemTaskList,
        setItemTaskList,
        taskGroupInfo,
        setTaskGroupInfo,
        checklistInProgressId,
        setChecklistInProgressId,
        compartmentId,
        setCompartmentId,
        tgid,
        setTgid,
        clid,
        setClid,
        bottomSwipeOpen,
        setBottomSwipeOpen,
        taskGroup,
        setTaskGroup,
        apparatusId,
        setApparatusId,
      }}
    >
      {props.children}
    </ApparatusChecklistContext.Provider>
  );
};
