import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import PopupWeb from "../Popup/PopupNew";

import { useAuth } from "../../context/AuthContext";

import TrainingFinder from "../../apis/TrainingFinder";
import { TrainingContext } from "../../context/TrainingContext";

import { useNavigate } from "react-router-dom";

import TrainingSignIn from ".//TrainingSignIn";
import TrainingItems from ".//TrainingItems";
import TrainingPhotos from ".//TrainingPhotos";
import ClipLoader from "react-spinners/ClipLoader";
import { MdArrowBackIosNew } from "react-icons/md";

import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import { notify } from "../../ToastNotifier";

import { usePageTitle } from "../Navbar/PageTitleContext";

const TrainingHeader = () => {
  const { id } = useParams();
  const { setPageTitle } = usePageTitle();

  const { currentUserSiteId } = useAuth();
  const [loading, setLoading] = useState(false);

  //reload page
  const history = useNavigate();

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
  };

  //Popup Training Details
  const [isOpen2, setIsOpen2] = useState(false);
  const trainingDetailsPopupClose = () => {
    setIsOpen2(!isOpen2);
  };

  const trainingDetailsPopup = () => {
    setIsOpen2(!isOpen2);
    setIsOpen(!isOpen1);
  };

  //Popup create new events
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const detailsPopup = () => {
    setIsOpenDetails(!isOpenDetails);
  };

  const [isOpenDetailsForm, setIsOpenDetailsForm] = useState(false);
  const detailsFormPopup = () => {
    setIsOpenDetails(!isOpenDetails);
    setIsOpenDetailsForm(!isOpenDetailsForm);
    setTrainingNewNoteId("");
    setTrainingNewNoteSubjectId("");
    setTrainingNewNote("");
    setTrainingNewMembers([]);
    seTrainingNoteSubject("");
  };

  //Getting training api

  //Input useStae
  const [training, setTraining] = useState("");

  const [trainingName, setTrainingName] = useState("");
  const [trainingDesc, setTrainingDesc] = useState("");
  const [trainingStatus, setTrainingStatus] = useState("");

  const [trainer] = useState([]);
  const [date, setDate] = useState([]);

  //note

  const [category, setCategory] = useState("");

  const [eventType, setEventType] = useState("");

  const { trainingId, setTrainingId } = useContext(TrainingContext);

  const [addNoteMember, setAddNoteMember] = useState([]);

  // Members avaiable
  const {
    trainingMembers,
    setTrainingMembers,
    setMemberList,
    setApparatusList,
  } = useContext(TrainingContext);

  //Photos
  const { setAddPhoto } = useContext(TrainingContext);

  //training items
  const { setTrainingItems } = useContext(TrainingContext);

  const { memberAttendingCount, setMemberAttendingCount } =
    useContext(TrainingContext);

  const [categoryList, setCategoryList] = useState([]);

  const [errors, setErrors] = useState({});

  const validationRules = {
    trainingForm: {
      trainingName: "Please enter a name",
      category: "Please select a category",
      date: "Please select a date",
    },
    noteForm: {
      trainingNewNote: "Please enter a text",
      trainingNewNoteSubjectId: "Please select a category",
    },
    // Add more validation rules for other forms here
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
  };

  const handleEditTrainingSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      trainingName,
      category,
      date,
    };

    if (!validateForm(formValues, validationRules.trainingForm)) {
      return;
    }

    setLoading(true);

    try {
      //eslint-disable-next-line
      const response = await TrainingFinder.post("/updatetrainingdetails", {
        training_name: trainingName,
        training_desc: trainingDesc,
        trainer,
        categoryId: category,
        eventType: eventType,
        date: date === "" ? null : date,
        trainingId: id,
        trainingStatus,
        currentUserSiteId,
      });

      // console.log(response.data.data.training);
      setTraining(response.data.data.training);
      setPageTitle(
        "Event - " + response.data.data.training[0].training_name
      ); // Set a dynamic page title

      trainingDetailsPopupClose();
      clearForm();
      notify("Event update successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to update event. Please try again.", "error"); // Show error notification
    }
  };

  const handleCompletedTrainingSubmit = async (e) => {
    if (trainingId === undefined) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return false;
    }

    //stop the page from reloading
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response = await TrainingFinder.post("/completed", {
        trainingId,
        currentUserSiteId,
      });
      notify("Event moved to completed successfully!", "success"); // Show success notification

      history({
        pathname: `/upcomingtrainings`,
      });
    } catch (err) {
      notify("Failed to update event. Please try again.", "error"); // Show error notification
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await TrainingFinder.get("/trainingDetails", {
          params: {
            trainingId: id,
            currentUserSiteId,
          },
        });

        setTraining(response.data.data.trainingInfo);
        setPageTitle(
          "Event - " + response.data.data.trainingInfo[0].training_name
        ); // Set a dynamic page title

        setTrainingName(response.data.data.trainingInfo[0].training_name);
        setTrainingDesc(response.data.data.trainingInfo[0].description);
        setTrainingStatus(response.data.data.trainingInfo[0].status);
        setTrainingId(response.data.data.trainingInfo[0].id);
        setCategory(response.data.data.trainingInfo[0].categoryid);
        setCategoryList(response.data.data.categoryList);
        setTrainingMembers(response.data.data.trainingMembers);
        setMemberAttendingCount(response.data.data.trainingMembers.length);
        setAddPhoto(response.data.data.trainingPhotos);
        setPhotoCount(response.data.data.trainingPhotos.length);

        setTrainingItems(response.data.data.trainingItems);
        setTrainingItemsCount(response.data.data.trainingItems.length);

        setMemberList(response.data.data.memberNames);
        setApparatusList(response.data.data.apparatusList);

        //setDate(new Date(response.data.data.trainingInfo[0].date));
        // setDate(
        //   response.data.data.trainingInfo[0].date === null
        //     ? ""
        //    : new Date(
        //         response.data.data.trainingInfo[0].date
        //       ).toLocaleDateString("en-CA")
        //  );
        setDate(
          response.data.data.trainingInfo[0].date === null
            ? ""
            : new Date(response.data.data.trainingInfo[0].date)
                .toISOString()
                .substring(0, 10)
        );

        setNoteList(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    } // eslint-disable-next-line
  }, [currentUserSiteId]);

  const setCategoryFunc = (label) => {
    setCategory(label);
    setEventType("");
  };

  //Delete training
  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response = await TrainingFinder.post("/deleteTraining", {
        trainingId,
        currentUserSiteId,
      });

      history({
        pathname: `/events`,
      });
      notify("Event deleted successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to delete event. Please try again.", "error"); // Show error notification
    }
  };

  //Popup
  const [deletePopup, setDeletePopup] = useState(false);
  const toggleDeletePopup = () => {
    setIsOpen(!isOpen1);
    setDeletePopup(!deletePopup);
  };
  const toggleDeletePopup2 = () => {
    setDeletePopup(!deletePopup);
  };

  const [endPopup, setEndPopup] = useState(false);
  const toggleEndPopup = () => {
    setIsOpen(!isOpen1);
    setEndPopup(!endPopup);
  };

  const {
    memberPopupOpen,
    setMemberPopupOpen,
    itemPopupOpen,
    setItemPopupOpen,
    photoPopupOpen,
    setPhotoPopupOpen,
    photoCount,
    setPhotoCount,
    trainingItemsCount,
    setTrainingItemsCount,
    noteList,
    setNoteList,
  } = useContext(TrainingContext);

  const handleMemberPopup = () => {
    setMemberPopupOpen(!memberPopupOpen);
  };

  const handleItemPopup = () => {
    setItemPopupOpen(!itemPopupOpen);
  };

  const handlePhotoPopup = () => {
    setPhotoPopupOpen(!photoPopupOpen);
  };

  const [noteCount, setNoteCount] = useState("0");
  const [trainingNewNoteId, setTrainingNewNoteId] = useState("");
  const [trainingNewNoteSubjectId, setTrainingNewNoteSubjectId] = useState("");

  const [trainingNewNote, setTrainingNewNote] = useState("0");
  const [trainingNewMembers, setTrainingNewMembers] = useState([]);
  const [trainingNoteSubject, seTrainingNoteSubject] = useState("");

  const editSubject = async (
    trainingnote_id,
    subject,
    note,
    members,
    categoryId
  ) => {
    //Delete training
    console.log(members);
    try {
      setAddNoteMember(
        members.map(function (data2) {
          return {
            value: data2.user_id,
            label: data2.firstname + " " + data2.lastname,
            id: data2.user_id,
          };
        })
      );
      setTrainingNewMembers(
        members.map(function (data2) {
          return {
            value: data2.user_id,
            label: data2.firstname + " " + data2.lastname,
          };
        })
      );
      setTrainingNewNoteId(trainingnote_id);
      seTrainingNoteSubject(subject);
      setTrainingNewNoteSubjectId(categoryId);
      setTrainingNewNote(note);
      setIsOpenDetails(!isOpenDetails);
      setIsOpenDetailsForm(!isOpenDetailsForm);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNoteSubmit = async (e) => {
    //stop the page from reloading
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      trainingNewNote,
      trainingNewNoteSubjectId,
    };

    if (!validateForm(formValues, validationRules.noteForm)) {
      return;
    }

    setLoading(true);

    try {
      if (trainingNewNoteId === "") {
        const response = await TrainingFinder.post("/addNote", {
          trainingId,
          trainingNewNoteSubjectId,
          trainingNewNote,
          currentUserSiteId,
          addNoteMember,
        });
        setLoading(false);
        setNoteList(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);
      } else {
        const response = await TrainingFinder.post("/updateNote", {
          trainingId,
          trainingNewNoteId,
          trainingNewNoteSubjectId,
          trainingNewNote,
          currentUserSiteId,
          addNoteMember,
        });
        setNoteList(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);
      }
      setLoading(false);
      detailsFormPopup();
      notify("Saved note successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to save note. Please try again.", "error"); // Show error notification
    }
  };

  const handleNoteDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      const response = await TrainingFinder.post("/deleteNote", {
        trainingId,
        trainingNewNoteId,
        currentUserSiteId,
      });
      setLoading(false);

      setNoteList(response.data.data.noteList);
      //  setNoteCount(response.data.data.noteList.length);

      setLoading(false);
      toggleNoteRemove();
      notify("Removed note successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to removed note. Please try again.", "error"); // Show error notification
    }
  };

  const [isOpenNoteRemove, setIsOpenNoteRemove] = useState(false);

  const toggleNoteRemove = (trainingnote_id) => {
    setTrainingNewNoteId(trainingnote_id);
    setIsOpenNoteRemove(!isOpenNoteRemove);
    setIsOpenDetails(!isOpenDetails);
  };

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];
  let options = categoryList.map(function (data) {
    return {
      value: data.categoryid,
      label: data.name,
      id: data.categoryid,
    };
  });
  const trailingActions2 = (trainingnote_id) => (
    <TrailingActions>
      <SwipeAction
        onClick={() => toggleNoteRemove(trainingnote_id)}
        //onClick={() => // console.log("lkfalkkld")}
      >
        <div className="unassignDiv2">
          <b>Remove</b>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div className="filter-search-toggle-container">
        <div className={`filter-search-container`}>
          <button
            className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => history(-1)}
          >
            <MdArrowBackIosNew /> Go Back
          </button>
          <button
            className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={togglePopup}
          >
            Edit
          </button>
        </div>
      </div>

      {training.length > 0 ? (
        training.map((data, i) => {
          // // console.log(data);
          return (
            <div key={i}>
              <div className="trainingPageSideRightContainer2">
                <div className="itemPageAmountContainer">
                  <div className="itemPageAmountName">
                    <b>Date</b>
                  </div>
                  <div className="itemPageAmountNumber22">
                    <b>{data.date}</b>
                  </div>
                </div>

                <div className="itemPageAmountContainer">
                  <div className="itemPageAmountName">
                    <b>Description</b>
                  </div>
                  <div className="itemPageAmountNumber22">
                    <b>{data.description}</b>
                  </div>
                </div>

                <div className="itemPageAmountContainer">
                  <div className="itemPageAmountName">
                    <b>Category</b>
                  </div>
                  <div className="itemPageAmountNumber22">
                    <b>{data.category}</b>
                  </div>
                </div>
                <div className="itemPageAmountContainer2">
                  <div className="itemPageAmountName">
                    <b>Status</b>
                  </div>
                  <div className="itemPageAmountNumber22">
                    <b>{data.status}</b>
                  </div>
                </div>
              </div>{" "}
            </div>
          );
        })
      ) : (
        <></>
      )}
      {training.length > 0 ? (
        <>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 p-4 bg-white rounded-lg shadow-md">
            <div
              className="flex flex-col items-center justify-center w-full h-20 border rounded-lg shadow-sm cursor-pointer hover:shadow-lg transition-shadow"
              onClick={handleMemberPopup}
            >
              <div className="text-base font-semibold text-gray-700 sm:text-lg">
                Members
              </div>
              <div className="text-xl font-bold text-black sm:text-2xl">
                {memberAttendingCount}
              </div>
            </div>

            <div
              className="flex flex-col items-center justify-center w-full h-20 border rounded-lg shadow-sm cursor-pointer hover:shadow-lg transition-shadow"
              onClick={handleItemPopup}
            >
              <div className="text-base font-semibold text-gray-700 sm:text-lg">
                Items
              </div>
              <div className="text-xl font-bold text-black sm:text-2xl">
                {trainingItemsCount}
              </div>
            </div>

            <div
              className="flex flex-col items-center justify-center w-full h-20 border rounded-lg shadow-sm cursor-pointer hover:shadow-lg transition-shadow"
              onClick={detailsPopup}
            >
              <div className="text-base font-semibold text-gray-700 sm:text-lg">
                Notes
              </div>
              <div className="text-xl font-bold text-black sm:text-2xl">
                {noteCount}
              </div>
            </div>

            <div
              className="flex flex-col items-center justify-center w-full h-20 border rounded-lg shadow-sm cursor-pointer hover:shadow-lg transition-shadow"
              onClick={handlePhotoPopup}
            >
              <div className="text-base font-semibold text-gray-700 sm:text-lg">
                Photos
              </div>
              <div className="text-xl font-bold text-black sm:text-2xl">
                {photoCount}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="trainingHolder"></div>
      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={trainingDetailsPopup}>
                    <b> Edit Event Information</b>
                  </div>
                </div>

                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={toggleDeletePopup}>
                    <b> Delete Event</b>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          showSubmitButton={false}
          headerText={<b>Event Information</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}
      {isOpen2 && (
        <PopupWeb
          content={
            <>
              {training.length > 0 ? (
                training.map((data, i) => {
                  //console.log(data);
                  return (
                    <div key={i}>
                      <form onSubmit={handleEditTrainingSubmit}>
                        <div className="popupContainer">
                          <label className="NewInputLabel" data-domain="Name">
                            <TextareaAutosize
                              onChange={(e) => setTrainingName(e.target.value)}
                              className="NewInput"
                              maxRows={10}
                              type="text"
                              placeholder=""
                              value={trainingName}
                              required
                            />
                            {errors.trainingName && (
                              <div className="errorInputLabel">
                                {errors.trainingName}
                              </div>
                            )}
                          </label>

                          <label
                            className="NewInputLabel"
                            data-domain="Description"
                          >
                            <TextareaAutosize
                              onChange={(e) => setTrainingDesc(e.target.value)}
                              className="NewInput"
                              maxRows={10}
                              type="text"
                              placeholder=""
                              value={trainingDesc}
                            />
                          </label>

                          <label
                            className="NewInputLabel"
                            data-domain="Category"
                          >
                            <Select
                              options={options}
                              defaultValue={options.find(
                                (option) => option.value === category
                              )}
                              onChange={(e) => setCategoryFunc(e.value)}
                              name="addMembers"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              required
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            {errors.category && (
                              <div className="errorInputLabel">
                                {errors.category}
                              </div>
                            )}
                          </label>

                          <label
                            className="NewInputLabel"
                            data-domain="Select Status"
                          >
                            <Select
                              options={StatusOptions}
                              defaultValue={StatusOptions.find(
                                (StatusOptions) =>
                                  StatusOptions.value === trainingStatus
                              )}
                              onChange={(e) => setTrainingStatus(e.value)}
                              name="addMembers"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              required
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </label>

                          <label className="NewInputLabel" data-domain="Date">
                            <input
                              className="NewInput"
                              type="date"
                              placeholder=""
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              required
                            />
                            {errors.date && (
                              <div className="errorInputLabel">
                                {errors.date}
                              </div>
                            )}
                          </label>
                        </div>
                      </form>
                    </div>
                  );
                })
              ) : (
                <>Select a event first</>
              )}
            </>
          }
          handleClose={trainingDetailsPopupClose}
          isOpen={isOpen2}
          handleSubmit={handleEditTrainingSubmit}
          showSubmitButton={true}
          headerText={<b> Edit Event</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
          loading={loading}
        />
      )}

      {deletePopup && (
        <PopupWeb
          content={
            <>
              <div className="popupContainer">
                <div className="deleteInfoApparatusContainer">
                  <b>This event will no longer be available</b>
                </div>

                <div className="BottomFixPosition">
                  <div className="PopupBottomHolder">
                    <button
                      className="button-39 padding-right two-button"
                      onClick={toggleDeletePopup}
                    >
                      Cancel
                    </button>
                    <button
                      className="button-39 DeletePopupButton two-button"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={toggleDeletePopup2}
          isOpen={deletePopup}
          showSubmitButton={false}
          headerText={<b>Delete - {trainingName} </b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      {endPopup && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>Complete this event</b>
              </div>

              <div className="editTrainingBtnContainer">
                <div
                  className="confirmTrainingBtn"
                  onClick={handleCompletedTrainingSubmit}

                  //onClick={toggleDeletePopup}
                >
                  <b>Confirm</b>
                </div>
              </div>

              <div className="editTrainingBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={toggleEndPopup}
                >
                  <b>Cancel</b>
                </div>
              </div>
            </>
          }
          handleClose={toggleEndPopup}
          isOpen={endPopup}
          showSubmitButton={false}
          headerText={<b>Complete - {trainingName} </b>}
        />
      )}

      {isOpenDetails && (
        <PopupWeb
          content={
            <>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <div className="popupContainer">
                {noteList.length > 0 ? (
                  noteList.map((data, i) => {
                    //  console.log(data);
                    return (
                      <div key={i}>
                        <SwipeableList>
                          <SwipeableListItem
                            trailingActions={trailingActions2(
                              data.trainingnote_id
                            )}
                          >
                            <div className="noteListHolder">
                              <div className="noteListSubjectHolder">
                                <div className="noteListSubject">
                                  <b>{data.subject}</b>
                                </div>
                                <div
                                  className="noteSubmitItemBtn"
                                  onClick={() =>
                                    editSubject(
                                      data.trainingnote_id,
                                      data.subject,
                                      data.note,
                                      data.trainingnotemembers,
                                      data.categoryid
                                    )
                                  }
                                >
                                  <b>Edit</b>
                                </div>
                              </div>

                              {data.trainingnotemembers[0].firstname ===
                              null ? (
                                <></>
                              ) : (
                                <div className="noteListNote">
                                  <div className="notelistMembersTitle">
                                    <b>Members</b>
                                  </div>
                                  <div
                                    className="notelistMembers"
                                    id={data.trainingnote_id}
                                  >
                                    {data.trainingnotemembers.map(
                                      (data2, i) => (
                                        <div key={i} className="4">
                                          <div className="4">
                                            {data2.firstname} {data2.lastname}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="noteListNote">
                                <div className="notelistMembersTitle">
                                  <b>Notes</b>
                                </div>
                                <div className="notelistMembers">
                                  {data.note}
                                </div>
                              </div>
                            </div>
                          </SwipeableListItem>
                        </SwipeableList>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </>
          }
          handleClose={detailsPopup}
          isOpen={isOpenDetails}
          handleSubmit={detailsFormPopup}
          showSubmitButton={true}
          headerText={<b>Event Notes</b>}
          submitButtonText={loading ? "Loading..." : "New Note"}
        />
      )}

      {isOpenDetailsForm && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNoteSubmit}>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Category">
                    {trainingNoteSubject === "" ? (
                      <Select
                        options={categoryList.map(function (data2) {
                          return {
                            value: data2.categoryid,
                            label: data2.name,
                          };
                        })}
                        onChange={(event) =>
                          setTrainingNewNoteSubjectId(event.value)
                        }
                        name="addMembers"
                        className="mb-3"
                        isSearchable={false}
                        required
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    ) : (
                      <Select
                        options={categoryList.map(function (data2) {
                          return {
                            value: data2.categoryid,
                            label: data2.name,
                          };
                        })}
                        onChange={(event) =>
                          setTrainingNewNoteSubjectId(event.value)
                        }
                        defaultValue={{
                          label: trainingNoteSubject,
                          value: trainingNewNoteSubjectId,
                        }}
                        name="addMembers"
                        className="mb-3"
                        isSearchable={false}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    )}

                    {errors.trainingNewNoteSubjectId && (
                      <div className="errorInputLabel">
                        {errors.trainingNewNoteSubjectId}
                      </div>
                    )}
                  </label>

                  <label
                    className="NewInputLabel"
                    data-domain="Select Member Optional"
                  >
                    {trainingNewMembers.length === 0 ||
                    trainingNewMembers[0].value === null ? (
                      <Select
                        options={trainingMembers.map(function (data2) {
                          return {
                            value: data2.user_id,
                            label: data2.firstname + " " + data2.lastname,
                          };
                        })}
                        onChange={(event) => setAddNoteMember(event)}
                        name="addMembers"
                        className="mb-3"
                        isSearchable={false}
                        isMulti
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    ) : (
                      <Select
                        options={trainingMembers.map(function (data2) {
                          return {
                            value: data2.user_id,
                            label: data2.firstname + " " + data2.lastname,
                          };
                        })}
                        onChange={(event) => setAddNoteMember(event)}
                        defaultValue={trainingNewMembers.map(function (e, i) {
                          return { value: e.value, label: e.label };
                        })}
                        name="addMembers"
                        className="mb-3"
                        isSearchable={false}
                        isMulti
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Text">
                    <TextareaAutosize
                      value={trainingNewNote}
                      onChange={(e) => setTrainingNewNote(e.target.value)}
                      className="NewInput"
                      maxRows={10}
                      type="text"
                      placeholder=""
                    />
                    {errors.trainingNewNote && (
                      <div className="errorInputLabel">
                        {errors.trainingNewNote}
                      </div>
                    )}
                  </label>
                </div>
              </form>
            </>
          }
          handleClose={detailsFormPopup}
          isOpen={isOpenDetailsForm}
          handleSubmit={handleNoteSubmit}
          showSubmitButton={true}
          headerText={<b>Event Note</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      {isOpenNoteRemove && (
        <PopupWeb
          content={
            <>
              <div className="emptySpace22"></div>

              <div className="deleteInfoApparatusContainer">
                <b>This will delete the current note</b>
              </div>
            </>
          }
          handleClose={toggleNoteRemove}
          isOpen={isOpenNoteRemove}
          showSubmitButton={true}
          headerText={<b>Delete Note</b>}
          handleSubmit={handleNoteDelete}
          submitButtonText={loading ? "Loading..." : "Delete Note"}
        />
      )}

      {memberPopupOpen && <TrainingSignIn />}
      {itemPopupOpen && <TrainingItems />}
      {photoPopupOpen && <TrainingPhotos />}
    </>
  );
};

export default TrainingHeader;
