import React from "react";
import "../components/Reports/Reports.css";
import ReportsEventsComponent from "../components/Reports/ReportsEvents";

const ReportsEvents = () => {
  return (
    <>
      <ReportsEventsComponent />
    </>
  );
};

export default ReportsEvents;
