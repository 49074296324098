import React, { createContext, useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

// Create a context for page title
const PageTitleContext = createContext();

export function PageTitleProvider({ children }) {
  const location = useLocation();
  const [customTitle, setCustomTitle] = useState(null);

  const pageTitle = useMemo(() => {
    // Define explicit page titles based on pathname
    const explicitTitles = {
  //    "/sitesettings": "Site Settings",
   //   "/site": "Site List",
    };

    // Navigation mapping (same as your navigation array)
    const navigation = [
      { href: "/", title: "Dashboard" },
      { href: "/inventory", title: "Inventory Management" },
      { href: "/events", title: "Events" },
      { href: "/location", title: "Location List" },
      { href: "/bags", title: "Bag Information" },
      { href: "/checklistWeb", title: "Checklists" },
      { href: "/qr", title: "QR Scanner" },
      { href: "/reports", title: "Report Center" },
      { href: "/admin", title: "Admin Tools" },
      { href: "/sitesettings", title: "Site Info" },
      { href: "/profile", title: "Profile" },
      { href: "/site", title: "Change Site" },

      { href: "/reports/inventoryusage", title: "Report - Inventory Usage" },
      { href: "/reports/inventoryinformation", title: "Report - Inventory Info" },
      { href: "/reports/checklisthistory", title: "Report - Checklist History" },
      { href: "/reports/events", title: "Report - Event History" },
      { href: "/reports/individualevents", title: "Report - Individual Events" },
      { href: "/reports/todolist", title: "Report - To Do History" },


      
    ];

    // Find current nav item
    const currentNavItem = navigation.find(
      (navItem) => navItem.href === location.pathname
    );
//import { usePageTitle } from "../Navbar/PageTitleContext";

    // Determine page title
    return (
      explicitTitles[location.pathname] || // Explicit titles
      currentNavItem?.title || // Matching nav item title
      customTitle || 
      "Not Found" // Fallback
    );
  }, [location.pathname, customTitle]);

  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle: setCustomTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
}

// Custom hook to use page title context
export function usePageTitle() {
  return useContext(PageTitleContext);
}
