import React, { useState, createContext } from "react";

export const InventoryContext = createContext();

export const InventoryContextProvider = (props) => {
  const [inventory, setInventory] = useState([]);
  const [bagInventory, setBagInventory] = useState([]);
  const [apparatusList, setApparatusList] = useState([]);

  const [categoryGroup, setCategoryGroup] = useState([]);
  const [typeGroup, setTypeGroup] = useState([]);

  //call to add inventory
  const addInventory = (inventory1) => {
    //copy old inventory add training
    setInventory([...inventory, inventory1]);
  };

  return (
    <InventoryContext.Provider
      value={{
        inventory,
        setInventory,
        addInventory,
        categoryGroup,
        setCategoryGroup,
        typeGroup,
        setTypeGroup,
        bagInventory,
        setBagInventory,
        apparatusList,
        setApparatusList,
      }}
    >
      {props.children}
    </InventoryContext.Provider>
  );
};
