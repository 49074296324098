import React from "react";
import "../components/Reports/Reports.css";
import ReportsUsageComponent from "../components/Reports/ReportsInventoryUsage";

const ReportsInventoryUsage = () => {
  return (
    <>
      <ReportsUsageComponent />
    </>
  );
};

export default ReportsInventoryUsage;
