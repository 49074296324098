import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production"
    ? "/api/todo"
    : "http://192.168.1.249:3001/api/todo";

export default axios.create({
  baseURL,
});
