import React, { useState, useEffect } from "react";
import ReportsFinder from "../../apis/ReportsFinder";
import { useAuth } from "../../context/AuthContext";

import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";
import PopupWeb from "../Popup/PopupNew";
import Select from "react-select";

import imageToBase64 from "image-to-base64/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PublicFolder from "../../apis/PublicFolder";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReportsInventoryUsage = () => {
  const { currentUserSiteId } = useAuth();

  const [results, setResults] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [from, setFrom] = useState("");
  const [thru, setThru] = useState("");

  const [pdfMemberList, setPdfMemberList] = useState("");
  const [pdfCategoryList, setPdfCategoryList] = useState("");
  const [pdfFrom, setPdfFrom] = useState("");
  const [pdfThru, setPdfThru] = useState("");

  const test = () => {
    document.getElementById("myDropdown").classList.toggle("show");

    let arrowCollapseRight = document.getElementById("bx-arrow-from-right2");
    let arrowCollapseLeft = document.getElementById("bx-arrow-from-left2");
    arrowCollapseRight.classList.toggle("collapse");

    if (arrowCollapseRight.classList.contains("collapse")) {
      arrowCollapseRight.classList.remove("disabled");
      arrowCollapseLeft.classList.add("disabled");
    } else {
      arrowCollapseRight.classList.add("disabled");
      arrowCollapseLeft.classList.remove("disabled");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ReportsFinder.get(`/getInventoryUsageOnLoad`, {
          params: {
            currentUserSiteId,
          },
        });

        setCategoryList(response.data.data.categoryList);
        setTrainingList(response.data.data.trainingList);
        console.log(response.data.data.trainingList);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]);

  const getReset = () => {
    setResults([]);
    setFrom("");
    setThru("");

    var options12 = document.getElementById("select-category");
    var options = document.getElementById("select-member");

    for (var i = 0; i < options12.length; i++) {
      options12[i].selected = false;
    }
    for (var ii = 0; ii < options.length; ii++) {
      options[ii].selected = false;
    }
  };

  const getResults = async (e) => {
    e.preventDefault();

    fetchResults();
    convertToBase64();
  };

  const getPDF = () => {
    generatePdf();
  };

  const fetchResults = async () => {
    try {
      var options = document.getElementById("select-member").selectedOptions;
      var members = Array.from(options).map(({ value }) => value);
      var membersNames = Array.from(options).map(({ innerHTML }) => innerHTML);

      var options12 =
        document.getElementById("select-category").selectedOptions;
      var category = Array.from(options12).map(({ value }) => value);

      const response = await ReportsFinder.get("/getInventoryUsage", {
        params: {
          currentUserSiteId,
          from,
          thru,
          category: category.length === 0 ? "" : category,
          event: members.length === 0 ? "" : members,
        },
      });

      const fromholder = new Date(from).toLocaleDateString();
      const thruholder = new Date(thru).toLocaleDateString();
      setPdfFrom(fromholder);
      setPdfThru(thruholder);

      setPdfCategoryList(
        category.length === 0 ? "Any category" : category.toString()
      );

      setPdfMemberList(
        membersNames.length === 0 ? "Not specified" : membersNames.toString()
      );

      setResults(response.data.data.results);
      console.log(response.data.data.results);
    } catch (err) {
      // console.log(err);
    }
  };

  const [base64IMG, setBase64IMG] = useState("");

  const convertToBase64 = () => {
    imageToBase64(PublicFolder.baseURL + "LogonIcon.png") // Path to the image
      .then((response) => {
        setBase64IMG(response);
      });
  };

  /* prettier-ignore */
  //let heading = [["Date","Individual Event Name", "Event Name", "Description", "Members"]];

  let heading = [
    [
      { text: "Category", bold: true },
      { text: "Equipment", bold: true },
      { text: "Serial Number", bold: true },
      { text: "Location", bold: true },
      { text: "Date Used", bold: true },
      { text: "Event Name", bold: true },
    ]
  ];

  const options2 = results.map((elt, i) => [
    elt.type,
    elt.identificationname ? elt.identificationname : elt.name,
    elt.serialnumber,
    `${elt.apparatus} - ${elt.compartment} ${
      elt.bagname ? `- ${elt.bagname}` : ""
    }`,
    elt.date,
    elt.training_name,
  ]);

  let bodyData = heading.concat(options2);
  const generatePdf = async () => {
    var docDefinition = {
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [20, 60, 20, 20],
      // pageOrientation: "landscape",
      info: {
        title: "Inventory Usage Report",
      },
      footer: function (currentPage, pageCount) {
        var columns = [
          {
            text: currentPage.toString() + " of " + pageCount,
            width: "auto",
            alignment: "center",
          },
        ];
        return columns;
      },

      header: {
        columns: [
          {
            text: "Inventory Usage Report",
            bold: true,
            fontSize: 26,

            margin: [20, 20, 0, 0],
          },
          {
            image: `data:image/png;base64,${base64IMG}`,
            width: 50,
            height: 50,
            alignment: "right",
            margin: [0, 10, 15, 0],
          },
        ],
      },

      content: [
        {
          text: `Date range:    ${pdfFrom} - ${pdfThru}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        {
          text: `Category:       ${pdfCategoryList}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        {
          text: `Event:       ${pdfMemberList}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        { width: "*", text: "", margin: [0, 0, 0, 30] },
        {
          text: `Number of records: ${results.length}`,
          fontSize: 10,
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableSyle",

          // layout: "lightHorizontalLines", // optional
          table: {
            // headers are automatically repeated if the tasble spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [80, 80, 80, 100, 80, 80],

            body: bodyData,
          },
        },
      ],

      styles: {
        tableSyle: {
          fontSize: 10,
        },
      },
    };

    //pdfMake.createPdf(docDefinition).download('InventoryUsage.pdf');
    pdfMake.createPdf(docDefinition).open();
  };
  const [loading, setLoading] = useState(false);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const togglePopupFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const categoryListoptions =
    categoryList.map(function (data) {
      return {
        value: data.type,
        label: data.type,
      };
    }) || [];

  const eventListoptions =
    trainingList.map(function (data) {
      return {
        value: data.training_name,
        label: data.training_name,
      };
    }) || [];

  const [tagsFilter, setTagsFilter] = useState([]);

  const handleChange = (e) => {
    const categorySearch = Array.isArray(e) ? e.map((x) => x.value) : [];
    setTagsFilter(categorySearch); // Update the state
  };

  const defaultValues = eventListoptions.filter((option) =>
    tagsFilter.includes(option.value)
  );

  const [categoryListFilter, setCategoryListFilter] = useState([]);

  const handleCategoryListChange = (e) => {
    const categorySearch = Array.isArray(e) ? e.map((x) => x.value) : [];
    setCategoryListFilter(categorySearch); // Update the state
  };

  const defaultCategoryListValues = categoryListoptions.filter((option) =>
    categoryListFilter.includes(option.value)
  );


  return (
    <>
      <form onSubmit={getResults} className="formClass">
        <div className=" top-[64px] bg-white border-b shadow-sm">
          <div className="filter-search-toggle-container">
            <div className={`filter-search-container`}>
              <div className="search-input-container">
                <div className="inventorySelect1">
                  <label className="NewInputLabel" data-domain="From">
                    <input
                      className="NewInput"
                      type="date"
                      placeholder=""
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div className="inventorySelect2">
                  <label className="NewInputLabel" data-domain="Thru">
                    <input
                      className="NewInput"
                      type="date"
                      placeholder=""
                      value={thru}
                      onChange={(e) => setThru(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
              <button
                className="group flex items-center font-medium text-gray-700 rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={togglePopupFilter}
              >
                <AdjustmentsHorizontalIcon
                  aria-hidden="true"
                  className=" mr-2 size-5 flex-none text-gray-400 group-hover:text-gray-500"
                />
                Filters
              </button>

              <button
                className="group flex items-center font-medium text-gray-700 rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                type="submit"
              >
                Generate
              </button>

              <button
                className="group flex items-center font-medium text-gray-700 rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={getReset}
              >
                Reset
              </button>

              {results.length > 0 ? (
                <div className="ReportResultsCommandsPDF">
                  <button
                    className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={getPDF}
                  >
                    <b>PDF</b>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="ReportResultsCommandsHolder">
          {results.length > 0 ? (
            <div className="ReportResultsCommandsPDF">
              <div className="ReportResultsCommandsBtnPDF" onClick={getPDF}>
                <b>PDF</b>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="ReportResultsCommands">
            <button className="ReportResultsCommandsGenerate" type="submit">
              <b>Generate</b>
            </button>
            <div className="ReportResultsCommandsReset" onClick={getReset}>
              <b>Reset</b>
            </div>
          </div>
        </div>
      </form>
      <div className="filterdropdown">
        <button className="filterdropbtn" onClick={() => test()}>
          <div className="filterHolder">
            <div className="filterDiv">
              <b>Filter and sort options</b>
            </div>
          </div>
          <div className="filterArrow disabled" id="bx-arrow-from-right2">
            <i className="bx" id="logo-name__icon">
              <AiOutlineArrowUp />
            </i>
          </div>
          <div className="filterArrow" id="bx-arrow-from-left2">
            <i className="bx" id="logo-name__icon">
              <AiOutlineArrowDown />
            </i>
          </div>
        </button>

        <div id="myDropdown" className="dropdown-content">
          <label className="NewInputLabel" data-domain="Category">
            <select multiple className="NewInput" id="select-category">
              {categoryList.map(function (e, i) {
                return (
                  <option key={i} value={e.type}>
                    {e.type}
                  </option>
                );
              })}
            </select>
          </label>

          <label className="NewInputLabel" data-domain="Event">
            <select multiple className="NewInput" id="select-member">
              {trainingList.map(function (e, i) {
                return (
                  <option key={i} value={e.training_name}>
                    {e.training_name}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
      {results.length > 0 ? (
        <div className="ReportRecords">Number of records: {results.length}</div>
      ) : (
        ""
      )}
      <div className="ReportResultsContainer">
        <div className="reportsPopupContainer">
          {results.length > 0 ? (
            <>
              <table id="reportsTable">
                <tbody>
                  <tr>
                    <th>Category</th>
                    <th>Equipment</th>
                    <th>Serial Number</th>
                    <th>Location</th>
                    <th>Date Used</th>
                    <th>Event Name</th>
                  </tr>
                  {results.map((data, i) => {
                    // console.log(data);
                    return (
                      <tr key={i}>
                        <td>{data.type}</td>
                        <td>
                          {data.identificationname
                            ? data.identificationname
                            : data.name}
                        </td>
                        <td>{data.serialnumber}</td>

                        <td>
                          {data.apparatus} - {data.compartment}
                          {data.bagname ? `- ${data.bagname}` : ""}
                        </td>

                        <td>{data.date}</td>

                        <td>{data.training_name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="emptySpaceReport"> </div>

      {isFilterOpen && (
        <PopupWeb
          content={
            <div>
              <button className="close-button text-sm" onClick={getReset}>
                Clear all
              </button>

              <label className="NewInputLabel123" data-domain="Category">
                <Select
                  isMulti
                  options={categoryListoptions}
                  onChange={handleCategoryListChange}
                  value={defaultCategoryListValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>
              <label className="NewInputLabel123" data-domain="Event">
                <Select
                  isMulti
                  options={eventListoptions}
                  onChange={handleChange}
                  value={defaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>
            </div>
          }
          handleClose={togglePopupFilter}
          isOpen={isFilterOpen}
          showSubmitButton={false}
          headerText={<b>Filters</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}
    </>
  );
};

export default ReportsInventoryUsage;
