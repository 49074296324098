import React from "react";

import { useNavigate } from "react-router-dom";

const ReportsHeader = () => {
  // eslint-disable-next-line
  const history = useNavigate();

  const handleReportUsage = () => {
    history(`/reports/inventoryusage`);
  };

  const handleReportChecklist = () => {
    history(`/reports/checklisthistory`);
  };
  const handleReportInformation = () => {
    history(`/reports/inventoryinformation`);
  };

  
  const handleReportEvents = () => {
    history(`/reports/events`);
  };

  const handleReportIndividualEvents = () => {
      history(`/reports/individualevents`);
   };

   const handleReportToDoList = () => {
    history(`/reports/todolist`);
 };

 const handleReportsMain = () => {
  history(`/reports/Main`);
};



  return (
    <>
    

      <div className="popupBtnContainers">
        {/* <div className="btnDiv">
                  <div className="addMemberBtn" onClick={handleChecklist}>
                    <b>Checklist</b>
                  </div>
          </div>*/}
        <div className="btnDiv" onClick={handleReportUsage}>
          <div className="addMemberBtn">
            <b>Inventory Usage</b>
          </div>
        </div>
        <div className="btnDiv" onClick={handleReportInformation}>
          <div className="addMemberBtn">
            <b>Inventory Information</b>
          </div>
        </div>
        <div className="btnDiv" onClick={handleReportChecklist}>
          <div className="addMemberBtn">
            <b>Checklist History</b>
          </div>
        </div>

        <div className="btnDiv" onClick={handleReportEvents}>
          <div className="addMemberBtn">
            <b>Events</b>
          </div>
        </div>

       <div className="btnDiv" onClick={handleReportIndividualEvents}>
          <div className="addMemberBtn">
            <b>Individual Events</b>
          </div>
        </div>
        <div className="btnDiv" onClick={handleReportToDoList}>
          <div className="addMemberBtn">
            <b>To Do List</b>
          </div>
        </div>
        <div className="btnDiv" onClick={handleReportsMain}>
          <div className="addMemberBtn">
            <b>Main</b>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsHeader;
