import React, { useRef, useState } from "react";
import "./Login.css";

import { useAuth } from "../../context/AuthContext";

import { Link } from "react-router-dom";
import { MdPersonOutline } from "react-icons/md";
import LogonLogo from "../../PublicPhotos/LogonIcon.png";

const ForgotPasswordHeader = () => {
  const emailRef = useRef();

  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  //const [message] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your inbox for further instructions");
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError("Failed to reset password");
      setLoading(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   setError("Not workng right now");
  //   setLoading(false);
  // };

  // font-family: "work sans";
  return (
    <>
      <div className="loginContainer">
        <div className="loginTitle">
          <img src={LogonLogo} alt="Did Not Load" className="logonLogoIMG" />
        </div>

        {error && <div className="alert">{error}</div>}
        {message && <div className="success">{message}</div>}
        <form onSubmit={handleSubmit}>
          <div className="loginInputHolder">
            <div className="loginInputHolderTopColor">
              <div className="loginInputTitle">Reset Password</div>
              <div className="loginInput">
                <div className="loginUsername">Email</div>
                <div className="login__field">
                  <i className="login__icon fas fa-user">
                    <MdPersonOutline />
                  </i>
                  <input
                    className="login__input"
                    placeholder="Email"
                    type="email"
                    ref={emailRef}
                    required
                  />
                </div>
              </div>

              <div className="loginBtnContainer">
                <button disabled={loading} type="submit" className="loginBtn">
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="loginForgotInputHolder">
          <div className="loginForgotPassword">
            <Link className="loginForgotPasswordLink" to="/login">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordHeader;
