import React, { useState, createContext } from "react";

export const ItemListPageContext = createContext();

export const ItemListPageContextProvider = (props) => {
  const [itemInfo, setItemInfo] = useState([]);
  const [bagItems, setBagItems] = useState([]);
  const [compartmentItems, setCompartmentItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  const [userItems, setUserItems] = useState([]);

  const [eventList, setEventList] = useState([]);
  const [checklistInfo, setChecklistInfo] = useState([]);
  const [currentChecklist, setCurrentChecklist] = useState([]);

  return (
    <ItemListPageContext.Provider
      value={{
        itemInfo,
        setItemInfo,
        bagItems,
        setBagItems,
        compartmentItems,
        setCompartmentItems,
        eventList,
        setEventList,
        userItems,
        setUserItems,
        filterItems,
        setFilterItems,
        checklistInfo,
        setChecklistInfo,
        currentChecklist,
        setCurrentChecklist,
      }}
    >
      {props.children}
    </ItemListPageContext.Provider>
  );
};
