import React from "react";

import "../components/ApparatusInventory/ApparatusInventory.css";
import ApparatusInventoryHeader from "../components/ApparatusInventory/ApparatusInventoryHeader";
import ApparatusInventoryLocationAdd from "../components/ApparatusInventory/ApparatusInventoryLocationAdd";
import ApparatusInventoryAdd from "../components/ApparatusInventory/ApparatusInventoryAdd";

import { ApparatusInventoryContextProvider } from "../context/ApparatusInventoryContext";
import { ItemPageContextProvider } from "../context/ItemPageContext";

function ApparatusInventory() {
  return (
    <ApparatusInventoryContextProvider>
    <ItemPageContextProvider>
      <>
        <ApparatusInventoryHeader />
        <ApparatusInventoryLocationAdd />
        <ApparatusInventoryAdd />
        {/* Add ItemPageInspectionTask where necessary */}
      </>
    </ItemPageContextProvider>
  </ApparatusInventoryContextProvider>
  );
}

export default ApparatusInventory;
