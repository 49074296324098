import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { IoMdArrowRoundBack } from "react-icons/io";

import ItemCheckFinder from "../../apis/ItemCheckFinder";

import Header from "../Header/Header";
import { useAuth } from "../../context/AuthContext";

const ItemChecklistTaskHeader = () => {
  //reload page
  const history = useNavigate();

  const { id } = useParams();
  const { currentUserId } = useAuth();

  const data2 = history.location.state;

  const [selectedItems, setSelectedItems] = useState("");
  const [selectedItemsNotPresent, setSelectedItemsNotPresent] = useState("");

  const [error, setError] = useState("");

  const handleSaveBtnSubmit = async (e) => {
    // // console.log(selectedItems);
    setError("");

    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].taskstatus === "Fail") {
        if (
          selectedItems[i].tasknote === null ||
          selectedItems[i].tasknote === ""
        ) {
          setError(`Enter note for all failed task`);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          return false;
        }
      }
    }

    //stop the page from reloading
    e.preventDefault();
    try {
      const response2 = await ItemCheckFinder.post(`/${id}/task`, {
        selectedItems: selectedItems,
      });

      //returns submited checklistid
      console.log(response2.data.data.checklistInProgressId);
    } catch (err) {}
  };

  const handleSubmitBtn = async (e) => {
    setError("");

    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].taskstatus === "Fail") {
        if (
          selectedItems[i].tasknote === null ||
          selectedItems[i].tasknote === ""
        ) {
          setError(`Enter note for all failed task`);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          return false;
        }
      } else if (
        selectedItems[i].taskstatus === null ||
        selectedItems[i].taskstatus === ""
      ) {
        setError(`Complete all task before completing the checklist`);
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        return false;
      }
    }
    e.preventDefault();
    try {
      const response2 = await ItemCheckFinder.post(`/${id}/submittask`, {
        selectedItems: selectedItems,
        currentUserId: currentUserId,
      });

      console.log(response2.data.data);
    } catch (err) {}
  };

  //
  //
  const addCompletedItemTask2 = (
    value,
    itemscheduledeventid,
    itemtaskid,
    itemid
  ) => {
    const holder = selectedItems;
    // // console.log(holder);
    var i;

    for (i = 0; i < holder.length; i++) {
      if (
        holder[i].itemscheduledeventid === itemscheduledeventid &&
        holder[i].itemtaskid === itemtaskid
      ) {
        //   // console.log("Updating");
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...holder[i] };
        // 3. Replace the property you're intested in
        item.taskstatus = value;
        item.itemscheduledeventid = itemscheduledeventid;
        item.itemtaskid = itemtaskid;
        item.itemid = itemid;
        // 4. Put it back into our array.
        holder[i] = item;

        setSelectedItems(holder);
        // // console.log(holder);
      }
    }
  };

  const addCompletedTaskNote = (value, itemscheduledeventid, itemtaskid) => {
    //  // console.log(selectedItems);
    const holder = selectedItems;
    //  // console.log(itemtaskid);
    var i;
    for (i = 0; i < holder.length; i++) {
      if (
        holder[i].itemscheduledeventid === itemscheduledeventid &&
        holder[i].itemtaskid === itemtaskid
      ) {
        //  // console.log("Updating");
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...holder[i] };
        // 3. Replace the property you're intested in
        item.tasknote = value;
        // 4. Put it back into our array.
        holder[i] = item;

        setSelectedItems(holder);
        // // console.log(holder);
      }
    }
  };

  function toggleClass(id) {
    //  // console.log(id);
    document.getElementById(id).classList.add("show");
  }

  function toggleClassPass(id) {
    //  // console.log(id);
    document.getElementById(id).classList.remove("show");
  }

  const [task, setTask] = useState("");

  // // console.log(data2.selectedItems);

  //// console.log(data2.selectedItemsNotPresent);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ItemCheckFinder.get(`/${id}/task`, {
          params: {
            selectedItems: data2.selectedItems,
            selectedItemsNotPresent: data2.selectedItemsNotPresent,
          },
        });
        setTask(response.data.data.results);
        //  // console.log(response.data.data.notPresentFinal);
        setSelectedItems(response.data.data.results2);
        setSelectedItemsNotPresent(response.data.data.notPresentFinal);
      } catch (err) {
        // console.log(err);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  let currentTaskItem2 = null;
  let currentTaskItem = null;

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>

        <div className="checklistSaveDiv" onClick={handleSaveBtnSubmit}>
          Save
        </div>

        <Header content={<b>Asset Check</b>} />
      </div>

      <div className="pageHeader">
        <b>Asset Check </b>
      </div>
      <div className="taskContainerHolder">
        {error && <div className="alert2">{error}</div>}
        {task.length > 0 ? (
          task.map((data, i) => {
            // // console.log(data);
            let loopingGroup = data.itemscheduledeventid;

            if (loopingGroup === currentTaskItem) {
              return (
                <div key={i}>
                  <div className="taskContainerInProgress">
                    <div
                      className="PassFailTaskContainer"
                      onChange={(e) =>
                        addCompletedItemTask2(
                          e.target.value,
                          data.itemscheduledeventid,
                          data.itemtaskid,
                          data.itemid
                        )
                      }
                    >
                      <input
                        type="radio"
                        value="Pass"
                        className="passBtn"
                        name={"radio" + i}
                        defaultChecked={data.taskstatus === "Pass"}
                        id={"Pass" + i}
                        onClick={() => {
                          toggleClassPass(`input${i}`);
                        }}
                      />
                      <label className="passBtn2" htmlFor={"Pass" + i}>
                        <FiCheckCircle size="25px" />
                      </label>

                      <input
                        type="radio"
                        value="Fail"
                        className="failBtn"
                        name={"radio" + i}
                        defaultChecked={data.taskstatus === "Fail"}
                        id={"Fail" + i}
                        onClick={() => {
                          toggleClass(`input${i}`);
                        }}
                      />
                      <label className="failBtn2" htmlFor={"Fail" + i}>
                        <FiAlertCircle size="25px" />
                      </label>
                    </div>

                    <div className="taskNoteInProgress">
                      {data.taskdescription}

                      {data.tasknote ? (
                        <div className="inputDivActive" id={`input${i}`}>
                          <input
                            onChange={(e) =>
                              addCompletedTaskNote(
                                e.target.value,
                                data.itemscheduledeventid,
                                data.itemtaskid
                              )
                            }
                            defaultValue={data.tasknote}
                            className="addItemInput"
                            type="text"
                          />
                        </div>
                      ) : (
                        <div className="inputDiv" id={`input${i}`}>
                          <input
                            onChange={(e) =>
                              addCompletedTaskNote(
                                e.target.value,
                                data.itemscheduledeventid,
                                data.itemtaskid
                              )
                            }
                            defaultValue={data.tasknote}
                            className="addItemInput"
                            type="text"
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className="taskEditDiv"
                      onClick={() => {
                        toggleClass(`input${i}`);
                      }}
                    >
                      <HiOutlinePencilAlt size="25px" />
                    </div>
                  </div>
                </div>
              );
            } else {
              currentTaskItem = data.itemscheduledeventid;
              return (
                <div key={i}>
                  <div className="taskHeader">
                    <b>
                      {data.name}-{data.eventname}
                    </b>
                    <div className="serialNumberInProgressContainer">
                      SN: {data.serialnumber}
                    </div>
                  </div>

                  <div className="taskContainerInProgress">
                    <div
                      className="PassFailTaskContainer"
                      onChange={(e) =>
                        addCompletedItemTask2(
                          e.target.value,
                          data.itemscheduledeventid,
                          data.itemtaskid,
                          data.itemid
                        )
                      }
                    >
                      <input
                        type="radio"
                        value="Pass"
                        className="passBtn"
                        name={"radio" + i}
                        defaultChecked={data.taskstatus === "Pass"}
                        id={"Pass" + i}
                        onClick={() => {
                          toggleClassPass(`input${i}`);
                        }}
                      />
                      <label className="passBtn2" htmlFor={"Pass" + i}>
                        <FiCheckCircle size="25px" />
                      </label>

                      <input
                        type="radio"
                        value="Fail"
                        className="failBtn"
                        name={"radio" + i}
                        defaultChecked={data.taskstatus === "Fail"}
                        id={"Fail" + i}
                        onClick={() => {
                          toggleClass(`input${i}`);
                        }}
                      />

                      <label className="failBtn2" htmlFor={"Fail" + i}>
                        <FiAlertCircle size="25px" />
                      </label>
                    </div>

                    <div className="taskNoteInProgress">
                      {data.taskdescription}

                      {data.tasknote ? (
                        <div className="inputDivActive" id={`input${i}`}>
                          <input
                            onChange={(e) =>
                              addCompletedTaskNote(
                                e.target.value,
                                data.itemscheduledeventid,
                                data.itemtaskid
                              )
                            }
                            defaultValue={data.tasknote}
                            className="addItemInput"
                            type="text"
                          />
                        </div>
                      ) : (
                        <div className="inputDiv" id={`input${i}`}>
                          <input
                            onChange={(e) =>
                              addCompletedTaskNote(
                                e.target.value,
                                data.itemscheduledeventid,
                                data.itemtaskid
                              )
                            }
                            defaultValue={data.tasknote}
                            className="addItemInput"
                            type="text"
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className="taskEditDiv"
                      onClick={() => {
                        toggleClass(`input${i}`);
                      }}
                    >
                      <HiOutlinePencilAlt size="25px" />
                    </div>
                  </div>
                </div>
              );
            }
          })
        ) : (
          <></>
        )}
      </div>

      <div className="selectedItemsNotPresentHolder">
        {selectedItemsNotPresent.length > 0 ? (
          selectedItemsNotPresent.map((data, i) => {
            let loopingGroup2 = data.eventname;

            if (loopingGroup2 === currentTaskItem2) {
              return (
                <div key={i}>
                  <div className="itemRow2">
                    <div className="itemName">
                      {data.name}
                      {data.identificationname
                        ? `- ${data.identificationname}`
                        : ""}
                    </div>
                    <div className="itemAmount2">SN: {data.serialnumber}</div>
                  </div>
                </div>
              );
            } else {
              currentTaskItem2 = data.eventname;
              return (
                <div key={i}>
                  <div className="itemChecklistTaskHeaderContainer2">
                    <div className="itemIdentificationInProgressContainer2">
                      <b>
                        {data.name} - {data.eventname}
                      </b>
                    </div>
                    <div className="serialNumberInProgressContainer2">
                      <b> NOT PRESENT</b>
                    </div>
                  </div>
                  <div className="itemRow2">
                    <div className="itemName">
                      {data.name}
                      {data.identificationname
                        ? `- ${data.identificationname}`
                        : ""}
                    </div>
                    <div className="itemAmount2">SN: {data.serialnumber}</div>
                  </div>
                </div>
              );
            }
          })
        ) : (
          <></>
        )}
        {selectedItemsNotPresent.length > 0 ? (
          <div className="itemAlertBottomHolder">
            <div className="alert2">
              Asset can not be found. An alert will be generated on submit.
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="bottomHolder"></div>
      <div className="itemChecklistGetItems" onClick={handleSubmitBtn}>
        <div className="itemChecklistGetItemName">
          <b>Submit</b>
        </div>
      </div>
    </>
  );
};

export default ItemChecklistTaskHeader;
